import { ArrowUpFromDot, FlipVertical } from "lucide-react";
import { Tooltip } from "antd";

const ScrollToActions = ({scrollToTop,scrollToThread}:{
    scrollToThread:()=>void,
    scrollToTop:()=>void
}) => {
  return (
   <div className="w-full flex items-center justify-center">
     <div className="w-[120px] flex justify-center items-center gap-4 bg-white px-[8px] py-[8px] border-2 rounded-full shadow-sm border-[#ff6b39d8] shadow-[#ff6b39b6]">
      <Tooltip title="Scroll to top">
        <div onClick={scrollToTop}>
          <ArrowUpFromDot
            size={24}
            strokeWidth={1.7}
            className="text-gray-700 hover:scale-110 cursor-pointer"
          />
        </div>
      </Tooltip>
      <div className="bg-gray-400 rounded-xl w-[2px] h-[25px]" />
      <Tooltip title="Find thread">
        <div onClick={scrollToThread}>
          <FlipVertical
            size={24}
            strokeWidth={1.7}
            className="text-gray-700 hover:scale-110 cursor-pointer"
          />
        </div>
      </Tooltip>
    </div>
   </div>
  );
};

export default ScrollToActions;
