import React, { useState, useEffect } from "react";
// import { useQuery } from "@tanstack/react-query";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "@/components/ui/sheet";
import { Mail } from "lucide-react";
import { Button } from "antd";
import { cn } from "@/lib/utils";

import Event from "./Event";

// import useNotificationApi from "./api";

interface NotficationProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const Notification = ({ open, onOpenChange }: NotficationProps) => {
  const [isScrolled, setIsScrolled] = useState(false);

  // const {getNotifications} = useNotificationApi();

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    if (target.scrollTop > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  // const {data} = useQuery({
  //   queryKey: ['notifications'],
  //   queryFn: getNotifications,
  // })

  useEffect(() => {
    return () => {
      setIsScrolled(false);
    };
  }, []);

  return (
    <Sheet open={open} onOpenChange={onOpenChange}>
      <SheetContent className="min-w-[400px]">
        <SheetHeader>
          <SheetTitle className="text-gray-800 flex items-center justify-between">
            <p>91 Notifications</p>
            <Button>Clean</Button>
          </SheetTitle>
        </SheetHeader>
        <div
          className={cn('flex flex-col gap-[10px] my-5 overflow-scroll h-[calc(100vh-80px)]',isScrolled ? 'top-shadow' : '')}
          onScroll={handleScroll}
        >
          {Array.from({ length: 5 }).map((_, index) => (
            <Event
              key={index}
              index={index}
              description="Got new invoice from arunsingh28aug.as@mgial.com to as workflow."
              icon={<Mail strokeWidth={1.2} size={22} />}
              title="New Mail"
              timeStamp={new Date()}
            />
          ))}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default Notification;
