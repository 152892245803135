import http, { createCancelToken } from "@/http/index";

import { NewWorkflowProps, WorkflowResponse, DocumentResponse } from "./types";

export const useNewWorkflow = () => {
  const newWorkflow = async (data: NewWorkflowProps) => {
    return await http.post<{
      message: string;
      workflow_id: string;
      workflow_name: string;
    }>("/workflow/create-workflow", data);
  };

  const fetchWorkflow = async ({
    limit = 10,
    page = 0,
  }: {
    page: number;
    limit: number;
  }) => {
    return await http.get<WorkflowResponse>("/workflow/get-workflows", {
      params: {
        limit,
        page,
      },
    });
  };

  const deleteWorkflow = async (
    { id }: { id: string },
    cancelToken: ReturnType<typeof createCancelToken>
  ) => {
    return await http.delete<{ message: string }>(
      "/workflow/delete-workflow/",
      {
        params: {
          workflow_id: id,
        },
        cancelToken: cancelToken.token,
      }
    );
  };

  const getWorkflowFiles = async ({
    workflowId,
    limit = 10,
    page = 0,
  }: {
    workflowId: string;
    page: number;
    limit: number;
  }) => {
    return await http.get<DocumentResponse>(`/files/get/files/${workflowId}`, {
      params: {
        limit,
        page,
      },
    });
  };

  const editWorkflowName = async ({
    workflow_id,
    workflow_name,
  }: {
    workflow_id: string;
    workflow_name: string;
  }) => {
    return await http.put<{
      message: string;
      workflow_id: string;
      workflow_name: string;
    }>(`/workflow/update-workflow-name/${workflow_id}`, {
      workflow_name,
    });
  };

  const duplicateWorkflow = async ({
    workflow_id,
    workflow_name,
    workflow_description,
  }: {
    workflow_id: string;
    workflow_name: string;
    workflow_description: string;
  }) => {
    return await http.post<{
      message: string;
    }>(`/workflow/duplicate-workflow/${workflow_id}`, {
      workflow_name,
      workflow_description,
    });
  };

  const toggleWorkflowPermissionInvite = async ({
    workflow_ids,
    target_user_email,
    assign_all,
    component_name
  }: {
    workflow_ids: string[];
    target_user_email: string;
    assign_all: boolean;
    component_name: string;
  }) => {
    return await http.put<{
      message: string;
    }>(`/workflow/update-workflow-permission-invite`, {
      target_user_email,
      workflow_ids,
      assign_all,
      component_name
    });
  };

  const getAccessWorkflowList = async (email:string,componentName:string) => {
    return await http.get<string[]>(`/user/get-workflows-access-list/?email=${email}&component_name=${componentName}`);
  }

  return {
    newWorkflow,
    fetchWorkflow,
    deleteWorkflow,
    getWorkflowFiles,
    editWorkflowName,
    duplicateWorkflow,
    toggleWorkflowPermissionInvite,
    getAccessWorkflowList
  };
};
