import React from "react";

import { Message } from "@/types/email.type";

// eslint-disable-next-line react-refresh/only-export-components
export enum Methods {
  REPLY = "reply",
  REPLY_ALL = "replyAll",
  FORWARD = "forward",
  COMPOSE = "compose",
}

export interface ReplayMessage extends Message {
  messageId: string;
  method: Methods | null;
  show: boolean;
}

interface ReplayContextProps {
  replayMessage: ReplayMessage | null;
  setReplayMessage: React.Dispatch<React.SetStateAction<ReplayMessage | null>>;
}

interface ReplayProviderProps {
  children: React.ReactNode;
}

const ReplayContext = React.createContext<ReplayContextProps | undefined>(
  undefined
);

export const ReplayProvider: React.FC<ReplayProviderProps> = ({ children }) => {
  const [replayMessage, setReplayMessage] =
    React.useState<ReplayMessage | null>({
      id: "",
      threadId: "",
      snippet: "",
      labelIds: [],
      payload: {
        headers: [],
        parts: [],
        body: {
          size: 0,
          data: "",
        },
        filename: "",
        mimeType: "",
        partId: "",
      },
      historyId: "",
      internalDate: "",
      threadCount: 0,
      attachments: [],
      content: "",
      method: Methods.COMPOSE,
      messageId: "",
      show: false,
      assignees: [],
      priority_level: "",
      status: "",
      label: "",
    });

  return (
    <ReplayContext.Provider value={{ replayMessage, setReplayMessage }}>
      {children}
    </ReplayContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useReplay = () => {
  const context = React.useContext(ReplayContext);
  if (!context) {
    throw new Error("useReplay must be used within a ReplayProvider");
  }
  return context;
};
