import Headnav from "@/components/Navbars/Headnav";
import useHead from "@/hooks/useHead";

import { WorkflowsTable } from "@/components/table/workflowsTable";

const Workflows = () => {
  useHead({
    title: "Workflows | Wingg",
    description: "Workflows for Wingg",
  });
  return (
    <div>
      <Headnav heading="Workflows | Wingg" />
      <WorkflowsTable />
    </div>
  );
};

export default Workflows;
