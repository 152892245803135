import { useLocation } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

import DotPattern from "@/components/Dot";

import Viewer from "./components/Viewer";
import useWorkflowGround from "./hooks/useWorkflowGround";

const WorkflowGround = () => {
  const {getInvoiceMetadata} = useWorkflowGround()
  const location = useLocation();

  const job_id = location.pathname.split("/").pop();

  const {data} = useQuery({
    queryKey: ["invoice_metadata", job_id || location.state?.job_id ],
    queryFn: () => getInvoiceMetadata( job_id ||location.state?.job_id || ""),
  })

  console.log(data)

  return (
    <div>
      <Viewer document_name={location.state?.fileName || "Loading..."} />
      <DotPattern />
    </div>
  );
};

export default WorkflowGround;
