import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import React from "react";

import Headnav from "@/components/Navbars/Headnav";
import { Button } from "@/components/ui/button";

import {
  KeyRound,
  Contact,
  //  AtSign,
  Fingerprint,
} from "lucide-react";

import { appRoutes } from "@/utils/paths";

const settingHeader = [
  {
    name: "Profile Manager",
    path: appRoutes.dashboard.SETTINGS.PROFILE,
    icon: <Contact strokeWidth="1.2" size={24} />,
  },
  {
    name: "Password Manager",
    path: appRoutes.dashboard.SETTINGS.PASSWORD,
    icon: <KeyRound strokeWidth="1.2" size={24} />,
  },
  {
    name: "Security",
    path: appRoutes.dashboard.SETTINGS.SECURITY,
    icon: <Fingerprint strokeWidth="1.2" size={24} />,
  },
  // {
  //   name: "Email Manager",
  //   path: appRoutes.dashboard.SETTINGS.EMAIL,
  //   icon: <AtSign strokeWidth="1.2" size={24} />,
  // },
];

const Settings = () => {
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (location.pathname === "/settings") {
      navigate("/settings/profile");
    }
  }, [location.pathname, navigate]);

  return (
    <div className="w-full">
      <Headnav heading="Settings" />
      <div className="flex h-[calc(100vh-75px)] overflow-scroll">
        <div className="border-r w-[230px] fixed h-screen bg-white dark:bg-gray-900 z-10">
          {settingHeader.map((item) => (
            <Link to={item.path} key={item.name} className="w-full">
              <Button
                variant="ghost"
                className={
                  location.pathname === item.path
                    ? "text-gray-900 dark:text-white font-popins h-[50px] rounded-none border-b-2 border-[#ff6c37] w-full justify-start bg-[#f9fafb] dark:bg-primary"
                    : "text-gray-500 font-popins h-[50px] rounded-none hover:bg-[#f9fafb] dark:hover:bg-primary-foreground w-full justify-start"
                }
              >
                <div className="flex items-center w-full gap-[20px] text-sm">
                  {item.icon}
                  {item.name}
                </div>
              </Button>
            </Link>
          ))}
        </div>

        <div className="font-popins mt-[50px] pb-[50px] flex justify-center w-full ml-[calc(230px+0px)] bg-white dark:bg-background">
          <div className="mx-auto w-[700px] bg-white dark:bg-background">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
