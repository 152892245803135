import http from "@/http";

import { Invoice } from "@/types/schema";


export default function useWorkflowGround() {
  const getWorkflowAnalysis = async (id: string, workflow_id: string) => {
    const res = await http.get(`/workflow/analysis/${id}/${workflow_id}`);
    return res
  };

  const getWrokflowSchema = async (workflow_id: string) => {
    return http.get<Invoice>(`/workflow/get-schema/${workflow_id}`);
  };

  const reExtract = async ({job_id, workflow_id}:{job_id: string, workflow_id: string}) => {
    return await http.get(`/workflow/re-run-analysis/${job_id}/${workflow_id}`)
  }

  const getInvoiceMetadata = async (job_id: string) => {
    return await http.get(`/ground/get-invoice-metadata/${job_id}`)
  }
  
  return {
    getWorkflowAnalysis,
    getWrokflowSchema,
    reExtract,
    getInvoiceMetadata
  };
}
