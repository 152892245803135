import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";

import { Document } from "@/types/document";
import React from "react";

interface Props {
  showDeleteDialog: boolean;
  documentInfo: Document;
  deleteLoading: boolean;
  setShowDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteFile: (id: string) => void;
}

const DeleteScanData = ({
  documentInfo,
  handleDeleteFile,
  setShowDeleteDialog,
  showDeleteDialog,
  deleteLoading,
}: Props) => {
  return (
    <AlertDialog open={showDeleteDialog}>
      <AlertDialogContent className="min-w-[560px] w-auto">
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription className="text-red-500">
            <span className="font-bold dark:text-white text-black  p-1 rounded-sm">
              {documentInfo?.file_name}
            </span>{" "}
            will be permanently deleted. This action cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="flex">
          <AlertDialogCancel
            className="w-[100px]"
            onClick={() => setShowDeleteDialog(false)}
          >
            Cancel
          </AlertDialogCancel>
          <Button
            className="bg-red-500 hover:bg-red-600 w-[120px] disabled:bg-red-500"
            onClick={() => handleDeleteFile(documentInfo?.documentId as string)}
            loading={deleteLoading}
          >
            Continue
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteScanData;
