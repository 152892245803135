import * as React from "react";

import { cn } from "@/lib/utils";

import { AlertTriangle } from "lucide-react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  addornment?: React.ReactNode;
  togglePassword?: () => void;
  isLoginPage?: boolean;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type,isLoginPage, error,addornment,togglePassword, ...props }, ref) => {
    return (
      <div className="relative">
        <input
          type={type}
          className={cn(
            !addornment ? "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50" : "flex h-10 w-full !pr-[45px] rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50 dark:text-white",
            className
          )}
          ref={ref}
          {...props}
        />
        {
          addornment && (
            <div onClick={togglePassword} className={!isLoginPage ? "absolute top-[0px] right-[0px] border px-[10px] py-[6.5px] rounded-r-md border-input bg-gray-50 dark:!bg-gray-500 dark:text-gray-300 text-gray-500":"absolute top-[5px] right-[0px] px-[10px] py-[6.5px] dark:text-gray-300 text-gray-500"}>
              {addornment}
            </div>
          )
        }
        {error && (
          <p className="text-[12px] text-[#ff4444] flex items-center gap-[5px] mt-[5px]">
            <AlertTriangle size={13} />
            {error}
          </p>
        )}
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
