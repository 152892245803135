import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/legacy/build/pdf.worker.min.js",
  import.meta.url
).toString();

interface PreviewerProps {
  file_url: string;
}

const Previewer = ({ file_url }: PreviewerProps) => {
  const [numPages, setNumPages] = React.useState(0);
  const [pageNumber] = React.useState(0);
  

  return (
    <div
      className={
        " fixed top-0 right-0 bg-[#00000042] dark:bg-[#3e3f4223] z-50 h-screen max-w-[550px] text-white border-l"
      }
    >
      <Document
      file={file_url}
      onLoadSuccess={(e) => setNumPages(e._pdfInfo.numPages)}
      className="w-[450px] px-[10px] flex items-center justify-center"
      renderMode="canvas"
      error={<div className="mt-[60px] z-50">
        <img src={file_url} alt="invoice" loading="eager" className="w-[400px] h-full object-contain z-50" />
      </div>}
    >
        <Page
          _className="z-50"
          key={`page_${1}`}
          pageIndex={0}
          loading={true}
          className="mt-[60px] dark:text-black px-[10px] dark:bg-background w-[800px] !z-[9999999]"
          renderForms={false}
          scale={0.6}
          _enableRegisterUnregisterPage={false}
          renderTextLayer={false}
          width={600}
          renderAnnotationLayer={false}
        />
    </Document>
      <p className="text-center mt-[10px] text-[14px]">
        Page {pageNumber + 1} of {numPages || 1}
      </p>
    </div>
  );
};

export default Previewer;
