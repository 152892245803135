import React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Popover } from "antd";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import Text from "@/components/Text";

import { useNewWorkflow } from "../hooks/useWorkflow";
import { Input } from "@/components/ui/input";

import { Workflow as WorkflowType } from "@/types/workflow";
import { message, Switch, Spin } from "antd";
import { AxiosError } from "axios";

interface UpdatePermissionProps {
  isOpen: boolean;
  onClose: () => void;
  email: string;
  componentName: "invite" | "user";
  assign_all: boolean;
}
const UpdatePermission = ({
  isOpen,
  onClose,
  email,
  assign_all,
  componentName,
}: UpdatePermissionProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const {
    fetchWorkflow,
    toggleWorkflowPermissionInvite,
    getAccessWorkflowList,
  } = useNewWorkflow();
  const [shallowWorkflow, setShallowWorkflow] = React.useState<WorkflowType[]>(
    []
  );

  const { data, isLoading } = useQuery({
    queryKey: ["fetchWorkflow"],
    queryFn: () => fetchWorkflow({ limit: 100, page: 1 }),
    enabled: isOpen,
  });

  React.useEffect(() => {
    if (!isOpen) {
      setAllAssign(false);
      setWorkflowStates({});
      setShallowWorkflow([]);
    }
  }, [isOpen]);

  const { data: accessList, isLoading: accessListLoading } = useQuery({
    queryKey: ["fetchAccessWorkflow", email, componentName],
    queryFn: () => getAccessWorkflowList(email as string, componentName),
    enabled: isOpen || !!email,
  });

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["toggleWorkflowPermissionInvite"],
    mutationFn: toggleWorkflowPermissionInvite,
    onSuccess: (data) => {
      return message.success(data.data.message);
    },
    onError: (error: AxiosError) => {
      return message.error(
        (error.response?.data as { message: string }).message
      );
    },
  });

  const [allAssign, setAllAssign] = React.useState<boolean>(false);
  const [workflowStates, setWorkflowStates] = React.useState<
    Record<string, boolean>
  >({});

  React.useEffect(() => {
    if (data?.data.data && accessList) {
      setShallowWorkflow(data.data.data);

      const initialStates = data.data.data.reduce(
        (acc: Record<string, boolean>, workflow: WorkflowType) => {
          const isWorkflowInAccessList = accessList?.data.some(
            (accessWorkflow) => accessWorkflow === workflow.workflow_id
          );
          acc[workflow.workflow_id] =
            isWorkflowInAccessList || assign_all || false;
          return acc;
        },
        {}
      );

      setWorkflowStates(initialStates);
      setAllAssign(Object.values(initialStates).every((state) => state));
      inputRef.current?.focus();
    }
  }, [data, assign_all, accessList]);

  const handleNameFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "") {
      setShallowWorkflow(data?.data.data || []);
    } else {
      setShallowWorkflow(
        data?.data.data.filter((workflow) =>
          workflow.workflow_name.toLowerCase().includes(value.toLowerCase())
        ) || []
      );
    }
  };

  const handleAllAssignToggle = () => {
    const newAllAssignState = !allAssign;
    setAllAssign(newAllAssignState);
    setWorkflowStates((prevState) =>
      Object.keys(prevState).reduce((acc: Record<string, boolean>, key) => {
        acc[key] = newAllAssignState;
        return acc;
      }, {})
    );
    mutateAsync({
      target_user_email: email as string,
      assign_all: newAllAssignState,
      workflow_ids: shallowWorkflow.map((workflow) => workflow.workflow_id),
      component_name: componentName,
    });
  };

  const handleToggleWorkflow = (workflowId: string) => {
    setWorkflowStates((prevState) => {
      const updatedStates = {
        ...prevState,
        [workflowId]: !prevState[workflowId],
      };
      const allSelected = Object.values(updatedStates).every((state) => state);
      setAllAssign(allSelected);
      return updatedStates;
    });

    mutateAsync({
      workflow_ids: [workflowId],
      target_user_email: email as string,
      assign_all: false,
      component_name: componentName,
    });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] min-w-[540px]">
        <DialogHeader>
          <DialogTitle>Workflow access management</DialogTitle>
        </DialogHeader>
        {!isLoading && !accessListLoading ? (
          <div className="mb-4">
            <div className="flex justify-between">
              <Text
                variant="secondary"
                className="dark:!text-gray-400 !text-gray-600"
              >
                Assign all workflows
              </Text>
              <Switch
                checked={allAssign}
                onChange={handleAllAssignToggle}
                loading={isPending}
              />
            </div>
            <div className="mt-[10px]">
              <Text variant="secondary" semiBold>
                Workflows
              </Text>
              <Input
                placeholder="Search workflow..."
                className="mt-2"
                ref={inputRef}
                onChange={handleNameFilter}
              />
              <div className="max-h-[400px] overflow-scroll relative">
                {shallowWorkflow && shallowWorkflow.length ? (
                  shallowWorkflow?.map((workflow) => (
                    <div
                      key={workflow.workflow_id}
                      className="flex justify-between items-center mt-[10px] border py-[10px] px-[10px] rounded-md gap-[5px] max-h-[400px]"
                    >
                      <div>
                        <Text
                          variant="secondary"
                          className="!text-gray-700 dark:!text-gray-200 !text-[16px]"
                        >
                          {workflow.workflow_name}
                        </Text>
                        <Text
                          variant="secondary"
                          className="!text-gray-700 dark:!text-gray-200 mt-2"
                        >
                          ID: {workflow.workflow_id}
                        </Text>
                        <Text
                          variant="secondary"
                          className="!text-gray-700 dark:!text-gray-200 mt-1 text-sm"
                        >
                          Created by: {workflow.owner_name}
                        </Text>
                        <Popover
                          title={"workflow description"}
                          placement="right"
                          trigger={"hover"}
                          rootClassName="max-w-[500px]"
                          content={
                            workflow?.workflow_description
                              ?.split(" ")
                              .join(" ") || ""
                          }
                        >
                          <button className="!text-gray-500 dark:!text-gray-400 my-1">
                            hover here to see description
                          </button>
                        </Popover>
                        <p className="text-[12px] text-gray-500">
                          Contain{" "}
                          {workflow.number_of_documents <= 0
                            ? "no"
                            : workflow.number_of_documents}{" "}
                          files
                        </p>
                      </div>
                      <Switch
                        checked={workflowStates[workflow.workflow_id]}
                        onChange={() =>
                          handleToggleWorkflow(workflow.workflow_id)
                        }
                        size="default"
                        loading={isPending}
                      />
                    </div>
                  ))
                ) : (
                  <Text
                    variant="secondary"
                    className="mt-6 text-center !text-gray-500 dark:!text-gray-400"
                  >
                    No workflows found
                  </Text>
                )}
              </div>
            </div>
          </div>
        ) : (
          <Spin
            size="large"
            tip="Loading..."
            className="flex justify-center items-center h-[300px] text-gray-700"
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UpdatePermission;
