import { Card } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Building, Users } from "lucide-react";
import { Button, Input } from "antd";
import React from "react";
import { useMutation } from "@tanstack/react-query";
import http from "@/http";
import { AxiosError } from "axios";
import { message } from "antd";
import { ArrowRightToLine } from "lucide-react";

interface OrgnizationScreenProps {
  setStep: (
    value:
      | "org_setup"
      | "onboarded"
      | "team_setup"
      | "profession_details" 
      | "welcome_screen"
  ) => void;
}

const OrgnizationScreen: React.FC<OrgnizationScreenProps> = ({ setStep }) => {
  const [orgName, setOrgName] = React.useState("");
  const [teamSize, setTeamSize] = React.useState("");

  const { mutate, isPending } = useMutation({
    mutationKey: ["createOrganization", orgName, teamSize],
    mutationFn: () =>
      http.post("/user/onboard-org-details", { orgName, teamSize }),
    onError: (error: AxiosError) => {
      if(teamSize === "" || orgName === "") {
        return message.error("Please fill all the fields");
      }
      message.error((error.response?.data as { message: string }).message);
    },
    onSuccess: () => {
      setStep("welcome_screen");
    },
  });

  return (
    <div>
      <div className="flex flex-col justify-center">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">
          Tell us about your organization
        </h1>
        <p className="text-gray-600 mb-8">
          This helps us customize your experience.
        </p>

        <div className="space-y-6">
          <div className="space-y-2">
            <Label htmlFor="organization-name" className="text-gray-700">
              Organization name
            </Label>
            <div className="relative">
              <Building
                className="absolute left-3 top-3 z-10 h-6 w-6 text-gray-400"
                strokeWidth={1.5}
              />
              <Input
                id="organization-name"
                placeholder="Enter your organization name"
                className="pl-11 border-gray-200 focus:border-orange-500 focus:ring-orange-500 h-[40px] text-lg"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
              />
            </div>
          </div>

          <div className="space-y-2">
            <Label htmlFor="team-size" className="text-gray-700">
              Team size
            </Label>
            <div className="relative">
              <Users className="absolute left-3 top-3 h-6 w-6 text-gray-400" />
              <Select value={teamSize} onValueChange={(val) => setTeamSize(val)}>
                <SelectTrigger  className="w-full pl-11 border-gray-200 focus:border-orange-500 focus:ring-orange-500 text-lg h-[40px] text-gray-600">
                  <SelectValue
                    placeholder="Select team size"
                  />
                </SelectTrigger>
                <SelectContent>
                  {["1-10", "11-50", "51-200", "201-500", "501+"].map(
                    (size) => (
                      <SelectItem
                        key={size}
                        value={size}
                      >
                        {size + " employees"}
                      </SelectItem>
                    )
                  )}
                </SelectContent>
              </Select>
            </div>
          </div>

          <Card className="p-4 bg-orange-50 border-orange-100">
            <p className="text-sm text-orange-800">
              Your organization details help us provide:
              <ul className="list-disc list-inside mt-2 space-y-1">
                <li>Customized workflow solutions</li>
                <li>Relevant team collaboration features</li>
                <li>Appropriate scaling options</li>
              </ul>
            </p>
          </Card>
        </div>

        <div className="mt-8 flex justify-between">
          <Button
            size="large"
            type="text"
            className="text-gray-600 hover:text-gray-800"
            onClick={() => setStep("profession_details")}
          >
            Back
          </Button>
          <Button
            size="large"
            className="bg-orange-500 hover:bg-orange-600 text-white"
            onClick={() => mutate()}
            loading={isPending}
            iconPosition="end"
            icon={<ArrowRightToLine size={18} />}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrgnizationScreen;
