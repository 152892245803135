import React, { createContext, useState, useContext } from 'react';

interface AuthContextProps {
  showLoginPrompt: boolean;
  setShowLoginPrompt: (value: boolean) => void;
}

interface AuthProviderProps {
    children: React.ReactNode;
  }

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  return (
    <AuthContext.Provider value={{ showLoginPrompt, setShowLoginPrompt }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

