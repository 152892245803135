import React from "react";
import http from "@/http";
import { message } from "antd";
import { AxiosError } from "axios";

import { UpdatePasswordPayload, UpdateResponse } from "./types";

export default function useUpdatePassword() {
  const [loading, setLoading] = React.useState(false);
  const updatePassword = async (data: UpdatePasswordPayload) => {
    setLoading(true);
    try {
      const response = await http.post<UpdateResponse>(
        "/setting/update-password",
        data
      );
      setLoading(false);
      message.success(response.data?.message);
      return response.data
    } catch (error) {
      setLoading(false);
      if (error instanceof AxiosError) {
        message.error(error.response?.data.message);
      }
      throw error;
    }
  };

  return {
    updatePassword,
    loading,
  };
}
