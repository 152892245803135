import { Button, Input, Popover, Checkbox } from "antd";
import { useParams } from "react-router-dom";
import { RefreshCcw, Loader2, Filter } from "lucide-react";

import { useFilters } from "@/providers/FiltersContext";

import { timeAgo } from "@/helper/timAgo";

import useRefetchLatestEmail from "../hooks/useRefetchLatestEmail";

const statusOptions = ["Open", "Closed", "Pending", "Resolved"];
const priorityOptions = ["P1 High", "P2 Medium", "P3 Low"];

const ListHeader = ({
  count,
  lastSyncDate,
  loading,
  searchFilter,
  loadingNextPage,
}: {
  loading: boolean;
  count: number;
  lastSyncDate: Date;
  searchFilter: (value: string) => void;
  loadingNextPage: boolean;
}) => {
  const { isSyncMail, refetchLatestAllEmail } = useRefetchLatestEmail();
  const params = useParams();
  const {
    removeFilters,
    setSelectedPriority,
    setSelectedStatus,
    selectedStatus,
    selectedPriority,
    combinedFilters,
  } = useFilters();

  const handleStatusChange = (values: string[]) => {
    setSelectedStatus(values);
  };
  const handlePriorityChange = (values: string[]) => {
    setSelectedPriority(values);
  };

  const filterContent = (
    <div className="p-4 w-[200px]">
      <h3 className="font-semibold mb-2">Status</h3>
      <Checkbox.Group
        options={statusOptions}
        value={selectedStatus}
        onChange={handleStatusChange}
        className="flex flex-col space-y-2 text-gray-700"
      />
      <h3 className="font-semibold mt-4 mb-2">Priority</h3>
      <Checkbox.Group
        options={priorityOptions}
        value={selectedPriority}
        onChange={handlePriorityChange}
        className="flex flex-col space-y-2"
      />
      {combinedFilters?.length > 0 && (
        <button
          onClick={removeFilters}
          className="mt-4 w-full border border-[#e5612f] text-[#e5612f]  hover:text-black py-2 px-4 rounded transition duration-300 opacity-100 animate-fade-in"
        >
          Reset Filters
        </button>
      )}
    </div>
  );

  return (
    <div className="px-[10px] border-b pb-[10px] bg-white w-full">
      <div className="py-[10px] bg-white max-w-full flex items-center justify-between">
        <div className="flex gap-2 items-center">
          <h4 className="text-[15px] font-semibold capitalize">
            {params?.inbox_type?.split("-").join(" ")}
          </h4>
          <span className="text-sm text-gray-500">{count} messages</span>
        </div>
        <div className="flex gap-2 items-center">
          {lastSyncDate === null || lastSyncDate === undefined ? null : (
            <>
              <p className="text-[10px] text-gray-400 uppercase flex gap-2 items-center justify-center">
                last sync
                {loading ? (
                  <Loader2 size="12" className="text-gray-500 animate-spin" />
                ) : (
                  <span className="lowercase font-semibold text-gray-600">
                    {timeAgo(lastSyncDate)}
                  </span>
                )}
              </p>

              <Button
                onClick={() => {
                  refetchLatestAllEmail();
                  refetchLatestAllEmail(); // Call it a second time
                }}
                type="text"
                className="hover:!bg-[#ffc4af5f]"
                icon={<RefreshCcw size="18" className="text-gray-500" />}
                loading={isSyncMail}
              />
            </>
          )}
        </div>
      </div>
      <div className="flex items-center h-[] gap-[10px] mt-[-6px]">
        <Input.Search
          onChange={(e) => searchFilter(e.target.value)}
          placeholder="Search"
          loading={loadingNextPage}
          className="border-none"
          classNames={{
            input:
              "hover:!border-[#fa6e3c] active:!border-[#fa6e3c] focus-within:!border-[#fa6e3c] focus:!border-[#fa6e3c] focus:!ring-[#fa6e3c] focus:!ring-opacity-50",
          }}
        />
        <Popover content={filterContent}>
          <Filter
            size={30}
            className="border rounded-md px-[5px] !text-gray-500 cursor-pointer hover:shadow-md hover:!bg-[#ffc4af5f]"
            strokeWidth={1.4}
          />
          {combinedFilters?.length > 0 && (
            <div className="w-1 h-1 bg-blue-500 rounded translate-x-[13px] translate-y-[-20.5px]" />
          )}
        </Popover>
      </div>
      <style>{`
      .ant-input-group-addon:hover {
      color: #fa6e3c; /* Change parent color */
      }
    
      .ant-input-group-addon:hover .anticon-search {
        color: #fa6e3c; /* Change child color on parent hover */
      }
    `}</style>
    </div>
  );
};

export default ListHeader;
