import { Workflow } from "@/types/workflow";
import { CalendarCheck, Copy } from "lucide-react";
import { Link } from "react-router-dom";
import { message, Progress, Tooltip as AntTooltip, Popover } from "antd";
import type { ProgressProps } from "antd/lib/progress";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

import { ColumnDef } from "@tanstack/react-table";

import storage from "@/utils/storage";
import { WORKFLOW_NAME } from "@/constant/index";

const conicColors: ProgressProps["strokeColor"] = {
  "0%": "#006ba6",
  "50%": "#0496ff",
  "100%": "#ffbc42",
};

export const WorkflowDataColumns: ColumnDef<Workflow>[] = [
  {
    accessorKey: "item",
    header: () => <div className="text-left">Workflow type</div>,
    cell: ({ row }) => {
      return (
        <div className="text-gray-700 dark:text-gray-300">
          {row.original.workflow_type}
        </div>
      );
    },
  },
  {
    accessorKey: "workflow_name",
    header: () => <div className="text-left">Workflow name</div>,
    cell: ({ row }) => {
      const fileName = row.original.workflow_name;
      storage.set(WORKFLOW_NAME, fileName.concat("-", row.original.workflow_id));
      return (
        <Link
          to={`/workflows/${row.original.workflow_name}-${row.original.workflow_id}`}
          state={{
            workflow_name: fileName,
            workflow_id: row.original.workflow_id,
          }}
          className="capitalize overflow-hidden text-blue-700 text-ellipsis whitespace-nowrap w-[200px] cursor-pointer hover:underline dark:text-blue-600"
        >
          {fileName}
        </Link>
      );
    },
  },
  {
    accessorKey: "createdAt",
    header: () => <div className="text-left ml-[50px]">Create at</div>,
    cell: ({ row }) => {
      const location = row.getValue("createdAt") as string;
      const date = new Date(location);
      return (
        <div className="items-center justify-start ml-[50px] flex gap-1 text-[#7c7c7c] dark:text-gray-300">
          <CalendarCheck color="gray" size={22} strokeWidth={1.2} />
          {date.getTime() > 0 ? (
            <>
              {date.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
                calendar: "gregory",
                numberingSystem: "latn",
                localeMatcher: "lookup",
                weekday: "short",
              })}
            </>
          ) : (
            "N/A"
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "Invoice count",
    header: () => <div className="text-center">Invoice count</div>,
    cell: ({ row }) => {
      const numberOfDocuments = row.original.number_of_documents;
      return (
        <div className="items-center justify-center flex gap-1 text-[#7c7c7c] dark:text-gray-300">
          {numberOfDocuments}
        </div>
      );
    },
  },
  {
    accessorKey: "inbound mail",
    header: () => <div className="text-left">Inbound mail</div>,
    cell: ({ row }) => {
      const workflowId = row.original?.workflow_id; // Destructure owner and owner_avatar from row.original

      return (
        <div className="flex items-center text-gray-700 dark:text-gray-300 gap-1">
          <p className="w-[200px] text-ellipsis overflow-hidden whitespace-nowrap">
            {" "}
            {workflowId.concat("@docbots.ai")}
          </p>
          <Copy
            strokeWidth={1.2}
            size={15}
            className="active:scale-105 hover:text-primary cursor-pointer"
            onClick={() => {
              navigator.clipboard.writeText(workflowId.concat("@docbots.ai"));
              message.success("mail address copy to clipboard");
            }}
          />
        </div>
      );
    },
  },
  {
    accessorKey: "workflow_description",
    header: () => <div className="text-left">Description</div>,
    cell: ({ row }) => {
      const description = row.original?.workflow_description;
      return (
        <div className="capitalize">
          <Popover
            title={"workflow description"}
            content={description}
            trigger={"hover"}
            rootClassName="max-w-[500px]"
            placement="left"
          >
            {description && description.length > 17 ? (
              <button className="text-gray-700 overflow-hidden text-left text-ellipsis whitespace-nowrap w-[100px] dark:text-gray-400">
                {description}
              </button>
            ) : (
              <div className="text-gray-700 overflow-hidden text-left text-ellipsis whitespace-nowrap w-[100px] dark:text-gray-300">
                {description ? description : "N/A"}
              </div>
            )}
          </Popover>
        </div>
      );
    },
  },
  {
    accessorKey: "Manager",
    header: () => <div className="text-left">Manager</div>,
    cell: ({ row }) => {
      const manager = row.original?.owner_name;
      return (
        <div className="text-gray-700 dark:text-gray-400 capitalize flex items-center gap-2 dark:text-300">
          <Avatar>
            <AvatarImage
              src={row.original.owner_image}
              className="pointer-events-none object-cover"
            />
            <AvatarFallback>
              {row.original.owner_name
                .split(" ")
                ?.map((name) => name[0])
                ?.join("")}
            </AvatarFallback>
          </Avatar>
          {manager ? manager : "N/A"}
        </div>
      );
    },
  },
  {
    accessorKey: "progress",
    header: () => <div className="text-center">Progress</div>,
    cell: () => {
      const progress = 40;
      return (
        <div className="flex items-center gap-2 justify-center">
          <AntTooltip title={`${progress}% approval is done.`} placement="top">
            <Progress
              percent={progress}
              aria-label="progress"
              type="dashboard"
              size={35}
              style={{ width: 35, height: 35 }}
              success={{
                percent: progress >= 100 ? 100 : 0,
                strokeColor: "#16d416",
              }}
              showInfo={true}
              className="dark:text-white"
              strokeColor={conicColors}
            />
          </AntTooltip>
        </div>
      );
    },
  },
];
