/* eslint-disable @typescript-eslint/no-explicit-any */
// src/context/FileContext.tsx

import React, { createContext, useContext, useState, ReactNode } from 'react';

interface FileContextType {
  file: File | null;
  setFile: (file: File | null) => void;
  data: any[];
  setData: (data: any[]) => void;
}

const FileContext = createContext<FileContextType | undefined>(undefined);

export const FileProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [file, setFile] = useState<File | null>(null);
  const [data, setData] = useState<any[]>([]);

  return (
    <FileContext.Provider value={{ file, setFile, data, setData }}>
      {children}
    </FileContext.Provider>
  );
};

export const useFile = (): FileContextType => {
  const context = useContext(FileContext);
  if (!context) {
    throw new Error('useFile must be used within a FileProvider');
  }
  return context;
};
