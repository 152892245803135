import AIcon from "@/assets/initials/a.svg";
import BIcon from "@/assets/initials/b.svg";
import CIcon from "@/assets/initials/c.svg";
import DIcon from "@/assets/initials/d.svg";
import EIcon from "@/assets/initials/e.svg";
import FIcon from "@/assets/initials/f.svg";
import GIcon from "@/assets/initials/g.svg";
import HIcon from "@/assets/initials/h.svg";
import IIcon from "@/assets/initials/i.svg";
import JIcon from "@/assets/initials/j.svg";
import KIcon from "@/assets/initials/k.svg";
import LIcon from "@/assets/initials/l.svg";
import MIcon from "@/assets/initials/m.svg";
import NIcon from "@/assets/initials/n.svg";
import OIcon from "@/assets/initials/o.svg";
import PIcon from "@/assets/initials/p.svg";
import QIcon from "@/assets/initials/q.svg";
import RIcon from "@/assets/initials/r.svg";
import SIcon from "@/assets/initials/s.svg";
import TIcon from "@/assets/initials/t.svg";
import UIcon from "@/assets/initials/u.svg";
import VIcon from "@/assets/initials/v.svg";
import WIcon from "@/assets/initials/w.svg";
import XIcon from "@/assets/initials/x.svg";
import YIcon from "@/assets/initials/y.svg";
import ZIcon from "@/assets/initials/z.svg";

export const genrateInitials = (name: string) => {
  switch (name?.charAt(0)?.toUpperCase()) {
    case "A":
      return AIcon;
    case "B":
      return BIcon;
    case "C":
      return CIcon;
    case "D":
      return DIcon;
    case "E":
      return EIcon;
    case "F":
      return FIcon;
    case "G":
      return GIcon;
    case "H":
      return HIcon;
    case "I":
      return IIcon;
    case "J":
      return JIcon;
    case "K":
      return KIcon;
    case "L":
      return LIcon;
    case "M":
      return MIcon;
    case "N":
      return NIcon;
    case "O":
      return OIcon;
    case "P":
      return PIcon;
    case "R":
      return RIcon;
    case "Q":
      return QIcon;
    case "S":
      return SIcon;
    case "T":
      return TIcon;
    case "U":
      return UIcon;
    case "V":
      return VIcon;
    case "W":
      return WIcon;
    case "X":
      return XIcon;
    case "Y":
      return YIcon;
    case "Z":
      return ZIcon;
    default:
      return null;
  }
};
