import React from "react";
import { Message} from "@/types/email.type";

export const useCombineMessage = (
    newMessages:Message[]
) => {
    return  React.useMemo(() => {
        const existingMessagesMap = new Map();
    
        newMessages.forEach((msg) => {
          existingMessagesMap.set(msg?.threadId, msg);
        });
    
        newMessages.forEach((msg) => {
          const threadId = msg?.threadId;
          if (existingMessagesMap.has(threadId)) {
            const existingMessage = existingMessagesMap.get(threadId);
            if (
              new Date(msg.internalDate) > new Date(existingMessage.internalDate)
            ) {
              existingMessagesMap.set(threadId, msg);
            }
          } else {
            existingMessagesMap.set(threadId, msg);
          }
        });
    
        return [...existingMessagesMap.values()];
      }, [newMessages]);
}