import React from "react";
import Text from "@/components/Text";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";

import { X, ChevronDown } from "lucide-react";

/* eslint-disable @typescript-eslint/no-explicit-any */
interface LineItemsTableProps {
  lineItems: Record<string, any>;
  onClose: () => void;
}

const glCodes = [
  {
    value: "9982",
    label: "9982",
  },
  {
    value: "8754",
    label: "8754",
  },
  {
    value: "0143",
    label: "0143",
  },
  {
    value: "1234",
    label: "1234",
  },
];

const LineItemsTable = ({ lineItems, onClose }: LineItemsTableProps) => {
  const [lineItemsData, setLineItemsData] = React.useState<any[]>([]);
  const [selectedLineItems, setSelectedLineItems] = React.useState<boolean[]>(
    new Array(lineItems.length).fill(false)
  );

  const modifiedData = lineItems.map((itemArray: any) => {
    const newItemArray = [
      { label: "Check", value: false }, // Add checkbox label
      ...itemArray.filter((item: any) => item.label !== "Not found"),
      { label: "GL code", value: "" },
    ];
    const glCodeIndex = newItemArray.findIndex(
      (item: any) => item.label === "GL code"
    );
    const selectIndex = newItemArray.findIndex(
      (item: any) => item.label === "Select"
    );
    // put glcode to second index from start
    if (glCodeIndex !== -1) {
      const glCode = newItemArray.splice(glCodeIndex, 1);
      newItemArray.splice(1, 0, ...glCode);
    }

    if (selectIndex !== -1) {
      newItemArray[selectIndex].value = false;
    }
    return newItemArray;
  });

  React.useEffect(() => {
    setLineItemsData(modifiedData);
  }, [lineItems, modifiedData]);

  const headers = lineItemsData.length
    ? lineItemsData[0]?.map((item: any) => item.label)
    : [];

  const [selectedGlCodes, setSelectedGlCodes] = React.useState(
    new Array(lineItems.length).fill("GL code not detected")
  );
  const [openPopoverIndex, setOpenPopoverIndex] = React.useState<number | null>(
    null
  );

  const handleSelectGlCode = (rowIndex: number, currentValue: string) => {
    const newSelectedGlCodes = [...selectedGlCodes];
    newSelectedGlCodes[rowIndex] =
      currentValue === selectedGlCodes[rowIndex] ? "" : currentValue;
    setSelectedGlCodes(newSelectedGlCodes);

    const newLineItemsData = [...lineItemsData];
    const glCodeIndex = newLineItemsData[rowIndex].findIndex(
      (item: any) => item.label === "GL code"
    );
    if (glCodeIndex !== -1) {
      newLineItemsData[rowIndex][glCodeIndex].value =
        newSelectedGlCodes[rowIndex];
    }
    setLineItemsData(newLineItemsData);
    setOpenPopoverIndex(null);
  };

  const handleCheckboxChange = (rowIndex: number) => {
    const newSelectedLineItems = [...selectedLineItems];
    newSelectedLineItems[rowIndex] = !newSelectedLineItems[rowIndex];
    setSelectedLineItems(newSelectedLineItems);
    // upadate line items data with selected check box true/false
    const newLineItemsData = [...lineItemsData];
    const checkIndex = newLineItemsData[rowIndex].findIndex(
      (item: any) => item.label === "Check"
    );
    if (checkIndex !== -1) {
      newLineItemsData[rowIndex][checkIndex].value = newSelectedLineItems[
        rowIndex
      ];
    }
    setLineItemsData(newLineItemsData);
  };

  return(
    <div className="px-[10px] w-full">
      <div className="flex items-center justify-between">
        <Text variant="secondary">Line items</Text>
        <X
          onClick={onClose}
          className="text-gray-100 bg-red-500 rounded-md hover:scale-105 cursor-pointer"
        />
      </div>
      {headers.length ? (
        <div className="overflow-auto max-h-[400px]">
          <table className="mt-4 text-[13px] w-full">
            <thead>
              <tr className="">
                {headers?.map((header: string, index: number) => (
                  <th
                    key={index}
                    className={
                      header === "Check"
                        ? "text-start border-b px-2 py-2 dark:text-gray-400 min-w-[20px] bg-gray-300 dark:bg-gray-900 text-gray-500"
                        : "text-start border-b px-2 py-2 dark:text-gray-400 min-w-[120px] bg-gray-300 dark:bg-gray-900 text-gray-500"
                    }
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="overflow-scroll">
              {lineItemsData.map((row: any, rowIndex: number) => (
                <tr key={rowIndex}
                  
                >
                  {row.map((cell: any, cellIndex: number) => (
                    <td
                      key={cellIndex}
                      className={
                       selectedLineItems[rowIndex] ? "border-b border-green-500" : "border-b"
                      }
                    >
                      {cell.label === "Check" ? (
                        <Checkbox
                          className="checked:border-none"
                          checked={selectedLineItems[rowIndex]}
                          onCheckedChange={() => handleCheckboxChange(rowIndex)}
                        />
                      ) : cell.label !== "GL code" ? (
                        <Input
                          className="w-full text-gray-600 dark:text-gray-400 text-[12px] h-[20px] overflow-hidden text-ellipsis whitespace-nowrap bg-transparent rounded-sm ring-0 border-none focus-visible:ring-0 dark:focus-within:bg-[#0a183d] focus-within:bg-gray-100"
                          defaultValue={cell.value}
                        />
                      ) : (
                        <Popover
                          key={cellIndex}
                          open={openPopoverIndex === rowIndex}
                          onOpenChange={(isOpen) =>
                            setOpenPopoverIndex(isOpen ? rowIndex : null)
                          }
                        >
                          <PopoverTrigger className="flex items-center justify-between">
                            <Input
                              className="w-full text-gray-600 dark:text-gray-400 text-[12px] h-[20px] overflow-hidden text-ellipsis whitespace-nowrap bg-transparent rounded-sm ring-0 border-none focus-visible:ring-0 dark:focus-within:bg-[#0a183d] focus-within:bg-gray-100"
                              role="combobox"
                              value={
                                glCodes.find(
                                  (glCode) =>
                                    glCode.value === selectedGlCodes[rowIndex]
                                )?.label
                              }
                              defaultValue={
                                selectedGlCodes[rowIndex]
                                  ? glCodes.find(
                                      (glCode) =>
                                        glCode.value ===
                                        selectedGlCodes[rowIndex]
                                    )?.label
                                  : "GL code not detected"
                              }
                            />
                            <ChevronDown size={12} />
                          </PopoverTrigger>
                          <PopoverContent className="w-[160px] p-0 text-[14px]">
                            <Command>
                              <CommandInput
                                className="text-[12px]"
                                placeholder="Search GL codes"
                              />
                              <CommandEmpty className="text-[13px] text-center py-2 text-gray-600">
                                No GL code found.
                              </CommandEmpty>
                              <CommandList>
                                <CommandGroup title="GL Codes">
                                  {glCodes.map((glCode) => (
                                    <CommandItem
                                      key={glCode.value}
                                      value={glCode.value}
                                      className="text-[12px]"
                                      onSelect={() =>
                                        handleSelectGlCode(
                                          rowIndex,
                                          glCode.value
                                        )
                                      }
                                    >
                                      {glCode.label}
                                    </CommandItem>
                                  ))}
                                </CommandGroup>
                              </CommandList>
                            </Command>
                          </PopoverContent>
                        </Popover>
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="text-center text-gray-400 text-[14px]">
          No line items detected
        </div>
      )}
      {headers.length ? (
        <Button className="text-[12px] my-3 px-[25px]">Save</Button>
      ) : null}
    </div>)
};

export default LineItemsTable;
