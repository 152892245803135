import React from "react";

import { IUser } from "@/model/user.model";
import useUpdateEffect from "@/hooks/useUpdateEffect";
import { ACCOUNT_ID } from "@/constant";
import storage from "@/utils/storage";

type UserContextType = {
  user?: IUser;
  accountId?: string;
};

const INITIAL_STATE: UserContextType = {};

interface UserActions {
  setUser: (user: IUser) => void;
  setAccountId: (accountId: string) => void;
}

type UserProviderActions =
  | {
      type: "SET_USER";
      payload: IUser;
    }
  | {
      type: "SET_ACCOUNT_ID";
      payload: string;
    }

const UserActions = {
  setUser: "SET_USER",
  setAccountId: "SET_ACCOUNT_ID",
};

const reducer = (
  state: UserContextType,
  actions: UserProviderActions
): UserContextType => {
  switch (actions.type) {
    case UserActions.setUser:
      return { ...state, user: actions.payload as IUser };
    case UserActions.setAccountId:
      return { ...state, accountId: actions.payload as string };
    default:
      throw new Error("Invalid action type");
  }
};

const UserContext = React.createContext<UserContextType>(INITIAL_STATE);
UserContext.displayName = "UserContext";

const UserDispatch = React.createContext<UserActions | undefined>(undefined);
UserDispatch.displayName = "UserDispatch";

const initializeUserState = (initState: UserContextType): UserContextType => {
  return {
    ...initState,
    accountId: storage.get<string>(ACCOUNT_ID),
  };
};

interface UserProviderProps {
  children: React.ReactNode;
}

export default function UserProvider({ children }: UserProviderProps) {
  const [userState, dispatch] = React.useReducer(
    reducer,
    INITIAL_STATE,
    initializeUserState
  );

  useUpdateEffect(() => {
    storage.set(ACCOUNT_ID, userState.accountId);
  }, [userState.accountId]);

  const setUser = React.useCallback((user: IUser) => {
    dispatch({ type: "SET_USER", payload: user });
  }, []);

  const setAccountId = React.useCallback((accountId: string) => {
    dispatch({ type: "SET_ACCOUNT_ID", payload: accountId });
  }, []);

  return (
    <UserDispatch.Provider value={{ setAccountId, setUser }}>
      <UserContext.Provider value={userState}>{children}</UserContext.Provider>
    </UserDispatch.Provider>
  );
}

/* eslint-disable react-refresh/only-export-components */
export const useUser = () => React.useContext(UserContext);
export const useAccountId = () => React.useContext(UserContext).accountId;
export const useUserDispatch = () => React.useContext(UserDispatch);
