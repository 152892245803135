import Headnav from "@/components/Navbars/Headnav";
import useHead from "@/hooks/useHead";
import { useQuery } from "@tanstack/react-query";

import { Mail, CheckCircle, AlertTriangle, Tag } from "lucide-react";

import StatCard from "./components/Card";
// import SentimentAnalysis from "./components/Sentiment";
// import TeamPerformanceAnalytics from "./components/TeamPermormance";
// import EmailStatus from "./components/EmailStatus";

import { getAnalytics } from "./api/index";
import BetaCard from "./components/Beta";

const Analytics = () => {
  useHead({
    title: "Analytics | Wingg",
    description: "Analytics for Wingg",
  });

  const { data } = useQuery({
    queryKey: ["getAnalytics"],
    queryFn: getAnalytics,
    retry: false,
  });

  return (
    <div className="overflow-scroll h-full rounded-xl">
      <div className="sticky top-0 bg-white z-10">
        <Headnav heading="Dashboard" />
      </div>
      <div className="px-[20px] mx-auto py-[20px]">
      <BetaCard />
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4 mb-8">
          <StatCard
            title="Total Emails Received"
            value={(data?.data?.stats?.total_message as number) || 0}
            icon={<Mail className="text-blue-500" />}
            subtext="+20.1% from last month"
            color="border-l-blue-500"
          />
          <StatCard
            title="Status Count"
            value={
              data?.data.stats?.statusStats[0]?.status_count?.find(
                (name) => name.status_name?.toLowerCase() === "open"
              )?.status_count ?? 0 + " Open"
            }
            icon={<CheckCircle className="text-green-500" />}
            subtext={
              data?.data?.stats?.statusStats[0] && (
                <div className="flex gap-[5px] flex-wrap">
                  {data?.data?.stats?.statusStats[0]?.status_count?.map(
                    (status, index) => {
                      const isLast =
                        index ===
                        data.data.stats.statusStats[0].status_count.length - 1;

                      return (
                        <div
                          key={index}
                          className="flex items-center gap-[4px] flex-wrap"
                        >
                          <p>{`${status.status_name.toLocaleUpperCase()}: ${
                            status.status_count
                          }`}</p>
                          {!isLast && (
                            <div className="w-[1px] h-[12px] bg-gray-600" />
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              )
            }
            color="border-l-green-500"
          />
          <StatCard
            title="Priority Count"
            value={
              data?.data?.stats?.priorityStats[0]?.priority_count?.find(
                (name) => name.priority_name === null
              )?.priority_count ||
              0 + " Unmarked" ||
              "0 Unmarked"
            }
            icon={<AlertTriangle className="text-yellow-500" />}
            subtext={
              data?.data?.stats?.priorityStats[0] && (
                <div className="flex flex-wrap items-center gap-[5px]">
                  {data?.data.stats.priorityStats[0].priority_count
                    .filter((name) => name.priority_name !== null)
                    .map((priority, index) => {
                      const isLast =
                        priority ===
                        data?.data.stats.priorityStats[0].priority_count.slice(
                          -1
                        )[0];
                      return (
                        <div
                          key={index}
                          className="flex items-center gap-[5px] flex-wrap"
                        >
                          <p>
                            {`${priority?.priority_name?.toLocaleUpperCase()}: ${
                              priority.priority_count
                            }`}
                          </p>
                          {!isLast && (
                            <div className="w-[1px] h-[12px] bg-gray-600" />
                          )}
                        </div>
                      );
                    })}
                </div>
              )
            }
            color="border-l-yellow-500"
          />
          <StatCard
            title="Label Distribution"
            value={data?.data?.stats?.labels?.length + " Labels" || "0 Labels"}
            icon={<Tag className="text-purple-500" />}
            subtext={
              data?.data?.stats?.labels && (
                <div className="flex gap-[5px] items-center flex-wrap">
                  {data?.data.stats.labels.map((label) => {
                    const isLast =
                      label === data?.data.stats.labels.slice(-1)[0];
                    return (
                      <div className="flex items-center gap-[5px]">
                        <p>{`${label.label_name.toLocaleUpperCase()}: ${
                          label.label_message_count
                        } `}</p>
                        {!isLast && (
                          <div className="w-[1px] h-[12px] bg-gray-600" />
                        )}
                      </div>
                    );
                  })}
                </div>
              )
            }
            color="border-l-purple-500"
          />
        </div>

        <div className="w-[300px] h-[110px] rounded-md border flex items-center justify-center bg-gray-50 mx-auto">
          <p className="text-sm ">more to come +</p>
        </div>

        {/* <EmailStatus /> */}

        {/* <div className="grid gap-4 md:grid-cols-2 mb-8">
        
      </div> */}
        {/* <SentimentAnalysis /> */}
        {/* <TeamPerformanceAnalytics /> */}
      </div>
    </div>
  );
};

export default Analytics;
