import { useNavigate } from "react-router-dom";
import {message} from 'antd'

import { IUser } from "@/model/user.model";
import storage from "@/utils/storage";
import { useUserDispatch } from "@/providers/userProvider";
import { useTokenDispatch, TokenActions } from "@/providers/useAuthProvider";

import { appRoutes } from "@/utils/paths";

export default function useLogout() {
  const dispatch = useTokenDispatch();
  const userDispatch = useUserDispatch();
  const navigate = useNavigate();

  const logout = () => {
    storage.clear();
    dispatch({ type: TokenActions.logout });
    navigate(appRoutes.auth.INDEX);
    Promise.resolve();
    userDispatch?.setUser({} as IUser);
    message.success("Logout success!");
    navigate(appRoutes.auth.INDEX);
  };

  return logout;
}
