import { AxiosError } from "axios";
import http from "@/http";
import { message } from "antd";

interface Response {
  message: string;
}

export default function useDeleteFile() {
  const deleteFileHandler = async (documentId: string) => {
    try {
      const response = await http.delete<Response>(
        `/files/delete/doc/${documentId}`
      );
      if (response.status === 200) {
        message.success(response.data?.message);
      }
     return response; 
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        message.error(error.response?.data.message);
      }
    }
  };

  const deleteFilesHandler = async (documentIds: string[]) => {
    try {
      const response = await http.post<Response>(`/files/delete/docs`, {
        documentIds: documentIds,
      });
      if (response.status === 200) {
        message.success(response.data?.message);
      }
      return response;
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        message.error(error.response?.data.message);
      }
    }
  };

  return {
    deleteFileHandler,
    deleteFilesHandler,
  };
}
