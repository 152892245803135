/* eslint-disable @typescript-eslint/no-explicit-any */
const STORAGE = localStorage;

const storage = {

  get<T = any>(key: string) {
    const item = STORAGE.getItem(key);
    return JSON.parse(item!) as T;
  },
  set(key: string, value: any) {
    STORAGE.setItem(key, JSON.stringify(value));
  },
  remove(key: string) {
    STORAGE.removeItem(key);
  },
  clear() {
    STORAGE.clear();
  },
};

export default storage;
