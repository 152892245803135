export enum NotificationEvent {
    MAIL = 'mail',
    NOTIFICATION = 'notification'
}

export enum NotificationType {
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    SUCCESS = 'success'
}


export enum WebSocketEvent {
    SUBSCRIBE = 'subscribe',
    REGISTER = 'register',
  }