import { motion, AnimatePresence } from "framer-motion";
import React from "react";

interface WelcomeScreenProps {
  setStep: (
    value:
      | "org_setup"
      | "onboarded"
      | "department_setup"
      | "team_setup"
      | "profession_details"
      | "welcome_screen"
  ) => void;
}

const WelcomeScreen: React.FC<WelcomeScreenProps> = ({ setStep }) => {
  React.useEffect(() => {
    setTimeout(() => {
      setStep("onboarded");
    }, 2000);
  }, [setStep]);

  return (
    <div className="h-screen flex items-center justify-center bg-gray-50 w-full">
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{ duration: 0.5 }}
          className="text-center"
        >
          <motion.h1
            initial={{ y: -20 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.2, type: "spring", stiffness: 120 }}
            className="text-4xl font-bold text-orange-500 mb-4"
          >
            Welcome to Wingg
          </motion.h1>
          <motion.p
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
            className="text-xl text-gray-600"
          >
            Get ready for an amazing experience!
          </motion.p>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default WelcomeScreen;
