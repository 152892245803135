import React from "react";

interface Scale{
    value: number;
}

interface ScaleProps {
    scale: Scale;
    setScale: React.Dispatch<React.SetStateAction<Scale>>;
}

interface ScaleProviderProps {
    children: React.ReactNode;
}

const ScaleContext = React.createContext<ScaleProps | undefined>(undefined);

export const ScaleProvider: React.FC<ScaleProviderProps> = ({
    children,
}) => {
    const [scale, setScale] = React.useState<Scale>({ value: 5 });

    return (
        <ScaleContext.Provider value={{ scale, setScale }}>
            {children}
        </ScaleContext.Provider>
    );
};


// eslint-disable-next-line react-refresh/only-export-components
export const useScale = () => {
    const context = React.useContext(ScaleContext);
    if (!context) {
        throw new Error("useScale must be used within a ScaleProvider");
    }
    return context;
}
