import { ReactComponent as MailServices } from "@/assets/svgs/mail-service.svg";

const Stats = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="flex items-center flex-col justify-center h-[calc(100vh-10px)]">
      <p className="font-semibold text-gray-700 text-[15px]">No conversations selected</p>
      <div>
        <p className="text-gray-500 text-[12px]">Select a conversation to read messages</p>
      </div>
      </div>
      <MailServices className="w-[400px] h-[500px] absolute bottom-[-150px]" />
    </div>
  );
};

export default Stats;
