export function formatSingleFileSize(sizeInBytes: number): string {
  if (sizeInBytes < 1024) {
    return sizeInBytes + " bytes";
  } else if (sizeInBytes < 1024 * 1024) {
    return (sizeInBytes / 1024).toFixed(2) + " KB";
  } else if (sizeInBytes < 1024 * 1024 * 1024) {
    return (sizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
  } else {
    return (sizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  }
}

export function getTotalFileSize(fileSizes: string[]): string {
  const convertToBytes = fileSizes.map((size) => {
    const numericPart = parseFloat(size);
    const suffix = size.trim().slice(-2).toUpperCase();
    let multiplier = 1;

    switch (suffix) {
      case "KB":
        multiplier = 1024;
        break;
      case "MB":
        multiplier = 1024 * 1024;
        break;
      case "GB":
        multiplier = 1024 * 1024 * 1024;
        break;
      default:
        break;
    }
    return numericPart * multiplier;
  });

  const totalSizeInBytes = convertToBytes.reduce((a, b) => a + b, 0);

  if (totalSizeInBytes < 1024) {
    return totalSizeInBytes + " bytes";
  } else if (totalSizeInBytes < 1024 * 1024) {
    return (totalSizeInBytes / 1024).toFixed(2) + " KB";
  } else if (totalSizeInBytes < 1024 * 1024 * 1024) {
    return (totalSizeInBytes / (1024 * 1024)).toFixed(2) + " MB";
  } else {
    return (totalSizeInBytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
  }
}
