import React from "react";
import Lottie from "lottie-react";

import LoadingLotti from "@/assets/lottis/envelop-fly.json";

interface AppLoaderProps {
  userLoading?: boolean;
  children?: React.ReactNode;
}

const AppLoader = ({ children, userLoading }: AppLoaderProps) => {
  return (
    <div>
      {userLoading && (
        <div className="h-screen w-screen flex flex-col items-center justify-center px-[100px]">
          <Lottie className="w-[400px] h-[400px]" animationData={LoadingLotti} loop />
        </div>
      )}
      {!userLoading && <div>{children}</div>}
    </div>
  );
};

export default AppLoader;
