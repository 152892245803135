import {
  PlusIcon,
  MinusIcon,
  Trash,
  Ban,
  Download,
  CheckCircle,
} from "lucide-react";



export const Options = ({zoomIn, zoomOut,download}: {
  zoomIn: () => void, 
  zoomOut: () => void,
  download: () => void,
}) => [
  {
    text: "Approve",
    icon: <CheckCircle size={22} strokeWidth={1.4} />,
    onclick: (e:React.MouseEvent<HTMLButtonElement>) => console.log(e),
    variant: "default",
    className: "flex gap-[5px] items-center hover:!bg-blue-700",
  },
  {
    text: "Rejct",
    icon: <Ban size={22} strokeWidth={1.4} />,
    onclick: (e:React.MouseEvent<HTMLButtonElement>) => console.log("Reject",e),
    variant: "outline",
    className: "hover:!text-red-500 flex gap-[5px] items-center !text-gray-400",
  },
  {
    text: "Delete",
    icon: <Trash size={22} strokeWidth={1.4} />,
    onclick: (e:React.MouseEvent<HTMLButtonElement>) => console.log("Delete",e),
    variant: "outline",
    className: "hover:!text-red-500 flex gap-[5px] items-center !text-gray-400",
  },
  {
    text: "Download",
    icon: <Download size={22} strokeWidth={1.4} />,
    onclick: download,
    variant: "outline",
    className:
      "hover:!text-blue-500 flex gap-[5px] items-center !text-gray-400",
  },
  {
    // text: "Zoom In",
    icon: <PlusIcon size={22} strokeWidth={1.4} />,
    onclick: zoomIn,
    variant: "outline",
    className:
      "hover:!text-blue-500 flex gap-[5px] items-center !text-gray-400",
  },
  {
    icon: <MinusIcon size={22} strokeWidth={1.4} />,
    onclick: zoomOut,
    variant: "outline",
    className:
      "hover:!text-blue-500 flex gap-[5px] items-center !text-gray-400",
  },
];
