import { useMutation } from "@tanstack/react-query";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

import useUpdateEmail from "./hooks/useUpdateEmail";

import useHead from "@/hooks/useHead";

import { AtSign } from "lucide-react";

interface FormValues {
  email: string;
}

const Email = () => {
  useHead({
    title: "Email | Wingg",
    description: "Email for Wingg",
  });


  const { updateEmail } = useUpdateEmail();

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
    },
    mode: "onBlur",
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().email().required(),
      })
    ),
  });

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["updateEmail", getValues().email],
    mutationFn: () => updateEmail(getValues().email),
  });

  return (
    <form
      onSubmit={handleSubmit(async () => {
        await mutateAsync();
      })}
    >
      <h1 className="text-xl font-semibold">Email Manager</h1>
      <p className="text-[14px] text-[#7c7c7c]">
        Change current email to new email
      </p>
      <div className="flex justify-between text-[#7c7c7c] my-[20px] items-center gap-[20px]">
        <label
          htmlFor="email"
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white w-[500px]"
        >
          Your new email
          <p
            id="helper-text-explanation"
            className="mt-2 text-sm text-gray-500 dark:text-gray-400"
          >
            you can take back you current email till 30's day
          </p>
        </label>
        <div className="w-full">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                type="email"
                placeholder="Enter your new email"
                addornment={<AtSign size={20} />}
                error={errors.email?.message}
              />
            )}
          />
        </div>
      </div>
      <div className="flex justify-end">
        <Button variant="default" loading={isPending} className="w-[200px]">
          Continue
        </Button>
      </div>
    </form>
  );
};

export default Email;
