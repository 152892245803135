import http from "@/http";

interface ActivateInviteResponse {
  message: string;
  token: string;
}

const useInviteAPI = () => {
  const activateInvite = async ({ token }: { token: string }) => {
    return await http.post<ActivateInviteResponse>(
      "/activate-token?token=" + token
    );
  };

  const createPassword = async ({
    password,
    name,
    token,
  }: {
    password: string;
    name: string;
    token: string;
  }) => {
    return await http.post("/set-password?p_token=" + token, {
      password,
      name,
    });
  };

  return {
    activateInvite,
    createPassword,
  };
};

export default useInviteAPI;
