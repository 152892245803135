import { Button } from "antd";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { ChevronDown } from "lucide-react";

import { ReactComponent as JSONIcon } from "@/assets/svgs/icons/json.svg";
import { ReactComponent as CSVIcon } from "@/assets/svgs/icons/csv.svg";
import { ReactComponent as GoogleSheetIcon } from "@/assets/svgs/icons/gsheet.svg";

const DownloadButton = () => {
  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger>
          <Button className="flex gap-[10px]" type={"dashed"}>
            Download
            <ChevronDown size={18} strokeWidth={1.5} />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel>Select format</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem className="flex items-center gap-[6px] text-gray-700">
            <CSVIcon height={20} width={20} />
            CSV
          </DropdownMenuItem>
          <DropdownMenuItem className="flex items-center gap-[6px] text-gray-700">
            <JSONIcon height={20} width={20} />
            JSON
          </DropdownMenuItem>
          <DropdownMenuItem className="flex items-center gap-[6px] text-gray-700">
            <GoogleSheetIcon height={20} width={20} />
            Sheet
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default DownloadButton;
