import React from "react";
import { message } from "antd";
import { useMutation } from "@tanstack/react-query";
import { AxiosError, CancelTokenSource } from "axios";
import { createCancelToken } from "@/http";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";

import { useNewWorkflow } from "../hooks/useWorkflow";

import { Workflow as WorkflowType } from "@/types/workflow";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  workflowInfo: WorkflowType | null;
  refetch: () => void;
}

const DeleteWorkflow = ({ isOpen, onClose, workflowInfo, refetch }: Props) => {
  const cancelTokenRef = React.useRef<CancelTokenSource | null>(null);
  const { deleteWorkflow } = useNewWorkflow();
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["delete_workflow"],
    mutationFn: async ({ workflow_id }: { workflow_id: string }) => {
      cancelTokenRef.current = createCancelToken();
      return deleteWorkflow(
        { id: workflow_id },
        cancelTokenRef.current as CancelTokenSource
      );
    },
    onSuccess: (data) => {
      refetch();
      onClose();
      message.success(data.data.message);
    },
    onError: (error) => {
      return message.error(
        ((error as AxiosError).response?.data as { message: string }).message +
          ((error as AxiosError).response?.data as { desc: string })?.desc );
    },
  });

  const handleCancel = () => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("canceled");
    }
    onClose();
  };

  return (
    <AlertDialog open={isOpen} onOpenChange={onClose}>
      <AlertDialogContent className="min-w-[560px] w-auto">
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription className="text-red-500">
            <span className="font-bold text-black dark:text-white p-1 rounded-sm">
              {workflowInfo?.workflow_name}
            </span>{" "}
            will be permanently deleted. This action cannot be undone.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="flex">
          <AlertDialogCancel className="w-[100px]" onClick={handleCancel}>
            Cancel
          </AlertDialogCancel>
          <Button
            className="bg-red-500 hover:bg-red-600 w-[120px] disabled:bg-red-500"
            loading={isPending}
            onClick={() => {
              mutateAsync({ workflow_id: workflowInfo?.workflow_id as string });
            }}
          >
            Continue
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteWorkflow;
