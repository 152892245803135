import React from "react";
import { useMutation } from "@tanstack/react-query";
import {message,Button} from 'antd'
import { AxiosError } from "axios";
import { File } from "lucide-react";

import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";

import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import Text from "@/components/Text";

import { formatSingleFileSize } from "@/helper/sizeCalculator";
import { Workflow } from "@/types/workflow";

import { useNewWorkflow } from "../hooks/useWorkflow";

interface Props {
  setShowEditWorkflow: React.Dispatch<React.SetStateAction<boolean>>;
  showEditWorkflow: boolean;
  workflowInfo: Workflow | undefined;
  refetch: () => void;
}

const EditWorkflow = ({
  setShowEditWorkflow,
  showEditWorkflow,
  workflowInfo,
  refetch,
}: Props) => {
  const [workflowName, setWorkflowName] = React.useState(
    workflowInfo?.workflow_name
  );

  const { editWorkflowName } = useNewWorkflow();

  React.useEffect(() => {
    setWorkflowName(workflowInfo?.workflow_name);
  }, [workflowInfo]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (workflowInfo?.workflow_name === workflowName) {
      return message.warning(
        "Workflow name is same as previous one. Please change it to save.",
      );
    }
    mutateAsync();
  };

  const { mutateAsync, isPending, error } = useMutation({
    mutationKey: ["editWorkflowMeta", workflowInfo?.workflow_id],
    mutationFn: async () => {
      return await editWorkflowName({
        workflow_id: workflowInfo?.workflow_id || "",
        workflow_name:
          (workflowName as string) || workflowInfo?.workflow_name || "",
      });
    },
    onError: (error) => {
      return message.error(
        ((error as AxiosError).response?.data as { message: string }).message);
    },
    onSuccess: (data) => {
      refetch();
      message.success(data.data?.message);
      setShowEditWorkflow(false);
    },
  });

  return (
    <Sheet
      open={showEditWorkflow}
      onOpenChange={() => setShowEditWorkflow(false)}
    >
      <SheetContent className="min-w-[500px]">
        <SheetHeader>
          <form onSubmit={onSubmit}>
            <SheetTitle>Update workflow name</SheetTitle>
            <div className="grid gap-4 py-4 w-full">
              <div className="mb-[-10px] flex items-center justify-between">
                <Label>Workflow Name</Label>
                <Button
                  className="mr-[-10px] !outline-none focus:outline-none focus-within:outline-none focus-visible:outline-none"
                  htmlType="reset"
                  onClick={() => setWorkflowName(workflowInfo?.workflow_name)}
                  type="link"
                >
                  reset
                </Button>
              </div>
              <Input
                type="text"
                id="file_name"
                name="file_name"
                className="capitalize"
                value={workflowName}
                placeholder="File name here"
                onChange={({ target }) => setWorkflowName(target.value)}
                error={
                  workflowName && workflowName?.length <= 0
                    ? "It can not be empty"
                    : error?.message
                }
                addornment={<File size={20} />}
              />
            </div>
            <div>
              <Text className="text-muted-foreground text-sm">
                Documents size:{" "}
                {formatSingleFileSize(workflowInfo?.workflow_size || 0)}
              </Text>
            </div>
            <div className="flex items-start gap-[15px] mt-[30px]">
              <Button
                htmlType="submit"
                className="w-[100px]"
                loading={isPending}
                type={"primary"}
              >
                Save
              </Button>
            </div>
          </form>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

export default EditWorkflow;
