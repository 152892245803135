import http from "@/http";

import React from "react";
import { message } from "antd";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import { useUserDispatch, useAccountId } from "@/providers/userProvider";
import storage from "@/utils/storage";

export default function useGetUser() {
  const dispatch = useUserDispatch();
  const navigator = useNavigate();
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState<number>();

  const accountId = useAccountId();

  const getUser = async () => {
    try {
      const response = await http.get(`/auth/get-user/${accountId}`);
      setUser(response.data);
      setLoading(1);
      dispatch?.setUser(response.data?.user);
    } catch (error: unknown) {
      setLoading(100);
      if (error instanceof AxiosError) {
        storage.clear();
        if (error.response?.status === 404) {
          return navigator("/login", {
            replace: true,
            preventScrollReset: true,
          });
        }
        message.error(error.response?.data.message || error.message);
      }
      return navigator("/", {
        state: { error: (error as Error).message },
        replace: true,
        preventScrollReset: true,
      });
    } finally {
      setLoading(100);
    }
  };
  return { user, loading, getUser };
}
