export function timeAgo(date: Date): string {
  const now = new Date();
  const secondsPast = Math.floor((now?.getTime() - new Date(date)?.getTime()) / 1000);

  if (secondsPast < 60) {
    return `${secondsPast} sec ago`;
  }
  if (secondsPast < 3600) {
    // less than an hour
    const minutes = Math.floor(secondsPast / 60);
    return `${minutes} min${minutes !== 1 ? "s" : ""} ago`;
  }
  if (secondsPast < 86400) {
    // less than a day
    const hours = Math.floor(secondsPast / 3600);
    return `${hours} hr${hours !== 1 ? "s" : ""} ago`;
  }
  if (secondsPast < 2592000) {
    // less than a month
    const days = Math.floor(secondsPast / 86400);
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  }
  if (secondsPast < 31536000) {
    // less than a year
    const months = Math.floor(secondsPast / 2592000);
    return `${months} month${months !== 1 ? "s" : ""} ago`;
  }
  const years = Math.floor(secondsPast / 31536000);
  return `${years} year${years !== 1 ? "s" : ""} ago`;
}



export const convertDateToTime = (timeStamp: string) => {
  const newDate = new Date(Number(timeStamp));

  // if the date is today return Today
  if (newDate.toDateString() === new Date().toDateString()) {
    return (
      "Today" +
      ", " +
      newDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        formatMatcher: "best fit",
      })
    );
  }
  return newDate.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    formatMatcher: "best fit",
  });
};