import http from "@/http";
import { message } from "antd";

export default function useUpdateEmail() {
  const updateEmail = async (email: string) => {
    try {
      const { data } = await http.post<{ message: string }>(
        "/setting/update-email",
        {
          email,
        }
      );
      message.success(data.message);
      return data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      message.error(error.response.data.message);
    }
  };

  return { updateEmail };
}
