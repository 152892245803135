import React from "react";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";

import { useUser } from "@/providers/userProvider";

import { ReactComponent as GoogleIcon } from "@/assets/svgs/oauth/google.svg";

const ConnectCard = ({
  setInboxEnabled,
  inboxEnabled,
}: {
  setInboxEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  inboxEnabled: boolean;
}) => {
  const { user } = useUser();

  return (
    <div className="border py-2 mb-8 px-5 rounded-md shadow-lg">
      <div className="flex items-center justify-between ">
        <div className="space-y-1 flex items-center gap-[10px]">
          <GoogleIcon className="w-[100px]" />
          <div className="flex flex-col gap-1 text-gray-600">
            <p className="text-gray-500 font-semibold">Connected account with <span className="text-[#ff6c39]">Wingg</span> inbox</p>
            <span className="text-gray-700 text-sm">{user?.inbox?.inbox_account_email}</span>
                <p className="text-[11px] font-mono mt-2">Active since 19 Oct 2024</p>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <Switch
            id="inbox-mode"
            checked={inboxEnabled}
            onCheckedChange={setInboxEnabled}
          />
          <Label htmlFor="inbox-mode">Enable Inbox</Label>
        </div>
      </div>
    </div>
  );
};

export default ConnectCard;
