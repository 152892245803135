import * as React from "react";
import { Transition } from "@headlessui/react";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";
import { 
  // message, 
  notification } from "antd";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { ShineBorder } from "@/components/magicui/border-beam";

// import google from "@/assets/svgs/oauth/google.svg";

import useLogin from "./hooks/useLogin";
import useSignup from "./hooks/useSignup";
import useGoogle from "./hooks/useGoogle";

import useHead from "@/hooks/useHead";
import useTogglePwd from "@/hooks/useTogglePwd";

import cover from "@/assets/cover/collabration.jpg";
import winggTextLogo from "@/assets/Wingg-Final.png";

interface SignInFormValues {
  email: string;
  password: string;
}

interface SignUpFormValues {
  email: string;
  password: string;
  name: string;
}

type PageType = "login" | "signup";

export default function Login() {
  const { showPwd, togglePwd } = useTogglePwd();
  const {
    googleCallback,
    googleLink,
    // loading: googleLoading
  } = useGoogle();
  const { loading: signupLoading, signup } = useSignup();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const code = params.get("user");
  const isSignupPage = params.get("signup") === "true";

  const [page, setPage] = React.useState<PageType>(
    isSignupPage ? "signup" : "login"
  );

  useHead({
    title: page === "signup" ? "Signup | Wingg" : "Login | Wingg",
    description: page === "signup" ? "Signup to Wingg" : "Login to Wingg",
  });

  React.useEffect(() => {
    if (code) {
      googleCallback(code);
    }
  }, [code, googleCallback]);

  React.useEffect(() => {
    setPage(isSignupPage ? "signup" : "login");
  }, [isSignupPage]);

  const { loading, login } = useLogin();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<SignInFormValues>({
    defaultValues: {
      email: "",
      password: "",
    },
    mode: "onBlur",
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .email()
          .required("Email is required")
          .email("Email is not valid"),
        password: Yup.string().required("Password is required"),
      })
    ),
  });

  const {
    control: signupControl,
    handleSubmit: signupSubmit,
    setValue: signupSetValue,
    formState: { errors: signupError },
    reset,
  } = useForm<SignUpFormValues>({
    defaultValues: {
      email: "",
      password: "",
      name: "",
    },
    mode: "onSubmit",
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string()
          .email()
          .required("Email is required")
          .email("Email is not valid"),
        password: Yup.string().required("Password is required"),
        name: Yup.string().required("Name is required"),
      })
    ),
  });

  const onSubmit = async (data: SignInFormValues) => {
    login(data.email, data.password);
  };

  const onSubmitSignup = async (data: SignUpFormValues) => {
    notification.error({
      message: "Sorry for the inconvenience " + data.name,
      description: (
        <p>
          We are currently not accepting new signups. Please contact us at{" "}
          <a
            href="mailto:hello@mywingg.com"
            target="_top"
            className="text-blue-500"
          >
            hello@mywingg.com
          </a>
        </p>
      ),
    });
    reset();
    isSignupPage ? signup(data.email, data.password, data.name) : null;
  };

  const handleButtonClick = () => {
    setPage((prevPage) => (prevPage === "login" ? "signup" : "login"));
  };

  const handleGoogleLogin = () => {
    googleLink();
    // window.open("https://forms.gle/4PevNGHbJqjytEqh8", "_parent");
  };

  return (
    <section className="flex flex-col md:flex-row h-screen items-center">
      {/* <Carousel dotPosition="right"> */}
      <div className="hidden lg:block w-full md:w-1/2 xl:w-2/3 h-screen bg-green-600">
        <img
          loading="eager"
          src={cover}
          alt="banner"
          className="w-full h-full object-cover"
        />
      </div>

      {/* </Carousel> */}

      <div className="bg-white w-full md:max-w-md lg:max-w-full md:mx-0 md:w-1/2 xl:w-1/3 h-screen px-6 lg:px-16 xl:px-12 flex items-center">
        <Transition
          className={page === "login" ? "w-full" : "hidden"}
          show={page === "login"}
          enter="transition-opacity duration-300 transition-transform duration-300"
          enterFrom="opacity-0 transform -translate-x-10"
          enterTo="opacity-100 transform translate-x-0"
          leave="transition-opacity duration-300 transition-transform duration-300"
          leaveFrom="opacity-100 transform translate-x-0"
          leaveTo="opacity-0 transform translate-x-10"
        >
          <div className="max-w-full h-100">
            <img src={winggTextLogo} className="h-[80px] mb-4" alt="wingg" />

            <h1 className="text-xl md:text-xl leading-tight mt-0 text-gray-700">
              Log in to your account
            </h1>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-3">
                <Label>Email Address</Label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="email"
                      type="email"
                      name="email"
                      value={field.value}
                      onChange={({ target }) => setValue("email", target.value)}
                      className="w-full px-4 py-3 rounded-lg text-[13px] bg-gray-100 mt-2
                focus:bg-white focus:outline-none h-[45px]"
                      placeholder="awesome@domain.com"
                      error={errors.email?.message}
                    />
                  )}
                />
              </div>

              <div className="mt-4">
                <Label>Password</Label>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <Input
                      id="password"
                      type={showPwd ? "text" : "password"}
                      name="password"
                      value={field.value}
                      onChange={({ target }) =>
                        setValue("password", target.value)
                      }
                      className="w-full px-4 py-3 rounded-lg bg-gray-100 mt-2 text-[13px]
                focus:bg-white focus:outline-none h-[45px]"
                      placeholder="Enter your password"
                      error={errors.password?.message}
                      togglePassword={togglePwd}
                      isLoginPage={true}
                      addornment={
                        !showPwd ? <EyeOff size={20} /> : <Eye size={20} />
                      }
                    />
                  )}
                />
              </div>
              <Button
                type="submit"
                loading={loading}
                className="w-full h-[45px] block bg-blue-500 hover:bg-blue-400 focus:bg-blue-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
              >
                Log In
              </Button>
            </form>

            <hr className="my-6 border-gray-300 w-full" />

            <div onClick={handleGoogleLogin}>
              <ShineBorder
                color={"#ff6c37"}
                borderWidth={1}
                className="w-full relative block border hover:shadow-md cursor-pointer font-semibold text-gray-500"
              >
                {/* <Button
                type="button"
                className="w-full  border-none  focus:bg-gray-100 text-gray-900 font-semibold rounded-lg px-4 py-3 border border-gray-300 h-[45px] text-[14px]"
                loading={googleLoading}
                
              > */}
                <div className="flex items-center justify-center mt-2">
                  {/* <img src={google} className="w-[20px] h-[20px] mr-[10px]" /> */}
                  Join the waitlist
                </div>
                {/* </Button> */}
              </ShineBorder>
            </div>
            {/* <p className="mt-8">
              Need an account?{" "}
              <a
                href="#"
                className="text-blue-500 hover:text-blue-700 font-semibold"
                onClick={handleButtonClick}
              >
                Create an account
              </a>
            </p> */}

            <p className="text-sm text-gray-500 mt-12">
              &copy; {new Date().getFullYear()} Wingg - All Rights Reserved.
            </p>
          </div>
        </Transition>

        <Transition
          className={page === "signup" ? "w-full" : "hidden"}
          show={page === "signup"}
          enter="transition-opacity duration-300 transition-transform duration-300"
          enterFrom="opacity-0 transform -translate-x-10"
          enterTo="opacity-100 transform translate-x-0"
          leave="transition-opacity duration-300 transition-transform duration-300"
          leaveFrom="opacity-100 transform translate-x-0"
          leaveTo="opacity-0 transform translate-x-10"
        >
          <div className="max-w-full h-100">
            <img src={winggTextLogo} className="h-[80px] mb-4" alt="wingg" />

            <h1 className="text-xl md:text-x text-gray-700">
              Sign up to Wingg
            </h1>

            <form onSubmit={signupSubmit(onSubmitSignup)}>
              <div className="mt-4">
                <Label>Full Name</Label>
                <Controller
                  name="name"
                  control={signupControl}
                  render={({ field }) => (
                    <Input
                      id="name"
                      type="text"
                      name="name"
                      value={field.value}
                      onChange={({ target }) =>
                        signupSetValue("name", target.value)
                      }
                      className="w-full px-4 py-3 rounded-lg bg-gray-100 mt-2 text-[13px]
                focus:bg-white focus:outline-none h-[45px]"
                      placeholder="Enter your full name"
                      error={signupError.name?.message}
                    />
                  )}
                />
              </div>
              <div className="mt-3">
                <Label>Email Address</Label>
                <Controller
                  name="email"
                  control={signupControl}
                  render={({ field }) => (
                    <Input
                      id="email"
                      type="email"
                      name="email"
                      value={field.value}
                      onChange={({ target }) =>
                        signupSetValue("email", target.value)
                      }
                      className="w-full px-4 py-3 rounded-lg text-[13px] bg-gray-100 mt-2
                focus:bg-white focus:outline-none h-[45px]"
                      placeholder="awesome@domain.com"
                      error={signupError.email?.message}
                    />
                  )}
                />
              </div>

              <div className="mt-4">
                <Label>Password</Label>
                <Controller
                  name="password"
                  control={signupControl}
                  render={({ field }) => (
                    <Input
                      id="password"
                      type={showPwd ? "text" : "password"}
                      name="password"
                      value={field.value}
                      onChange={({ target }) =>
                        signupSetValue("password", target.value)
                      }
                      className="w-full px-4 py-3 rounded-lg bg-gray-100 mt-2 text-[13px]
                focus:bg-white focus:outline-none h-[45px]"
                      placeholder="Enter your password"
                      error={signupError.password?.message}
                      togglePassword={togglePwd}
                      isLoginPage={true}
                      addornment={
                        !showPwd ? <EyeOff size={20} /> : <Eye size={20} />
                      }
                    />
                  )}
                />
              </div>
              <Button
                type="submit"
                loading={signupLoading}
                className="w-full h-[45px] block bg-blue-500 hover:bg-blue-400 focus:bg-blue-400 text-white font-semibold rounded-lg px-4 py-3 mt-6"
              >
                Sign Up
              </Button>
            </form>

            <hr className="my-6 border-gray-300 w-full" />

            {/* <button
              type="button"
              className="w-full block bg-white hover:bg-gray-100 focus:bg-gray-100 text-gray-900 font-semibold rounded-lg px-4 py-3 border border-gray-300"
            >
              <div
                className="flex items-center justify-center"
                onClick={() => {
                  message.error(
                    "Google login is disabled for now. Please use email to signup"
                  );
                }}
              >
                <img src={google} className="w-[20px] h-[20px] mr-[10px]" />
                <span className="ml-4">Sign up with Google</span>
              </div>
            </button> */}
            <div onClick={handleGoogleLogin}>
              <ShineBorder
                color={"#ff6c37"}
                borderWidth={1}
                className="w-full relative block border hover:shadow-md cursor-pointer font-semibold text-gray-500"
              >
                {/* <Button
                type="button"
                className="w-full  border-none  focus:bg-gray-100 text-gray-900 font-semibold rounded-lg px-4 py-3 border border-gray-300 h-[45px] text-[14px]"
                loading={googleLoading}
                
              > */}
                <div className="flex items-center justify-center mt-2">
                  {/* <img src={google} className="w-[20px] h-[20px] mr-[10px]" /> */}
                  Join the waitlist
                </div>
                {/* </Button> */}
              </ShineBorder>
            </div>

            <p className="mt-8">
              Already have an account?{" "}
              <a
                href="#"
                className="text-blue-500 hover:text-blue-700 font-semibold"
                onClick={handleButtonClick}
              >
                Log in
              </a>
            </p>

            <p className="text-sm text-gray-500 mt-12">
              &copy; {new Date().getFullYear()} Wingg - All Rights Reserved.
            </p>
          </div>
        </Transition>
      </div>
    </section>
  );
}
