import React from "react";
import { Navigate } from "react-router-dom";
import { notification } from "antd";

import { useUser } from "@/providers/userProvider";
import { useToken } from "@/providers/useAuthProvider";
import { appRoutes } from "@/utils/paths";

interface ProtectedRouteProps {
  children: React.ReactNode;
  allowedRoles: string[];
}

const ProtectedRoute = ({ children, allowedRoles }: ProtectedRouteProps) => {
  const { user } = useUser();
  const token = useToken();

  if (!token.access_token) {
    return <Navigate to={appRoutes.auth.INDEX} />;
  }

  if (!allowedRoles.includes(user?.role ?? "admin")) {
    notification.open({
      message: "Unauthorized",
      description: "You are not authorized to access this page",
      type: "error",
      placement: 'top',
      role: 'status'
    });
    return <Navigate to={appRoutes.dashboard.INDEX} />;
  }

  return children;
};

export default ProtectedRoute;
