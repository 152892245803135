import React from "react";
import { Transition } from "@headlessui/react";
import { useQuery, useMutation } from "@tanstack/react-query";
import QrCode from "qrcode";
import {message,Button} from 'antd'
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "@/components/ui/input-otp";

import Text from "@/components/Text";
import { Input } from "@/components/ui/input";

import { Loader2 } from "lucide-react";

import { useUserDispatch, useUser } from "@/providers/userProvider";

import use2FA from "../hooks/use2FA";

type twofaView = "qr" | "code";

const TwoFA = () => {
  const { user } = useUser();
  const dispatch = useUserDispatch();
  const [view, setView] = React.useState<twofaView>("qr");

  const { init2FA, validate2FA } = use2FA();
  const [codeImage, setCodeImage] = React.useState<string>("");

  const { data, isLoading } = useQuery({
    queryKey: ["init2FA"],
    queryFn: init2FA,
  });

  const { mutateAsync, isPending } = useMutation({
    mutationFn: ({ token }: { token: string }) => validate2FA(token),
    mutationKey: ["validate2FA"],
    onError: (error) => {
      message.error(error.message);
    },
    onSuccess: (data) => {
      if (user && data) {
        if (data.status === 200) {
          dispatch?.setUser({ ...user, is_2fa_enabled: true });
        }
      }
    },
  });

  React.useEffect(() => {
    if (data) {
      QrCode.toDataURL(data.data.qr_code).then((url: string) => {
        setCodeImage(url);
      });
    }
  }, [data]);

  const handleChange = async (value: string) => {
    if (value.length === 6) {
      await mutateAsync({ token: value });
    }
  };
  return (
    <>
      {user?.is_2fa_enabled ? null : (
        <div>
          <Transition
            show={view === "qr"}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {isLoading ? (
              <div className="text-center mt-[20px]">Loading...</div>
            ) : (
              <div className="flex flex-col items-center justify-center py-[60px] gap-[10px]">
                <Text bold variant="secondary" className="text-[20px] text-center">
                  Scan the QR from <a href="https://www.google.com/search?q=authenticator+app" target="_blank"><span className="text-sm text-blue-500 hover:underline cursor-pointer">
                    authenticator app
                  </span></a>
                </Text>
                <img src={codeImage} height={200} width={200} />
                <Text variant="primary" toSmall>
                  Or enter the code manually
                </Text>
                <Input
                  placeholder="Enter the code"
                  className="w-[300px] font-mono"
                  value={data?.data.manual_code}
                  // addornment={<Files size={20} />}
                />
                <Button className="w-[300px]" type="dashed" onClick={() => setView("code")}>
                  Continue
                </Button>
              </div>
            )}
          </Transition>

          <Transition
            show={view === "code"}
            enter="transition-opacity duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="flex flex-col items-center justify-center gap-[10px] py-[10px]"
          >
            {!isPending ? (
              <div className="flex flex-col items-center justify-center gap-[10px] py-[50px]">
                <Text bold variant="secondary" className="text-[20px] text-gray-700">
                  Verify the 2FA code
                </Text>
                <div className="mt-5">
                  <InputOTP
                    autoFocus
                    inputMode="numeric"
                    maxLength={6}
                    onChange={handleChange}
                  >
                    <InputOTPGroup>
                      <InputOTPSlot className="w-[40px] h-[40px] text-[20px]" index={0} />
                      <InputOTPSlot className="w-[40px] h-[40px] text-[20px]" index={1} />
                      <InputOTPSlot className="w-[40px] h-[40px] text-[20px]" index={2} />
                    </InputOTPGroup>
                    <InputOTPSeparator />
                    <InputOTPGroup>
                      <InputOTPSlot className="w-[40px] h-[40px] text-[20px]" index={3} />
                      <InputOTPSlot className="w-[40px] h-[40px] text-[20px]" index={4} />
                      <InputOTPSlot className="w-[40px] h-[40px] text-[20px]" index={5} />
                    </InputOTPGroup>
                  </InputOTP>
                </div>
              </div>
            ) : (
              <Loader2
                size={40}
                strokeWidth={1.2}
                className="animate-spin my-12"
              />
            )}
          </Transition>
        </div>
      )}
    </>
  );
};

export default TwoFA;
