import React from "react";
import http from "@/http";
import { message } from "antd";

import { AxiosError } from "axios";

import { UpdateResponse } from "./types";

export default function useUpdateProfileImage() {
  const [loading, setLoading] = React.useState(false);
  const [response, setResponse] = React.useState<UpdateResponse | null>(null);
  const updateProfileImage = async (file: File) => {
    setLoading(true);
    message.open({
      type: "loading",
      content: "Uploading profile image...",
      key: "uploading",
    });
    try {
      const formData = new FormData();
      formData.append("profileImage", file);
      const response = http.post<UpdateResponse>(
        "/setting/update-profile-picture",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setLoading(false);
      setResponse((await response).data);
      message.destroy("uploading");
      message.success("Profile image uploaded successfully");
    } catch (error) {
      setLoading(false);
      if (error instanceof AxiosError) {
        message.warning(error.message);
        message.error((error.response?.data as { message: string }).message);
      }
    }
  };

  return {
    updateProfileImage,
    loading,
    data: response,
  };
}
