/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useCallback } from "react";

import {
  WebSocketEvent,
  NotificationType,
  NotificationEvent,
} from "@/types/notifications.types";

export interface WebSocketMessage {
  event: WebSocketEvent;
  payload: any;
  userId?: string;
}

interface Payload {
  payload: {
    data: {
      message: string;
      title: string;
      type: NotificationType;
    };
  };
  event: NotificationEvent;
}

const useWebSocket = () => {
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [response, setResponse] = useState<Payload>();
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const ws = new WebSocket(import.meta.env.VITE_WS_URL as string);
    ws.onopen = () => {
      console.log("Connected to wingg server");
      setIsConnected(true);
    };
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setResponse(data);
    };
    ws.onclose = () => {
      setIsConnected(false);
    };
    ws.onerror = (error) => {
      console.error("socket error:", error);
    };
    setSocket(ws);
    return () => {
      ws.close();
    };
  }, []);

  const sendMessage = useCallback(
    (event: WebSocketEvent, payload: any, userId?: string) => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        const message: WebSocketMessage = { event, payload, userId };
        socket.send(JSON.stringify(message));
      } else {
        console.error("socket is not connected");
      }
    },
    [socket]
  );

  const registerConnection = (workflowId: string) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const message = JSON.stringify({
        event: WebSocketEvent.REGISTER,
        workflowId,
      });
      socket.send(message);
    }
  };

  const subscribe = (user_id: string) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const message = JSON.stringify({
        event: WebSocketEvent.SUBSCRIBE,
        user_id,
      });
      socket.send(message);
    }
  };

  return { response, sendMessage, isConnected, registerConnection, subscribe };
};

export default useWebSocket;
