import React from "react";

interface FiltersContextProps {
  selectedStatus: string[];
  selectedPriority: string[];
  setSelectedStatus: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedPriority: React.Dispatch<React.SetStateAction<string[]>>;
  applyFilters: () => void;
  removeFilters: () => void;
  combinedFilters: string[]; // Read-only property for combined filters
}

interface FiltersProviderProps {
  children: React.ReactNode;
}

const FiltersContext = React.createContext<FiltersContextProps | undefined>(undefined);

export const FiltersProvider: React.FC<FiltersProviderProps> = ({ children }) => {
  const [selectedStatus, setSelectedStatus] = React.useState<string[]>([]);
  const [selectedPriority, setSelectedPriority] = React.useState<string[]>([]);

  // Combined filters array
  const combinedFilters = [...selectedStatus, ...selectedPriority];

  const applyFilters = () => {
    // Here you might want to persist the combined filters or trigger filtering
    console.log("Filters applied:", combinedFilters);
  };

  const removeFilters = () => {
    setSelectedStatus([]);
    setSelectedPriority([]);
  };

  return (
    <FiltersContext.Provider
      value={{
        selectedStatus,
        selectedPriority,
        setSelectedStatus,
        setSelectedPriority,
        applyFilters,
        removeFilters,
        combinedFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

// Hook for using Filters Context
// eslint-disable-next-line react-refresh/only-export-components
export const useFilters = () => {
  const context = React.useContext(FiltersContext);
  if (!context) {
    throw new Error("useFilters must be used within a FiltersProvider");
  }
  return context;
};
