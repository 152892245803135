import http from "@/http";
import { message } from "antd";

import { Init2FAResponse } from "./types";

import { ExtendedAxiosError } from "@/types/index";

const use2FA = () => {
  const init2FA = async () => {
    return await http.get<Init2FAResponse>("/setting/enable-2fa");
  };

  const disable2FA = async () => {
    return await http.put<{ message: string }>("/setting/disable-2fa");
  }

  const validate2FA = async (token: string) => {
    try {
      const response = await http.post<{ message: string }>(
        "/setting/verify-2fa-token",
        {
          token,
        }
      );
      message.success(response.data.message ?? "Unknown error occurred");
      return response;
    } catch (error: unknown) {
      message.error(
        (error as ExtendedAxiosError).response?.data?.message ??
          "Unknown error occurred"
      );
    }
  };

  return {
    init2FA,
    validate2FA,
    disable2FA
  };
};

export default use2FA;
