import React from "react";
import { message, Flex, notification } from "antd";
import Confetti from "react-confetti";
import { useSearchParams, useNavigate } from "react-router-dom";

import { ReactComponent as GmailIcon } from "@/assets/svgs/gmail.svg";

import Text from "@/components/Text";

import { useUser } from "@/providers/userProvider";

const Account = () => {
  const { user } = useUser();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const errorMessage = searchParams.get("error");
  const successMessage = searchParams.get("success");

  const [showConfetti, setShowConfetti] = React.useState(false);

  React.useEffect(() => {
    if (errorMessage) {
      if (errorMessage === "access_denied") {
        notification.error({
          message: "Access Denied",
          description: "You have denied the access to your google account.",
          duration: 10,
          showProgress: true,
        });
        setTimeout(() => {
          navigate(window.location.pathname, { replace: true });
        }, 5200);
      } else
        message.error({
          content: atob(errorMessage),
          duration: 10,
          type: "error",
        });
      setTimeout(() => {
        navigate(window.location.pathname, { replace: true });
      }, 5200);
    }
    if (successMessage) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 5000);
      message.success(atob(successMessage));
      setTimeout(() => {
        navigate(window.location.pathname, { replace: true });
      }, 5000);
    }
  }, [errorMessage, successMessage, navigate]);

  return (
    <div className="bg-white w-full m-0 px-5 py-[15px] border-tl rounded-tl-xl">
      <Flex align="center" gap={5}>
        <GmailIcon className="h-[30px] w-[30px]" />
        <Text big>Google Account</Text>
      </Flex>
      <Text
        variant="primary"
        toSmall
        className="mt-1 overflow-hidden text-ellipsis"
      >
        {user?.inbox?.inbox_account_email || "No Google account connected"}
      </Text>
      {showConfetti && <Confetti />}
    </div>
  );
};

export default Account;
