import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import { Loader2 } from "lucide-react";
import getContractImage from "./hooks";

import { useDrawer } from "@/providers/DrawerContext";
import { useGeometry } from "@/providers/geometryProvider";
import { useScale } from "@/providers/ScaleProvider";

const ImageViewer = () => {
  const { state, hash } = useLocation();
  const { data, isLoading } = useQuery({
    queryKey: ["getImage", hash || state?.ref_id],
    queryFn: () =>
      getContractImage.getContractImage({
        ref_id: hash?.split("#")[1] || state?.ref_id,
      }),
  });

  const { height } = useDrawer();
  const { geometry } = useGeometry();
  const { scale } = useScale();
  const parentRef = useRef<HTMLDivElement>(null);
  const annotationDivRef = useRef<HTMLDivElement>(null);

  const isGeometryEmpty = () => {
    return (
      geometry.bounding_box?.left === 0 &&
      geometry.bounding_box?.top === 0 &&
      geometry.bounding_box?.width === 0 &&
      geometry.bounding_box?.height === 0 &&
      (!geometry.polygon || geometry.polygon.length === 0)
    );
  };

  const drawBoundingBox = (
    ctx: CanvasRenderingContext2D,
    bbox: { height: number; width: number; left: number; top: number },
    scaledWidth: number,
    scaledHeight: number,
    annotationLabel?: string,
    padding: number = 3 // Padding value (default 3px)
  ) => {
    const paddedLeft = bbox.left * scaledWidth - padding;
    const paddedTop = bbox.top * scaledHeight - padding;
    const paddedWidth = bbox.width * scaledWidth + 2 * padding;
    const paddedHeight = bbox.height * scaledHeight + 2 * padding;

    ctx.beginPath();
    ctx.rect(paddedLeft, paddedTop, paddedWidth, paddedHeight);
    ctx.lineWidth = 4;
    ctx.strokeStyle = "blue";
    ctx.stroke();

    if (annotationLabel) {
      ctx.font = "25px Arial";
      ctx.fillText(annotationLabel, paddedLeft, paddedTop - 0);
      // background
      ctx.fillStyle = "blue";
      ctx.fillRect(
        paddedLeft,
        paddedTop - 41,
        annotationLabel.length * 12 + 50,
        40
      );
      // text white
      ctx.fillStyle = "white";
      ctx.fillText(annotationLabel, paddedLeft + 10, paddedTop - 16);
      // rounded rectangle
      ctx.beginPath();
    }
  };

  const drawImagesOnCanvas = () => {
    if (data && parentRef.current) {
      const parent = parentRef.current;
      parent.innerHTML = ""; // Clear existing canvases

      data.data.images.forEach((imageData: { key: string }) => {
        const canvas = document.createElement("canvas");
        canvas.className = "max-w-full";
        parent.appendChild(canvas);
        const ctx = canvas.getContext("2d");
        if (ctx) {
          const image = new Image();
          image.src = imageData.key;

          image.onload = () => {
            const parentWidth = parent.clientWidth || 0;
            const parentHeight = parent.clientHeight || 0;

            const imageWidth = image.naturalWidth;
            const imageHeight = image.naturalHeight;

            const ratio = Math.min(
              parentWidth / imageWidth,
              parentHeight / imageHeight
            );

            const scaleFactor = window.devicePixelRatio || 1;
            const scaledWidth =
              imageWidth * ratio * scale.value * 2 * scaleFactor; // Apply the scale state
            const scaledHeight =
              imageHeight * ratio * scale.value * 2 * scaleFactor;

            canvas.width = scaledWidth;
            canvas.height = scaledHeight;
            canvas.style.width = scaledWidth / scaleFactor + "px";
            canvas.style.height = scaledHeight / scaleFactor + "px";

            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = "high";
            ctx?.clearRect(0, 0, scaledWidth, scaledHeight);
            ctx?.drawImage(image, 0, 0, scaledWidth, scaledHeight);

            // Only draw the bounding box and polygon if geometry is not empty
            if (ctx && !isGeometryEmpty()) {
              if (geometry.bounding_box) {
                drawBoundingBox(
                  ctx,
                  geometry.bounding_box,
                  scaledWidth,
                  scaledHeight,
                  geometry.anotation_label
                );
              }
            }
          };
        }
      });
    }
  };

  useEffect(() => {
    drawImagesOnCanvas();

    const handleResize = () => {
      drawImagesOnCanvas();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [data, geometry, scale.value]);

  return (
    <div
      className={
        isLoading
          ? "flex items-center justify-center h-[calc(100vh-150px)]"
          : "mt-[80px] z-[1]"
      }
    >
      {isLoading && (
        <Loader2
          size={50}
          strokeWidth={1.8}
          className="animate-spin dark:text-blue-700"
        />
      )}
      <div
        style={{
          marginBottom: 50 + height + "px",
        }}
        ref={parentRef}
        className="relative h-auto"
      >
        <div ref={annotationDivRef} className="hidden"></div>
      </div>
    </div>
  );
};

export default ImageViewer;
