/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { useQuery } from "@tanstack/react-query";
import { Pagination } from "antd";

import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Trash, Loader2, ShieldCheck } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuLabel,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { UserDataColumns } from "./colums/usersColumn";

import RemoveUser from "./components/RemoveUser";
import UpdatePermission from "./components/UpdatePermission";

import { useUsers } from "./hooks/useUsers";

import { User } from "./hooks/types";

import { Empty } from "antd";

export function UsersTable() {
  const { fetchAssosiateUsers } = useUsers();

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);

  const [page, setPage] = React.useState(1);
  const [pageSize] = React.useState(10);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["assosited_users", page, pageSize],
    queryFn: () => fetchAssosiateUsers(page, pageSize),
  });

  React.useEffect(() => {
    setPage(page);
  }, [page, pageSize]);

  /* ---- Action Functions ----*/
  const handleDeleteModalOpener = (user: User) => {
    setShowDeleteDialog(true);
    setSelectedUser(user);
  };

  const handleUpdatePermission = (inviteDetails: User) => {
    setShowUpdateDialog(true);
    setSelectedUser(inviteDetails);
  };

  /* ---- Table Update Functions ----*/

  const table = useReactTable({
    data: data?.data?.data || [],
    manualPagination: true,
    columns: [
      ...UserDataColumns,
      {
        id: "actions",
        cell: ({ row }) => {
          const fileName = row.getValue("name") as string;
          const currentUser = row.original;
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="ghost"
                  value={fileName}
                  className="h-8 w-8 p-0"
                >
                  <span className="sr-only">Open menu</span>
                  <DotsHorizontalIcon className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="font-popins">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={() => handleUpdatePermission(currentUser)}
                  className="flex items-center gap-1 text-[#7c7c7c]"
                >
                  <ShieldCheck size={20} />
                  Workflow permission
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => handleDeleteModalOpener(currentUser)}
                  className="flex items-center gap-1 text-[#7c7c7c] hover:!text-red-500"
                >
                  <Trash size={20} />
                  Remove user
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ],
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="w-[98%] mx-4 max-h-[550px] overflow-x-scroll">
      {!isLoading ? (
        <div>
          <RemoveUser
            onClose={() => setShowDeleteDialog(false)}
            showDeleteDialog={showDeleteDialog}
            userDetails={selectedUser}
            refetch={refetch}
            key={2}
          />
          <UpdatePermission
            isOpen={showUpdateDialog}
            onClose={() => setShowUpdateDialog(false)}
            email={selectedUser?.email as string}
            assign_all={selectedUser?.assign_all as boolean}
            key={9}
            componentName="user"
          />
          <div className="flex items-center py-4 justify-between">
            <Input
              placeholder="Filter users with their name..."
              value={
                (table.getColumn("name")?.getFilterValue() as string) ?? ""
              }
              onChange={(event) =>
                table.getColumn("name")?.setFilterValue(event.target.value)
              }
              className="min-w-[500px] w-full"
            />
          </div>
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table
                    .getRowModel()
                    .rows.sort((a, b) => {
                      const a_date = new Date(a.original.createdAt);
                      const b_date = new Date(b.original.createdAt);
                      return b_date.getTime() - a_date.getTime();
                    })
                    .map((row) => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={UserDataColumns.length + 1}
                      className="h-24 text-center"
                    >
                      <Empty
                        className="py-[10px] font-popins flex items-center justify-center flex-col"
                        imageStyle={{ height: 100 }}
                        description=" No assosiates users found!!"
                        image={
                          "https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                        }
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <div className="flex items-center justify-end space-x-2 py-4">
            <div className="space-x-2">
              <Pagination
                total={data?.data.pagination.total || 0}
                current={page}
                showLessItems
                showTitle
                showQuickJumper
                showSizeChanger={false}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center mt-[40px] gap-[6px]">
          <Loader2 className="animate-spin h-5 w-5 text-gray-400" />
          <p className="text-gray-400">Loading...</p>
        </div>
      )}
    </div>
  );
}
