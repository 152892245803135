/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Empty,Pagination } from "antd";
import { useQuery } from "@tanstack/react-query";

import {
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { X, Loader2, Send, ShieldCheck } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuLabel,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { InviteDataColumns } from "./colums/inviteColumn";

import DeleteInvitation from "./components/DeleteInvitation";
import InviteUser from "./components/InviteUser";
import UpdatePermission from "./components/UpdatePermission";

import { useUsers } from "./hooks/useUsers";

import { InviteUser as InterfaceInviteUser } from "./hooks/types";

import NoInvite from "@/assets/svgs/empty-invitation.svg";

export function InviteTable() {
  const { fetchPendingInvites } = useUsers();

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showDialog, setShowDialog] = React.useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const [inviteDetails, setInviteDetails] =
    React.useState<InterfaceInviteUser | null>(null);

  const [page, setPage] = React.useState(1);
  const [pageSize] = React.useState(10);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["inviteUsers", { page, pageSize }],
    queryFn: () => fetchPendingInvites({ limit: pageSize, page: page }),
  });

  React.useEffect(() => {
    setPage(page);
  }, [page]);

  /* ---- Action Functions ----*/
  const handleDeleteModalOpener = (inviteDetails: InterfaceInviteUser) => {
    setShowDeleteDialog(true);
    setInviteDetails(inviteDetails);
  };

  const handleUpdatePermission = (inviteDetails: InterfaceInviteUser) => {
    setShowUpdateDialog(true);
    setInviteDetails(inviteDetails);
  };

  const handleInviteNewUser = () => {
    setShowDialog(true);
  };

  /* ---- Table Update Functions ----*/

  const table = useReactTable({
    data: data?.data.data ?? [],
    manualPagination: true,
    columns: [
      ...InviteDataColumns,
      {
        id: "actions",
        cell: ({ row }) => {
          const user = row.original;
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <DotsHorizontalIcon className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="font-popins">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <DropdownMenuItem
                  onClick={() => handleUpdatePermission(user)}
                  className="flex items-center gap-1 text-[#7c7c7c]"
                >
                  <ShieldCheck size={20} />
                  Workflow permission
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => handleDeleteModalOpener(user)}
                  className="flex items-center gap-1 text-[#7c7c7c] hover:!text-red-500"
                >
                  <X size={20} />
                  Cancel invite
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ],
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  return (
    <div className="w-[98%] mx-4 max-h-[550px] overflow-x-scroll">
      {!isLoading ? (
        <div>
          <DeleteInvitation
            onClosed={() => setShowDeleteDialog(false)}
            showDeleteDialog={showDeleteDialog}
            inviteDetails={inviteDetails}
            refetch={refetch}
            key={Math.random()}
          />
          <InviteUser
            isOpen={showDialog}
            onClose={() => setShowDialog(false)}
            key={Math.random()}
            refetch={refetch}
          />
          <UpdatePermission
            componentName="invite"
            isOpen={showUpdateDialog}
            onClose={() => setShowUpdateDialog(false)}
            email={inviteDetails?.email as string}
            assign_all={inviteDetails?.assign_all as boolean}
            key={2}
          />
          <div className="flex items-center py-4 justify-between">
            <Input
              placeholder="Filter users with their email..."
              value={
                (table.getColumn("email")?.getFilterValue() as string) ?? ""
              }
              onChange={(event) =>
                table.getColumn("email")?.setFilterValue(event.target.value)
              }
              className="min-w-[500px] w-full"
            />
            <Button
              className="flex gap-[5px] items-center"
              onClick={handleInviteNewUser}
            >
              <Send strokeWidth={1.2} size={20} />
              Invite user
            </Button>
          </div>
          <div className="rounded-md border">
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={InviteDataColumns.length + 1}
                      className="h-24 text-center"
                    >
                      <Empty
                        description="No invitation found!!"
                        image={NoInvite}
                        className="w-full flex flex-col py-[20px] justify-center items-center font-popins"
                        imageStyle={{ height: 100 }}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <div className="flex items-center justify-end space-x-2 py-4">
            <div className="space-x-2">
              <Pagination 
                current={page + 1}
                total={data?.data.pagination.total ?? 0}
                pageSize={pageSize}
                showLessItems
                onChange={(page) => setPage(page - 1)}
                onShowSizeChange={(current) => {
                  setPage(current);
                }
                }
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center mt-[40px] gap-[6px]">
          <Loader2 className="animate-spin h-5 w-5 text-gray-400" />
          <p className="text-gray-400">Loading...</p>
        </div>
      )}
    </div>
  );
}
