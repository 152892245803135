import Headnav from "@/components/Navbars/Headnav";
import useHead from "@/hooks/useHead";
import { useLocation } from "react-router-dom";

import WorkflowDataTable from '@/components/table/workflowDataTable'

const Workflow = () => {
  useHead({
    title: "Workflow | Wingg",
    description: "Workflow for Wingg",
  });

  const { pathname } = useLocation();

  return (
    <div>
      <Headnav
        heading={`Workflow/${pathname.split("/")?.pop()?.split("-")[0]
        }`}
      />
      <WorkflowDataTable />
    </div>
  );
};

export default Workflow;
