import React from "react";
import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { AxiosError } from "axios";
import { File } from "lucide-react";

import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import Text from "@/components/Text";

import { formatSingleFileSize } from "@/helper/sizeCalculator";
import { Document } from "@/types/document";

import useUpdateFile from "../hooks/useUpdateMeta";

interface Props {
  setShowEditFile: React.Dispatch<React.SetStateAction<boolean>>;
  showEditFile: boolean;
  documentInfo: Document;
  refetch: () => void;
}

const EditDocument = ({
  setShowEditFile,
  showEditFile,
  documentInfo,
  refetch,
}: Props) => {
  const [fileName, setFileName] = React.useState(documentInfo?.file_name);

  const { updateFile } = useUpdateFile();

  React.useEffect(() => {
    setFileName(documentInfo?.file_name);
  }, [documentInfo]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    mutateAsync();
  };

  const { mutateAsync, isPending, error } = useMutation({
    mutationKey: ["updateFile", documentInfo?.documentId, fileName],
    mutationFn: () =>
      updateFile(documentInfo?.documentId, { file_name: fileName }),
    onSuccess: (data) => {
      refetch();
      setFileName(fileName);
      setShowEditFile(false)
      return message.success(data.data.message);
    },
    onError: (error:AxiosError) => {
      return message.error((error.response?.data as {message: string}).message);
    }
  });

  const documentCreatedOn = new Date(documentInfo?.createdAt);

  return (
    <Sheet open={showEditFile} modal={true} onOpenChange={()=>setShowEditFile(false)}>
      <SheetContent className="min-w-[500px]">
        <SheetHeader>
          <form onSubmit={onSubmit}>
            <SheetTitle>Update file metadata</SheetTitle>
            <SheetDescription className="">
              {documentInfo?.file_name}
            </SheetDescription>
            <div className="grid gap-4 py-4 w-full mt-[10px]">
              <div className="mb-[-20px] flex items-center justify-between">
                <Label>File Name</Label>
                <Button
                  className="mr-[-10px] !outline-none focus:outline-none focus-within:outline-none focus-visible:outline-none"
                  type="reset"
                  onClick={() => setFileName(documentInfo?.file_name)}
                  variant={"link"}
                >
                  reset
                </Button>
              </div>
              <Input
                type="text"
                id="file_name"
                name="file_name"
                value={fileName}
                placeholder="File name here"
                onChange={({ target }) => setFileName(target.value)}
                error={
                  fileName?.length <= 0 ? "It can not be empty" : error?.message
                }
                addornment={<File size={20} />}
              />
              <Text semiBold big className="mt-[30px] mb-[10px]">
                File's meta information
              </Text>
              <Label className="flex gap-[5px] items-center justify-between">
                Created on <Text>{documentCreatedOn.toUTCString()}</Text>
              </Label>
              <Label className="flex gap-[5px] items-center justify-between">
                File format <Text>{documentInfo?.format}</Text>
              </Label>
              <Label className="flex gap-[5px] items-center justify-between">
                Public visible{" "}
                <Text>{documentInfo?.isPublic ? "Yes" : "No"}</Text>
              </Label>
              <Label className="flex gap-[5px] items-center justify-between">
                File size{" "}
                <Text>{formatSingleFileSize(documentInfo?.size)}</Text>
              </Label>
              <Label className="flex gap-[5px] items-center justify-between">
                Encrypted{" "}
                <Text>{documentInfo?.isEncrypted ? "Yes" : "No"}</Text>
              </Label>
              <Label className="flex gap-[5px] items-center justify-between">
                Shared with someone{" "}
                <Text>{documentInfo?.isShared ? "Yes" : "No"}</Text>
              </Label>
            </div>
            <div className="flex items-start gap-[15px] mt-[30px]">
              <Button
                type="submit"
                className="w-[100px]"
                loading={isPending}
                variant={"default"}
              >
                Save
              </Button>
            </div>
          </form>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

export default EditDocument;
