import { useState } from "react";
import { Button, Input, Form, Select, message } from "antd";
import { Circle } from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import { ArrowRightToLine } from "lucide-react";
import http from "@/http";
import { AxiosError } from "axios";

interface ProfessionProps {
  setStep: (
    value:
      | "org_setup"
      | "onboarded"
      | "department_setup"
      | "team_setup"
      | "profession_details"
      | "welcome_screen"
      | "plan_selection"
  ) => void;
  professions: string[];
  selectedOption: "INDIVIDUAL" | "ORGAZNIZATION" | null;
}

export default function Profession({
  setStep,
  professions,
  selectedOption,
}: ProfessionProps) {
  const [selectedProfession, setSelectedProfession] = useState<string | null>(
    null
  );
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [form] = Form.useForm();

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 120 }} defaultValue={"91"}>
        <Select.Option value="91">+ 91 India</Select.Option>
      </Select>
    </Form.Item>
  );

  const { mutate, isPending } = useMutation({
    mutationKey: [
      "createProfession",
      selectedProfession,
      phoneNumber,
      selectedOption,
    ],
    mutationFn: () =>
      http.post("/user/onboard-profession-details", {
        phoneNumber,
        selectedProfession,
        selectedOption,
      }),
    onError: (error: AxiosError) => {
      return message.error(
        (error.response?.data as { message: string }).message
      );
    },
    onSuccess: () => {
      setStep(selectedOption === "INDIVIDUAL" ? "welcome_screen" : "org_setup");
    },
  });

  return (
    <div>
      <div className="max-w-4xl mx-auto">
        <h1 className="text-5xl font-light mb-6 text-gray-800">
          Select your {selectedOption === "INDIVIDUAL" ? "profession" : "team"}
        </h1>
        <p className="text-gray-600 mb-8">
          This ensures you receive more accurate recommendations.
        </p>

        {/* Profession Grid */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mb-8">
          {professions.map((profession) => (
            <Button
              key={profession}
              type="dashed"
              className={`h-24 flex flex-col items-center justify-center text-center p-2 ${
                selectedProfession === profession
                  ? "border-orange-500 text-orange-500 shadow-md font-semibold shadow-orange-200"
                  : "hover:bg-orange-50 text-md"
              }`}
              onClick={() => setSelectedProfession(profession)}
            >
              {profession}
              {selectedProfession === profession ? (
                <Circle
                  size={15}
                  fill="#ff7f0f"
                  stroke="#fa9537"
                  className="text-gray-300 absolute top-2 right-2 border-orange-400"
                />
              ) : (
                <Circle
                  size={15}
                  className="text-gray-300 absolute top-2 right-2"
                />
              )}
            </Button>
          ))}
        </div>

        {/* Phone Number Input */}
        <Form form={form} className="mb-8 " layout="vertical">
          <Form.Item
            label={
              <p className="text-gray-700">
                Phone number <span className="text-sm">(optional)</span>
              </p>
            }
            name="phone"
            rules={[
              {
                pattern: /^[0-9]{10}$/,
                message: "Please input a valid phone number!",
              },
            ]}
            hasFeedback
          >
            <Input
              id="phone"
              type="tel"
              inputMode="numeric"
              placeholder="Enter your phone number"
              value={phoneNumber || ""}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="max-w-md"
              addonBefore={prefixSelector}
              maxLength={10}
            />
          </Form.Item>
        </Form>

        {/* Navigation Buttons */}
        <div className="flex justify-between">
          <Button
            type="text"
            size="large"
            className="text-gray-600 hover:text-gray-800"
            onClick={() => setStep("plan_selection")}
          >
            Back
          </Button>
          <Button
            className="bg-orange-500 hover:bg-orange-600 text-white"
            disabled={!selectedProfession}
            size="large"
            icon={<ArrowRightToLine size={18} />}
            iconPosition="end"
            onClick={() => mutate()}
            loading={isPending}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
