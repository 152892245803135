import http from "@/http";

export const useRequestFormAPI = () => {
  const addRequest = async ({
    email,
    request_type,
    note,
  }: {
    email: string;
    request_type: string;
    note: string;
  }) => {
    return await http.post("/user/add-new-support-request", {
      email,
      request_type,
      note,
    });
  };

  return { addRequest };
};
