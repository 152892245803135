import {
  useRoutes,
  useLocation,
  Navigate,
} from "react-router-dom";
import { SnackbarProvider } from 'notistack'

import LoginPrompt from "@/components/LoginPrompt";

import { useAuth } from "@/providers/authContext";

import AuthStateHandler from "@/handler/AuthStateHandler";

import routesObject from "./routes";
import { appRoutes } from "@/utils/paths";

import { useToken } from "@/providers/useAuthProvider";

export default function Routes() {
  const routes = useRoutes(routesObject);
  const { showLoginPrompt } = useAuth();
  const token = useToken();

  const location = useLocation();

  if (Object.keys(token ?? {}).length) {
    if (location.pathname.startsWith(appRoutes.auth.INDEX)) {
      return <Navigate to={appRoutes.dashboard.INDEX} />;
    }
  }

  return (
    <>
      <AuthStateHandler />
      <LoginPrompt />
      <div className={showLoginPrompt ? "blur-sm scale-[10px]" : ""}>
        <SnackbarProvider preventDuplicate>
        {routes}
        </SnackbarProvider>
      </div>
    </>
  );
}
