import http, { createCancelToken } from "@/http";

import { AssosiatedUserResponse, InviteUserResponse } from "./types";

export const useUsers = () => {
  const fetchAssosiateUsers = async (page: number, limit: number) => {
    const res = await http.get<AssosiatedUserResponse>(
      "/user/get-all-users?page=" + page + "&limit=" + limit
    );
    return res;
  };

  const inviteUser = async ({
    email,
    role,
    assign_all,
  }: {
    email: string;
    role: string;
    assign_all: boolean;
  }) => {
    const res = await http.post<{ message: string }>("/user/invite-user", {
      email,
      role,
      assign_all,
    });
    return res;
  };

  const fetchPendingInvites = async ({
    page,
    limit,
  }: {
    page: number;
    limit: number;
  }) => {
    const res = await http.get<InviteUserResponse>(
      "/user/get-pending-invites?page=" + page + "&limit=" + limit
    );
    return res;
  };

  const cancelInvite = async (
    {
      email,
    }: {
      email: string;
    },
    cancelToken: ReturnType<typeof createCancelToken>
  ) => {
    const res = await http.delete<{ message: string }>("/user/cancel-invite", {
      data: { email },
      cancelToken: cancelToken.token,
    });
    return res;
  };

  const updateInviteRole = async ({
    email,
    role,
  }: {
    email: string;
    role: string;
  }) => {
    const res = await http.put<{ message: string }>(
      `/user/update-invites-role/${email}`,
      {
        role,
      }
    );
    return res;
  };

  const updateUserRole = async ({
    user_id,
    role,
  }: {
    user_id: string;
    role: string;
  }) => {
    const res = await http.put<{ message: string }>(
      `/user/update-role/${user_id}`,
      {
        role,
      }
    );
    return res;
  };

  const removeUser = async ({ user_id }: { user_id: string }) => {
    const res = await http.delete<{ message: string }>("/user/remove-user", {
      data: { user_id },
    });
    return res;
  };

  return {
    fetchAssosiateUsers,
    inviteUser,
    fetchPendingInvites,
    cancelInvite,
    updateInviteRole,
    updateUserRole,
    removeUser,
  };
};
