/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import LottiePlayer from "lottie-react";
import {Skeleton,message } from "antd";
import { useInView } from "react-intersection-observer";

import { useInfiniteQuery } from "@tanstack/react-query";
import { useLocation, useParams } from "react-router-dom";

import ListHeader from "../components/ListHeader";
import ScrollToActions from "./Scroller";


import emptyIboxLottie from "@/assets/lottis/empty-inbox.json";

import { useUser } from "@/providers/userProvider";
import { useMyMail } from "@/providers/myMailContext";
import { useThread } from "@/providers/thredContext";
import { useLabelSelect } from "@/providers/labelSelectContext";
import { useFilters } from "@/providers/FiltersContext";

import { gmailApi } from "../api/gmail";
import { Page, Label, Message } from "@/types/email.type";

import ConnectState from "./ConnectState";

import { useScroller } from "../hooks/useScroller";
import { useCombineMessage } from "../hooks/useCombineMessage";
import MessageUI from "./MessageUI";

interface ListPros {
  setGlobalSearch: React.Dispatch<React.SetStateAction<string>>;
  labels: Label[] | undefined;
}

const List: React.FC<ListPros> = ({ labels }) => {
  const { getMessageList } = gmailApi();
  const { user } = useUser();
  const { setPage, page } = useMyMail();
  const { setThread, thread } = useThread();
  const { labels: selectedLabels } = useLabelSelect();
  const { pathname } = useLocation()
  const { inbox_type } = useParams();
  const { selectedPriority, selectedStatus } = useFilters();

  const thread_id = pathname.split("/")[3];

  const { ref, inView } = useInView();
  const selectedThreadRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<HTMLDivElement>(null);

  const [pageQuery, setPageQuery] = React.useState<string>("INBOX");
  const [fetchNextPageCount, setFetchNextPageCount] = React.useState<number>(0);

  const {
    data,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
    status,
    refetch,
  } = useInfiniteQuery<Page, Error>({
    queryKey: ["getAllMails", pageQuery],
    queryFn: async ({ pageParam = null }) => {
      const response = await getMessageList(
        pageParam as string | null,
        pageQuery
      );
      return response.data as Page;
    },
    getNextPageParam: (lastPage) => {
      if (lastPage.messages.nextPageToken) {
        return lastPage.messages.nextPageToken ?? null;
      }
      return null;
    },
    initialPageParam: null,
    enabled: !!user?.inbox?.inbox_account_email,
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    _defaulted: true,
  });

  React.useEffect(() => {
    if (inView && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, isFetchingNextPage, hasNextPage]);

  const threadExistsInCurrentPage = page.messages.messages.some(
    (msg) => msg.threadId === thread?.thredId
  );
  React.useEffect(() => {
    if (thread_id) {
      setThread({
        thredId: thread_id,
      });
    }
  }, [thread_id, setThread]);


  React.useEffect(() => {
    const loadNextPageIfNeeded = async () => {
      if (
        !threadExistsInCurrentPage &&
        hasNextPage &&
        fetchNextPageCount < 2 &&
        thread_id
      ) {
        try {
          setLoadingNextPage(true);
          await fetchNextPage();
          setFetchNextPageCount((prev) => prev + 1);
        } catch (error) {
          message.error("Failed to fetch next page. Please try again.");
        } finally {
          setLoadingNextPage(false);
        }
      }
    };
    loadNextPageIfNeeded();
  }, [
    threadExistsInCurrentPage,
    fetchNextPage,
    hasNextPage,
    fetchNextPageCount,
    thread_id,
  ]);

  const combineMessages = useCombineMessage(
    data?.pages.flatMap((page) => page.messages.messages) || []
  ) as Message[];

  React.useEffect(() => {
    if (combineMessages) {
      setPage({
        lastSyncDate: data?.pages[0].lastSyncDate || new Date(),
        messages: {
          messages: combineMessages || [],
          nextPageToken: data?.pages[0].messages.nextPageToken || null,
        }
      })
    }
  }, [data]);

  React.useEffect(() => {
    const upperCaseInboxType = inbox_type?.toLocaleUpperCase();
    if (
      ["MY-MAILS", "ALL-MAILS", "UNASSIGNED"].includes(
        upperCaseInboxType as string
      )
    ) {
      setPageQuery("INBOX");
      refetch();
    } else if (upperCaseInboxType === "SENT") {
      setPageQuery("SENT");
      refetch();
    } else if (upperCaseInboxType === "SPAMS") {
      setPageQuery("SPAM");
      refetch();
    }
  }, [inbox_type, refetch]);

  const filterMessagesByInboxType = (
    messages: Message[],
    inboxType: string
  ) => {
    switch (inboxType.toLowerCase()) {
      case "my-mails":
        return messages.filter((mail) => (mail.assignees?.length || 0) > 0);
      case "unassigned":
        return messages.filter((mail) => (mail.assignees?.length || 0) === 0);
      case "sent":
      case "spams":
      case "all-mails":
        // No additional filter needed for these cases
        return messages;
      default:
        return messages; // Default to all messages
    }
  };

  React.useEffect(() => {
    if (!inbox_type || !data) return;

    // Flatten and sort initial messages from data
    const initialMessages = combineMessages || [];
    // Apply inbox type filter on initial load
    const filteredMessages = filterMessagesByInboxType(
      initialMessages,
      inbox_type
    );

    setPage({
      lastSyncDate: data.pages[0].lastSyncDate,
      messages: {
        messages: filteredMessages,
        nextPageToken: data.pages[0].messages.nextPageToken,
      },
    });
  }, [inbox_type, setPage]);


  // states
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [loadingNextPage, setLoadingNextPage] = React.useState<boolean>(false);

  const searchFilter = (search: string) => {
    setSearchTerm(search);
  };

  const updateMessagesBasedOnLabels = () => {
    const selectedLabel = selectedLabels.filter((label) => label.isSelected);
   
    const isUnassigned =
      inbox_type && inbox_type.toLowerCase() === "unassigned";
    const isAssigned = inbox_type && inbox_type.toLowerCase() === "my-mails";

    let filteredMessages = combineMessages

    if (isUnassigned) {
      filteredMessages = filteredMessages.filter(
        (mail) => mail.assignees.length === 0
      );
    }

    if (isAssigned) {
      filteredMessages = filteredMessages.filter(
        (mail) => mail.assignees.length > 0
      );
    }

    if (selectedLabel.length > 0) {
      filteredMessages = selectedLabel.flatMap((label) => {
        return filteredMessages.filter(
          (mail) => mail.label?.toLowerCase() === label.name.toLowerCase()
        );
      });
    }

    setPage((prev) => ({
      ...prev,
      messages: {
        messages: filteredMessages.sort((a,b) => a.internalDate > b.internalDate ? -1 : 1),
        nextPageToken: data?.pages[0]?.messages.nextPageToken || null,
      },
    }));
  };

  React.useEffect(() => {
    updateMessagesBasedOnLabels();
  }, [selectedLabels]);

  React.useEffect(() => {
    if (!selectedPriority.length && !selectedStatus.length) {
      const resetMessages = combineMessages

      const filteredMessages = filterMessagesByInboxType(
        resetMessages,
        inbox_type || "all-mails"
      );

      setPage((prev) => ({
        ...prev,
        messages: {
          messages: filteredMessages.sort((a,b) => a.internalDate > b.internalDate ? -1 : 1),
          nextPageToken: data?.pages[0]?.messages.nextPageToken || null,
        },
      }));
      return;
    }

    setPage((prev) => {
      let filteredMessages = combineMessages;

      if (selectedPriority.length) {
        filteredMessages = filteredMessages.filter((mail) =>
          selectedPriority.includes(mail.priority_level)
        );
      }

      if (selectedStatus.length) {
        filteredMessages = filteredMessages.filter((mail) =>
          selectedStatus.some(
            (status) => mail.status.toUpperCase() === status.toUpperCase()
          )
        );
      }

      return {
        ...prev,
        messages: {
          messages: filteredMessages,
          nextPageToken: prev.messages.nextPageToken,
        },
      };
    });
  }, [selectedPriority, selectedStatus, setPage, inbox_type]);

  const { scrollToThread, scrollToTop } = useScroller(
    selectedThreadRef,
    listRef
  );

  React.useEffect(() => {
    if (thread) {
      setPage((prev) => {
        const updatedMessages = combineMessages.map((mail) => {
          if (
            mail.threadId === thread.thredId &&
            mail.labelIds.includes("UNREAD")
          ) {
            return {
              ...mail,
              labelIds: mail.labelIds.filter((label) => label !== "UNREAD"),
            };
          }
          return mail;
        });

        const hasChanges = updatedMessages.some(
          (mail, index) =>
            JSON.stringify(mail) !==
            JSON.stringify(prev.messages.messages[index])
        );

        if (hasChanges) {
          return {
            ...prev,
            messages: {
              ...prev.messages,
              messages: updatedMessages,
            },
          };
        }
        return prev;
      });
    }
  }, [thread, setPage]);

  return (
    <div className="min-w-[470px] border-r">
      <div>
        <ListHeader
          loading={isLoading}
          count={
            page.messages.messages.length || 0
          }
          lastSyncDate={
            (page?.lastSyncDate as Date) || data?.pages[0].lastSyncDate
          }
          searchFilter={searchFilter}
          loadingNextPage={loadingNextPage}
        />
      </div>
      <div className="flex items-center min-w-[470px]">
        <div
          ref={listRef}
          className="h-[calc(100vh-100px)] overflow-scroll py-[5px] w-full scrollbar-visible overflow-x-hidden relative group"
        >
          {!user?.inbox?.inbox_account_email && <ConnectState />}
          {status === "pending" && isLoading && (
            <div className="p-4 mt-[-15px]">
              {Array.from({ length: 6 }).map((_, index) => (
                <Skeleton avatar key={index} active title className="p-4" />
              ))}
            </div>
          )}
          {status === "success" && (
            <>
              {page.messages.messages.length === 0 ? (
                <div className="flex flex-col items-center justify-center h-full gap-0">
                  <LottiePlayer
                    animationData={emptyIboxLottie}
                    loop={false}
                    autoplay
                    className="mx-auto w-full pl-10 h-[400px]"
                  />
                  <p className="text-center">Your inbox is empty</p>
                </div>
              ) : null}
            </>
          )}

          {status === "success" &&
          page.messages.messages.length === 0 &&
          !isLoading &&
          !isFetchingNextPage ? (
            <div>
              <div className="flex flex-col items-center justify-center h-full gap-0">
                <LottiePlayer
                  animationData={emptyIboxLottie}
                  loop={false}
                  autoplay
                  className="mx-auto w-full pl-10 h-[400px]"
                />
                <p className="text-center">No results found</p>
              </div>
            </div>
          ) : <MessageUI 
              page={page}
              labels={labels}
              searchTerm={searchTerm}
              selectedThreadRef={selectedThreadRef}
              setThread={setThread}
              thread={thread}
              key={page.messages.messages.length}
          />
          }
          {status === "success" && (
            <div ref={ref}>
              {isFetchingNextPage && (
                <div className="w-full flex items-center justify-center px-[40px] py-[10px]">
                  <Skeleton avatar active title />
                </div>
              )}
            </div>
          )}

          <div className="fixed hover:flex hidden z-50 backdrop-blur-sm items-center w-[459px] bottom-[0px] group-hover:block py-[10px]">
            <ScrollToActions
              scrollToThread={scrollToThread}
              scrollToTop={scrollToTop}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
