import http from "@/http";

const getContractImage = async ({ ref_id }: { ref_id: string }) => {
  return http.get<{
    images: {
      key: string;
      index: number;
    }[];
  }>(`/files/get-images/${ref_id}`);
};

export default { getContractImage };
