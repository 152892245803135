import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import { AxiosError, CancelTokenSource } from "axios";
import { createCancelToken } from "@/http";

import useLogout from "@/hooks/useLogout";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";

import { InviteUser } from "../hooks/types";

import { useUsers } from "../hooks/useUsers";
import React from "react";

interface Props {
  showDeleteDialog: boolean;
  onClosed: () => void;
  inviteDetails: InviteUser | null;
  refetch: () => void;
}

const DeleteInvitation = ({
  showDeleteDialog,
  onClosed,
  inviteDetails,
  refetch,
}: Props) => {
  const logout = useLogout();
  const { cancelInvite } = useUsers();
  const cancelTokenRef = React.useRef<CancelTokenSource | null>(null);

  const { isPending, mutateAsync } = useMutation({
    mutationKey: ["cancelInvite"],
    mutationFn: ({ email }: { email: string }) => {
      cancelTokenRef.current = createCancelToken();
      return cancelInvite({ email }, cancelTokenRef.current);
    },
    onSuccess: (data) => {
      message.success(data.data.message);
      onClosed();
      refetch();
    },
    onError: (error: AxiosError) => {
      if((error.response?.data as {error_code: number}).error_code === 501){
        message.error((error.response?.data as { message: string }).message);
        return logout()
      }
      return message.error(
        (error.response?.data as { message: string }).message
      );
    },
  });

  const handleCancel = () => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel("canceled");
    }
    onClosed();
  };

  return (
    <AlertDialog open={showDeleteDialog} onOpenChange={onClosed}>
      <AlertDialogContent className="min-w-[560px] w-auto">
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription className="text-red-500">
            <span className="dark:text-gray-200 text-gray-700">
              {inviteDetails?.email}
            </span>{" "}
            will be permanently deleted.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="flex">
          <AlertDialogCancel className="w-[100px]" onClick={handleCancel}>
            Cancel
          </AlertDialogCancel>
          <Button
            className="bg-red-500 hover:bg-red-600 w-[120px] disabled:bg-red-500"
            onClick={() => {
              mutateAsync({
                email: inviteDetails?.email as string,
              });
            }}
            loading={isPending}
          >
            Continue
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteInvitation;
