import { Bell, ChevronRight } from "lucide-react";
import { Link } from "react-router-dom";
// import useWebSocket from "@/hooks/useWebsocket";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import { appRoutes } from "@/utils/paths";

// import { NotificationType } from "@/types/notifications.types";

import Notification from "../Notification";
import React from "react";

interface Data {
  tooltipTitle?: string;
  icon: React.ReactNode;
  height?: number;
  width?: number;
}

const data: Data[] = [
  {
    tooltipTitle: "Notifications",
    icon: <Bell strokeWidth={1.2} size={25} className="text-gray-500" />,
  },
];

const Headnav = ({ heading }: { heading: string }) => {
  // const { response } = useWebSocket();

  const [openNotification, setOpenNotification] = React.useState(false);
  return (
    <div className="flex items-center justify-between h-[60px] px-[20px] font-popins border-b">
      <p className="font-bold text-xl text-[#343434] dark:text-white">
        {heading &&
          heading.split("/").map((item, index) => {
            return (
              <span key={index} className="capitalize">
                {index === 0 ? (
                  item === "Workflow" ? (
                    <Link
                      to={appRoutes.dashboard.WORKFLOWS.INDEX}
                      className="hover:underline"
                    >
                      {item}
                    </Link>
                  ) : (
                    item
                  )
                ) : (
                  <span className="font-medium text-gray-700 dark:text-gray-200">
                    <ChevronRight
                      size={20}
                      strokeWidth={1.2}
                      className="inline-block"
                    />
                    {item}
                  </span>
                )}
              </span>
            );
          })}
      </p>
      <div className="flex items-center gap-[30px]">
        {data &&
          data.map((item, index) => {
            return (
              <TooltipProvider key={index} delayDuration={500}>
                <Tooltip>
                  <TooltipTrigger>
                    <div
                      className="relative border border-gray-200 rounded-md p-[3px]"
                      onClick={() => setOpenNotification(true)}
                    >
                      {item.icon}
                      {/* {response &&
                        response.type === NotificationType.NOTIFICATION &&
                        (response.message as unknown as { count: number })
                          .count > 0 && (
                          <div className="absolute top-0 right-0 w-[10px] h-[10px] bg-red-500 rounded-full">
                            {
                              (response.message as unknown as { count: number })
                                .count
                            }
                          </div>
                        )} */}
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{item.tooltipTitle}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            );
          })}
      </div>
      <Notification
        open={openNotification}
        onOpenChange={() => {
          setOpenNotification(false);
        }}
      />
    </div>
  );
};

export default Headnav;
