export enum RoleEnum {
    Admin = "admin",
    Manager = "manager",
    Member = "member",
    Aprrover = "approver"
}

export const allAccess = [RoleEnum.Admin, RoleEnum.Manager, RoleEnum.Member, RoleEnum.Aprrover];
export const adminAccess = [RoleEnum.Admin, RoleEnum.Manager];
export const managerAccess = [RoleEnum.Member, RoleEnum.Manager]
export const memberAccess = [RoleEnum.Member]
