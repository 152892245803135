/* eslint-disable @typescript-eslint/no-explicit-any */
import Text from "@/components/Text";
import { Skeleton } from "antd";
import { Input } from "@/components/ui/input";
import { Badge } from "@/components/ui/badge";
import { Drawer, DrawerContent } from "@/components/ui/drawer";

import { Table } from "lucide-react";
import React from "react";

import LineItemsTable from "./LineItemsTable";

import { useDrawer } from "@/providers/DrawerContext";
import { useGeometry } from "@/providers/geometryProvider";

interface InformationProps {
  schemaValues: {
    sections: {
      [key: string]: any;
    };
  };
  lineItems: Record<string, any>;
  loading: boolean;
}


const Information = ({
  schemaValues,
  loading,
  lineItems,
}: InformationProps) => {
  return (
    <div className="overflow-scroll mb-[10px]">
      {loading ? (
        <div className="px-[8px] py-[12px] flex flex-col gap-[20px]">
          <Skeleton active
          />
          {Array.from({ length: 1 }).map((_, index) => (
            <div className="flex gap-3" key={index}>
              <Skeleton
                active
                loading
                className={`w-[100px] h-[15px] rounded-sm dark:bg-gray-600 delay-100`}
              />
              <Skeleton active />
            </div>
          ))}
          <Skeleton
            active
            className="w-full h-[35px] rounded-sm dark:bg-gray-600 delay-150"
          />
          {Array.from({ length: 2 }).map((_, index) => (
            <div className="flex gap-3" key={index}>
              <Skeleton
                active
                className="w-[100px] h-[15px] rounded-sm dark:bg-gray-600 delay-200"
              />
              <Skeleton
                active
                className="w-full h-[15px] rounded-sm dark:bg-gray-600 delay-200"
              />
            </div>
          ))}
          <Skeleton
            active
            className="w-full h-[35px] rounded-sm dark:bg-gray-600 delay-150"
          />
        </div>
      ) : (
        <DisplayInformation
          schemaValues={schemaValues}
          lineItems={lineItems}
          loading
        />
      )}
    </div>
  );
};

export default Information;

const DisplayInformation = ({ schemaValues, lineItems }: InformationProps) => {
  const drawerRef = React.useRef<HTMLDivElement>(null);

  const { setHeight } = useDrawer();
  const { setGeometry } = useGeometry();

  const [open, setOpen] = React.useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const onOpen = () => {
    setOpen(true);
  };

  const updateHeight = React.useCallback(() => {
    if (drawerRef.current) {
      setHeight(open ? drawerRef.current.clientHeight : 0);
    }
  }, [open, setHeight]);

  React.useEffect(() => {
    if (!open) {
      setHeight(0);
    }
  }, [open, setHeight]);

  React.useEffect(() => {
    updateHeight();
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [updateHeight]);

  const setDrawerRef = (element: HTMLDivElement) => {
    if (element && open) {
      setHeight(element.clientHeight);
    }
  };

  const renderSection = (title: string, data: Record<string, any>) => {
    return (
      <div key={title} className="mb-[10px]">
        <Text className="dark:bg-gray-800 py-[10px] px-[10px] bg-gray-100 capitalize">
          {title}
        </Text>
        <div className="mt-[10px] flex flex-col gap-[5px] px-[10px]">
          {Object.keys(data || {}).map(
            (key) =>
              data[key]?.is_visible && (
                <div
                  key={key}
                  className="flex w-full items-center justify-between"
                >
                  <Text className="dark:!text-gray-300 !text-gray-500">
                    {data[key]?.label}
                  </Text>
                  <Input
                    readOnly
                    onClick={()=>{
                     if(data[key]?.value === "N/A") return
                     setGeometry({
                      ...data[key].geometry,
                      anotation_label: data[key].label
                     })
                    }}
                    className={
                      data[key].value === "N/A"
                        ? "w-[230px] text-gray-500 overflow-hidden text-ellipsis whitespace-nowrap bg-transparent rounded-none border-b-2 dark:border-gray-500 border-gray-300 border-l-0 border-r-0 border-t-0 ring-0 focus-visible:ring-0 dark:focus-within:border-blue-600 focus-within:border-primary/100"
                        : "w-[230px] overflow-hidden text-ellipsis whitespace-nowrap bg-transparent rounded-none border-b-2 dark:border-green-500 border-green-400 border-l-0 border-r-0 border-t-0 ring-0 focus-visible:ring-0 dark:focus-within:border-blue-600 focus-within:border-primary/100"
                    }
                    defaultValue={data[key]?.value || ""}
                  />
                </div>
              )
          )}
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="!z-[99999]">
        {Object.keys(schemaValues?.sections ?? {}).map((sec) => {
          return renderSection(
            sec?.split("_")?.join(" "),
            schemaValues?.sections[sec] as Record<string, any>
          );
        })}
        <div className="mt-5 dark:bg-gray-800 bg-gray-100 py-[8px] px-[10px] flex items-center justify-between">
          <Text className="capitalize">Line Items</Text>
          <div className="flex gap-1 items-center">
            <Badge className="text-white font-xeroda h-[20px] w-[17px] text-[16px] flex items-center justify-center text-center">
              {lineItems?.length}
            </Badge>
            <Table
              size={22}
              strokeWidth={1.4}
              className="text-gray-600 dark:text-gray-400 cursor-pointer"
              onClick={onOpen}
            />
          </div>
        </div>
      </div>
      <div className="group">
        <div className="ml-[420px] fixed bottom-0 w-[calc(100vw-390px)] h-[50px] translate-y-7 bg-gray-100 dark:bg-gray-900 flex items-center justify-center group-hover:translate-y-0 transition-all">
          <div>
            <div
              className="mx-auto h-2 w-[100px] rounded-full dark:bg-gray-600 bg-gray-300 cursor-pointer mt-1"
              onClick={onOpen}
            />
            {lineItems?.length > 0 ? (
              <p
                onClick={onOpen}
                className="mt-2 !text-green-500 underline text-[14px] cursor-pointer"
              >
                View Line Items
              </p>
            ) : (
              <p className="mt-2 !text-red-500 text-[14px]">
                No line items detected
              </p>
            )}
          </div>
        </div>
      </div>
      <Drawer
        open={open}
        onOpenChange={() => {
          document.body.style.removeProperty("pointer-events");
        }}
        modal={false}
        onClose={onClose}
        key={"2"}
        shouldScaleBackground={false}
        direction="bottom"
        fixed
        dismissible={false}
      >
        <DrawerContent ref={setDrawerRef} className="ml-[420px] rounded-none">
          <LineItemsTable lineItems={lineItems} onClose={onClose} />
        </DrawerContent>
      </Drawer>
    </div>
  );
};
