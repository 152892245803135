import React from "react";
import http from "@/http";
import { message } from "antd";

import { AxiosError } from "axios";

import { UpdateDataPayload, UpdateResponse } from "./types";

import { useUserDispatch } from "@/providers/userProvider";

export default function useUpdateProfile() {
  const dispatch = useUserDispatch();
  const [loading, setLoading] = React.useState(false);
  const updateProfile = async (data: UpdateDataPayload) => {
    setLoading(true);
    try {
      const response = await http.put<UpdateResponse>(
        "/setting/update-profile-data",
        data
      );
      setLoading(false);
      dispatch?.setUser(response.data?.user);
      message.success(response.data?.message);
    } catch (error) {
      setLoading(false);
      if (error instanceof AxiosError) {
        message.error(error.response?.data.message);
      }
      throw error;
    }
  };

  return {
    updateProfile,
    loading,
  };
}
