import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";

import { Document } from "@/types/document";
import React from "react";

interface Props {
  showDeleteDialog: boolean;
  documentInfo: Document[];
  deleteLoading: boolean;
  setShowDeleteMultipleDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleDeleteFile: (id: string[]) => void;
}

const DeleteMultipleFiles = ({
  documentInfo,
  handleDeleteFile,
  setShowDeleteMultipleDialog,
  showDeleteDialog,
  deleteLoading,
}: Props) => {
  return (
    <AlertDialog open={showDeleteDialog}>
      <AlertDialogContent className="min-w-[560px] w-auto">
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            <ul className="text-pretty list-decimal pl-4 pb-3 rounded-sm">
             {
                documentInfo.map((doc) => (
                  <li key={doc.documentId}>{doc.file_name}</li>
                ))
             }
            </ul>
            <p className="text-red-500">will be available for next 30 days in archive center.</p>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="flex">
          <AlertDialogCancel
            className="w-[100px]"
            onClick={() => setShowDeleteMultipleDialog(false)}
          >
            Cancel
          </AlertDialogCancel>
          <Button
            className="bg-red-500 hover:bg-red-600 w-[120px] disabled:bg-red-500"
            onClick={() =>
              handleDeleteFile(
                documentInfo?.map((doc) => doc.documentId) as string[]
              )
            }
            loading={deleteLoading}
          >
            Continue
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteMultipleFiles;
