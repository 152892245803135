import { Save } from "lucide-react";
import Headnav from "@/components/Navbars/Headnav";
import { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Switch } from "@/components/ui/switch";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Slider } from "@/components/ui/slider";
import { Button } from "@/components/ui/button";
import ConnectCard from "./components/ConnectCard";

import { useUser } from "@/providers/userProvider";

const InboxConfiguration = () => {
  const { user } = useUser();
  const [inboxEnabled, setInboxEnabled] = useState(
    user?.inbox?.inbox_account_email ? true : false
  );
  return (
    <div>
      <Headnav heading={"Inbox configuration"} />
      <div className="bg-background">
        <main className="px-[20px] py-6">
          <ConnectCard
            setInboxEnabled={setInboxEnabled}
            inboxEnabled={inboxEnabled}
          />

          <Tabs defaultValue="general" className="space-y-4">
            <TabsList>
              <TabsTrigger value="general">General</TabsTrigger>
              <TabsTrigger value="filters">Filters</TabsTrigger>
              <TabsTrigger value="notifications">Notifications</TabsTrigger>
              <TabsTrigger value="advanced">Advanced</TabsTrigger>
            </TabsList>
            <TabsContent value="general" className="space-y-4">
              <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
                <div className="space-y-2">
                  <Label htmlFor="inbox-type">Inbox Type</Label>
                  <Select disabled={!inboxEnabled}>
                    <SelectTrigger id="inbox-type">
                      <SelectValue placeholder="Select type" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="default">Default</SelectItem>
                      <SelectItem value="priority">Priority Inbox</SelectItem>
                      <SelectItem value="important">Important First</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="language">Language</Label>
                  <Select disabled={!inboxEnabled}>
                    <SelectTrigger id="language">
                      <SelectValue placeholder="Select language" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="en">English</SelectItem>
                      <SelectItem value="es">Spanish</SelectItem>
                      <SelectItem value="fr">French</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
                <div className="space-y-2">
                  <Label htmlFor="timezone">Timezone</Label>
                  <Select disabled={!inboxEnabled}>
                    <SelectTrigger id="timezone">
                      <SelectValue placeholder="Select timezone" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="utc">UTC</SelectItem>
                      <SelectItem value="est">Eastern Time</SelectItem>
                      <SelectItem value="pst">Pacific Time</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <Switch id="auto-advance" disabled={!inboxEnabled} />
                  <Label htmlFor="auto-advance">Auto-advance</Label>
                </div>
                <p className="text-sm text-muted-foreground">
                  Automatically advance to the next conversation after archiving
                  or deleting.
                </p>
              </div>
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <Switch id="send-and-archive" disabled={!inboxEnabled} />
                  <Label htmlFor="send-and-archive">Send and Archive</Label>
                </div>
                <p className="text-sm text-muted-foreground">
                  Show "Send & Archive" button when composing a reply.
                </p>
              </div>
            </TabsContent>
            <TabsContent value="filters" className="space-y-4">
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <Switch id="spam-filter" disabled={!inboxEnabled} />
                  <Label htmlFor="spam-filter">Spam Filter</Label>
                </div>
                <p className="text-sm text-muted-foreground">
                  Automatically detect and filter spam messages.
                </p>
              </div>
              <div className="space-y-2">
                <Label htmlFor="email-threading">Email Threading</Label>
                <Select disabled={!inboxEnabled}>
                  <SelectTrigger id="email-threading">
                    <SelectValue placeholder="Select option" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="on">On</SelectItem>
                    <SelectItem value="off">Off</SelectItem>
                  </SelectContent>
                </Select>
                <p className="text-sm text-muted-foreground">
                  Group related messages together in conversations.
                </p>
              </div>
              <div className="space-y-2">
                <Label htmlFor="auto-categorize">Auto-categorize</Label>
                <Select disabled={!inboxEnabled}>
                  <SelectTrigger id="auto-categorize">
                    <SelectValue placeholder="Select option" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="on">On</SelectItem>
                    <SelectItem value="off">Off</SelectItem>
                  </SelectContent>
                </Select>
                <p className="text-sm text-muted-foreground">
                  Automatically sort emails into categories like Social,
                  Promotions, etc.
                </p>
              </div>
            </TabsContent>
            <TabsContent value="notifications" className="space-y-4">
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <Switch id="desktop-notifications" disabled={!inboxEnabled} />
                  <Label htmlFor="desktop-notifications">
                    Desktop Notifications
                  </Label>
                </div>
                <p className="text-sm text-muted-foreground">
                  Receive notifications for new emails when the app is running.
                </p>
              </div>
              <div className="space-y-2">
                <Label htmlFor="notification-sound">Notification Sound</Label>
                <Select disabled={!inboxEnabled}>
                  <SelectTrigger id="notification-sound">
                    <SelectValue placeholder="Select sound" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="default">Default</SelectItem>
                    <SelectItem value="ping">Ping</SelectItem>
                    <SelectItem value="chime">Chime</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2">
                <Label htmlFor="notification-frequency">
                  Notification Frequency
                </Label>
                <Select disabled={!inboxEnabled}>
                  <SelectTrigger id="notification-frequency">
                    <SelectValue placeholder="Select frequency" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="realtime">Real-time</SelectItem>
                    <SelectItem value="hourly">Hourly</SelectItem>
                    <SelectItem value="daily">Daily</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </TabsContent>
            <TabsContent value="advanced" className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="sync-frequency">Sync Frequency (minutes)</Label>
                <Slider
                  id="sync-frequency"
                  disabled={!inboxEnabled}
                  defaultValue={[15]}
                  max={60}
                  step={5}
                />
              </div>
              <div className="space-y-2">
                <Label htmlFor="attachment-size">
                  Max Attachment Size (MB)
                </Label>
                <Input
                  id="attachment-size"
                  type="number"
                  placeholder="Enter max size"
                  disabled={!inboxEnabled}
                />
              </div>
              <div className="space-y-2">
                <div className="flex items-center space-x-2">
                  <Switch id="vacation-responder" disabled={!inboxEnabled} />
                  <Label htmlFor="vacation-responder">Vacation Responder</Label>
                </div>
                <p className="text-sm text-muted-foreground">
                  Automatically reply to incoming messages when you're away.
                </p>
              </div>
            </TabsContent>
          </Tabs>

          <div className="mt-6 flex justify-end">
            <Button className="flex items-center gap-2">
              <Save className="h-4 w-4" />
              Save Changes
            </Button>
          </div>
        </main>
      </div>
    </div>
  );
};

export default InboxConfiguration;
