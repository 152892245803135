import React from "react";
import { useAuth } from "@/providers/authContext";

import { ShieldAlert } from "lucide-react";

import useLogout from "@/hooks/useLogout";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "./ui/alert-dialog";

const LoginPrompt: React.FC = () => {
  const { showLoginPrompt, setShowLoginPrompt } = useAuth();
  const logout = useLogout();

  if (!showLoginPrompt) {
    return null;
  }

  const onClose = () => {
    logout();
    setShowLoginPrompt(false);
  };

  return (
    <AlertDialog open>
      <AlertDialogContent>
        <AlertDialogHeader>
          <div className="flex gap-[10px]">
            <ShieldAlert size={48} strokeWidth={1.2} className="text-red-500 bg-red-200 rounded-full p-2.5 w-[50px] h-[50px]" />
            <div className="flex flex-col items-start">
              <AlertDialogTitle>Your session has expired</AlertDialogTitle>
              <AlertDialogDescription className="text-left">
                Please login again to continue using the Wingg application.
              </AlertDialogDescription>
            </div>
          </div>
        </AlertDialogHeader>
        <AlertDialogFooter className="flex items-end">
            <AlertDialogAction onClick={onClose}>Login</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default LoginPrompt;
