import React from "react";

interface HeadProps {
  title: string;
  description?: string;
  // i can add more meta tags here
  moreAttibutes?: <T>(props: T) => React.ReactElement<T>;
}

const useHead = ({ description, title, moreAttibutes }: HeadProps) => {
  React.useEffect(() => {
    document.title = title;
    document.querySelector('meta[name="description"]')?.setAttribute("content", description ?? "");
  }, [description, title]);
  return moreAttibutes;
};

export default useHead