import { Navigate, RouteObject } from "react-router-dom";
import { appRoutes } from "@/utils/paths";

import DashboardLayout from "@/layouts/Dashboard";
import AuthLayout from "@/layouts/Auth";
import WorkflowLayout from "@/layouts/Workflow-ground";
import InboxLayout from "@/layouts/Dashboard/inbox";

import Invite from "@/views/Invite";
import Settings from "@/views/Settings";
import Analytics from "@/views/Analytics";
import UserPage from "@/views/Users";
import Login from "@/views/Login";
import Workflows from "@/views/workflows";
import Billing from '@/views/payments'

import Profile from "@/views/Settings/pages/Profile";
import Password from "@/views/Settings/pages/Password";
import Team from "@/views/Settings/pages/Team";
import Plan from "@/views/Settings/pages/Plan";
import Email from "@/views/Settings/pages/Email";
import Security from "@/views/Settings/pages/Security";
import Workflow from "@/views/workflows/pages/workflow";
import TwoFA from "@/views/2FA";
// import Configurations from "@/views/Configuration";
// import ConfigWorkflow from "@/views/Configuration/AllWorkflows";
import InboxConfiguration from "@/views/Configuration/inbox";

import AllMails from "@/views/Inbox/pages/AllMails";

import ProtectedRoute from "./Protected";

const routesObject: RouteObject[] = [
  {
    path: appRoutes.auth.INDEX,
    element: <AuthLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={appRoutes.auth.MAIN_LOGIN} />,
      },
      {
        path: appRoutes.auth.MAIN_LOGIN,
        element: <Login />,
      },
      {
        path: appRoutes.auth.MAIN_TWO_FA,
        element: <TwoFA />,
      },
      {
        path: appRoutes.auth.INVITE,
        element: <Invite />,
      },
    ],
  },
  {
    path: appRoutes.dashboard.INDEX,
    element: <DashboardLayout />,
    children: [
      {
        index: true,
        element: <Navigate to={appRoutes.dashboard.INBOX.ROOT} />,
      },
      {
        path: appRoutes.dashboard.BILLING,
        element: <Billing />,
      },
      {
        path: appRoutes.dashboard.INBOX.ROOT,
        element: <InboxLayout />,
        children: [
          {
            index: true,
            path: appRoutes.dashboard.INBOX.CATEGORY_PATH,
            element: <AllMails />,
          },
        ],
      },
      {
        path: appRoutes.dashboard.WORKFLOWS.INDEX,
        element: <Workflows />,
      },
      {
        path: appRoutes.dashboard.ANALYTICS,
        element: <Analytics />,
      },
      {
        path: appRoutes.dashboard.WORKFLOWS.WORKFLOW_ID,
        element: <Workflow />,
      },
      {
        path: appRoutes.dashboard.USERS,
        element: (
          <ProtectedRoute allowedRoles={["admin", "manager"]}>
            <UserPage />
          </ProtectedRoute>
        ),
      },
      // {
      //   path: appRoutes.dashboard.CONFIGURATIONS,
      //   element: <ConfigWorkflow />,
      // },
      // {
      //   path: appRoutes.dashboard.CONFIG_WORKFLOW,
      //   element: <Configurations />,
      // },
      {
        path: appRoutes.dashboard.CONFIGURATIONS,
        element: <InboxConfiguration />,
      },
      {
        path: appRoutes.dashboard.SETTINGS.INDEX,
        element: <Settings />,
        children: [
          {
            index: true,
            path: appRoutes.dashboard.SETTINGS.PROFILE,
            element: <Profile />,
          },
          {
            path: appRoutes.dashboard.SETTINGS.PASSWORD,
            element: <Password />,
          },
          {
            path: appRoutes.dashboard.SETTINGS.TEAM,
            element: <Team />,
          },
          {
            path: appRoutes.dashboard.SETTINGS.PLAN,
            element: <Plan />,
          },
          {
            path: appRoutes.dashboard.SETTINGS.EMAIL,
            element: <Email />,
          },
          {
            path: appRoutes.dashboard.SETTINGS.SECURITY,
            element: <Security />,
          },
        ],
      },
    ],
  },
  {
    path: appRoutes.dashboard.WORKFLOWS.WORKFLOW_GROUND,
    element: <WorkflowLayout />,
  },
  {
    path: "*",
    element: <Navigate to={appRoutes.dashboard.WORKFLOWS.INDEX} />,
  },
];

export default routesObject;
