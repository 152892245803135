/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect } from "react";
import { useAuth } from "@/providers/authContext";
import http from "@/http";
import { AxiosError } from "axios";
import {message} from 'antd'

import storage from "@/utils/storage";
import { TOKENS } from "@/constant";

const AuthStateHandler: React.FC = () => {
  const { setShowLoginPrompt } = useAuth();

  useEffect(() => {
    const interceptor = http.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (
          error.response &&
          error.response.status === 401 &&
          originalRequest &&
          !(originalRequest as any)._retry
        ) {
          (originalRequest as any)._retry = true;

          const refreshToken = storage.get(TOKENS)?.refresh_token;
          if (refreshToken) {
            try {
              const response = await http.post("/auth/token", {
                refresh_token: refreshToken,
              });
              const newAccessToken = response.data.access_token;

              const tokenObject = {
                access_token: newAccessToken,
                refresh_token: refreshToken,
              };
              storage.set(TOKENS, tokenObject);
              originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            } catch (error: unknown) {
              storage.remove(TOKENS);
              message.error(((error as AxiosError).response?.data as { message: string }).message);
              setShowLoginPrompt(true);
            }
            return http(originalRequest);
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      http.interceptors.response.eject(interceptor);
    };
  }, [setShowLoginPrompt]);

  return null;
};

export default AuthStateHandler;
