import * as React from "react";
import { useMutation } from "@tanstack/react-query";
import { ChevronDown } from "lucide-react";
import { message } from "antd";
import { AxiosError } from "axios";

import { RoleEnum } from "@/types/role";

import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import { useUsers } from "../hooks/useUsers";
import { useUser } from "@/providers/userProvider";

interface Status {
  value: string;
}

const statuses = [
  {
    value: "admin",
  },
  {
    value: "manager",
  },
  {
    value: "member",
  },
  {
    value: "approver",
  },
];

interface RoleProps {
  role?: RoleEnum;
  value: string;
  table_name: "invite" | "user";
}

export function Role({ value, role, table_name }: RoleProps) {
  const { user } = useUser();
  const [userRole, setUserRole] = React.useState<Status[]>(statuses);
  React.useEffect(() => {
    if (user?.role === "manager") {
      setUserRole([statuses[2]]);
    } else {
      setUserRole([statuses[1], statuses[2], statuses[3]]);
    }
  }, [user]);
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = React.useState<Status | null>(
    null
  );
  const { updateInviteRole, updateUserRole } = useUsers();

  React.useEffect(() => {
    if (role) {
      setSelectedStatus(
        statuses.find((status) => status.value === role) || null
      );
    }
  }, [role]);

  const { isPending, mutateAsync } = useMutation({
    mutationKey: ["updateRole"],
    mutationFn: updateInviteRole,
    onSuccess: (data) => {
      return message.success(data.data.message);
    },
    onError: (error: AxiosError) => {
      return message.error(
        (error.response?.data as { message: string }).message
      );
    },
  });

  const { isPending: isPendingUser, mutateAsync: mutateUserAsync } =
    useMutation({
      mutationKey: ["updateUserRole"],
      mutationFn: updateUserRole,
      onSuccess: (data) => {
        return message.success(data.data.message);
      },
      onError: (error: AxiosError) => {
        return message.error(
          (error.response?.data as { message: string }).message
        );
      },
    });

  const handleUpdateRole = async (role: string) => {
    if (table_name === "invite" && selectedStatus) {
      mutateAsync({ email: value, role: role });
    } else {
      mutateUserAsync({ user_id: value, role: role });
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen} key={Math.random()}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size={"sm"}
          className="w-[180px] justify-between text-gray-500 dark:text-gray-300 capitalize"
          loading={isPending || isPendingUser}
        >
          {selectedStatus ? <>{selectedStatus.value}</> : <>+ Set status</>}
          <ChevronDown strokeWidth={1.2} size={22} />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <StatusList
          setOpen={setOpen}
          allowedRoles={userRole}
          setSelectedStatus={setSelectedStatus}
          handleUpdateRole={handleUpdateRole}
        />
      </PopoverContent>
    </Popover>
  );
}

function StatusList({
  setOpen,
  setSelectedStatus,
  handleUpdateRole,
  allowedRoles,
}: {
  setOpen: (open: boolean) => void;
  setSelectedStatus: (status: Status | null) => void;
  handleUpdateRole: (role: string) => void;
  allowedRoles: Status[];
}) {
  return (
    <Command>
      <CommandInput placeholder="Filter role..." />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        <CommandGroup>
          {allowedRoles.map((status) => (
            <CommandItem
              key={status.value}
              value={status.value}
              onSelect={(value) => {
                setSelectedStatus(
                  statuses.find((priority) => priority.value === value) || null
                );
                setOpen(false);
                handleUpdateRole(status.value);
              }}
              className="capitalize"
            >
              {status.value}
            </CommandItem>
          ))}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}
