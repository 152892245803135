import React from "react";
import { Skeleton, Tooltip } from "antd";
import {
  Reply,
  // MoreHorizontal
} from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import Highlighter from "react-highlight-words";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

import { genrateInitials } from "@/helper/genrateInitials";

import { useUser } from "@/providers/userProvider";
import { useReplay, ReplayMessage, Methods } from "@/providers/replayContext";
import { useMyMail } from "@/providers/myMailContext";

import EmailViewer from "./EmailViewer";
import Attachments from "./Attachments";

import { gmailApi } from "../../api/gmail";
import { Attachment } from "@/types/email.type";

interface MessageProps {
  active?: boolean;
  content: string;
  to?: string;
  timeStamp: Date;
  messageId: string;
  from: string;
  searchText: string;
  metadata: {
    messageId: string;
    label: string;
    tone_confidence: number;
    tone_emoji: string;
    label_confidence: number;
    tone: string;
  };
}

const Message: React.FC<MessageProps> = ({
  metadata,
  active = false,
  content,
  to,
  timeStamp,
  messageId,
  from,
  searchText,
}) => {
  const { user } = useUser();
  const { getMailContent } = gmailApi();
  const { setReplayMessage } = useReplay();
  const { page } = useMyMail();

  const [cc, setCc] = React.useState<string | null>(null);

  const yourEmail =
    to?.split("<")[1]?.split(">")[0]?.toLowerCase() || to?.toLowerCase();

  React.useEffect(() => {
    if (user?.inbox?.inbox_account_email === yourEmail) {
      setCc(`You`);
    } else {
      setCc(to as string);
    }
  }, [to, user, yourEmail]);

  const { data, isLoading } = useQuery({
    queryKey: ["mailContent", messageId],
    queryFn: () => getMailContent(messageId),
    enabled: !!messageId,
  });

  const handleReplay = () => {
    const message = page.messages.messages.find(
      (msg) => msg.id === messageId || msg.threadId === messageId
    );
    setReplayMessage({
      ...message,
      messageId: messageId,
      method: Methods.REPLY,
      show: true,
    } as ReplayMessage);
  };

  return (
    <div className="w-full">
      <div
        className={`p-5 border-b ${
          active ? "!bg-white rounded !shadow-xl" : "bg-[#f9f9f9] !overflow-scroll"
        } border-gray-200`}
      >
        <div className="flex gap-[5px] items-start justify-between">
          <div className="flex gap-[10px]">
            <Avatar className="h-[20px] w-[20px] text-[20px]">
              <AvatarImage src={genrateInitials("A") as string} />
              <AvatarFallback>
                <span>AS</span>
              </AvatarFallback>
            </Avatar>
            <p className="text-sm font-semibold max-w-[290px]">
              <Highlighter
                searchWords={[searchText]}
                autoEscape={true}
                highlightClassName="bg-orange-300 py-[1px] rounded-md px-[3px]"
                textToHighlight={from?.replace(">", "")?.replace("<", "")}
              />
            </p>
            <p className="text-sm text-gray-500 ml-2">
              To:{" "}
              <span className="text-gray-700 font-semibold">
                <Highlighter
                  highlightClassName="bg-orange-300 py-[1px] rounded-md px-[3px]"
                  searchWords={[searchText]}
                  autoEscape={true}
                  textToHighlight={cc === "You, " ? "You" : cc || ""}
                />
              </span>
            </p>
          </div>
          <div className="flex items-center gap-[10px] ">
            {metadata?.tone && (
              <Tooltip
                color="white"
                arrow
                className="!text-gray-500"
                title={
                  <div className="text-gray-700">
                    <p className="font-medium">
                      Wingg think message sound{" "}
                      <span className="capitalize">
                        {metadata?.tone || "Neutral"}.
                      </span>
                    </p>
                    <p className="text-sm">
                      Confidence:{" "}
                      {Number(metadata?.tone_confidence?.toFixed(1)) * 100 ||
                        70}
                      %
                    </p>
                  </div>
                }
              >
                <div className="border rounded-full px-[2px] py-[2px] bg-white">
                  <span className="rounded-full px-[3px]">
                    {metadata?.tone_emoji || "😐"}
                  </span>
                </div>
              </Tooltip>
            )}
            <p className="text-sm text-gray-500 font-medium">
              {new Date(timeStamp).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                calendar: "gregory",
              })}
            </p>
            {active && (
              <div className="flex items-center gap-[10px]">
                <Reply
                  size={16}
                  className="text-gray-600 hover:scale-110 cursor-pointer hover:text-gray-800"
                  onClick={handleReplay}
                />
                {/* <MoreHorizontal size={16} className="text-gray-600" /> */}
              </div>
            )}
          </div>
        </div>
        <p
          className={
            active ? "text-[13px] my-[10px] truncate" : "text-[13px] my-[10px] w-full"
          }
        >
          {active ? (
            <EmailViewer
              content={data?.data?.mail?.content as string}
              highlightText={searchText}
            />
          ) : (
            <Highlighter
            highlightClassName="bg-orange-300 py-[1px] rounded-md px-[3px]"
            searchWords={[searchText || ""]}
            autoEscape={true}
            textToHighlight={decodeHtmlEntities(content) || ""}
          />
          )}
        </p>
      </div>
      {data?.data?.mail?.attachments && (
        <div className="mb-3">
          {isLoading ? (
            <AttachementsSkeleton />
          ) : (
            <Attachments
              attachments={data?.data?.mail?.attachments as Attachment[]}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Message;

function decodeHtmlEntities(str:string) {
  const parser = new DOMParser();
  const dom = parser.parseFromString(`<!doctype html><body>${str}`, 'text/html');
    return dom.body.textContent;
}

const AttachementsSkeleton = () => {
  return (
    <div className="mt-4 w-full">
      <div className="flex items-center justify-between">
        <Skeleton.Input active size="small" className="!w-[150px] !h-[10px]" />
        <Skeleton.Input active size="small" className="!w-[120px] !h-[10px]" />
      </div>
      <div className="flex gap-[20px] mt-4">
        <Skeleton.Input active size="small" className="!w-[120px]" />
        <Skeleton.Input active size="small" className="!w-[120px]" />
        <Skeleton.Input active size="small" className="!w-[120px]" />
      </div>
    </div>
  );
};
