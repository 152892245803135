import React from "react";

import useUpdateEffect from "@/hooks/useUpdateEffect";
import { THREAD_ID } from "@/constant";
import storage from "@/utils/storage";

interface Thread {
  thredId: string | null;
}

interface ThreadProps {
  thread: Thread;
  setThread: React.Dispatch<React.SetStateAction<Thread>>;
}

interface ThreadProviderProps {
  children: React.ReactNode;
}

const ThreadContext = React.createContext<ThreadProps | undefined>(undefined);
ThreadContext.displayName = "ThreadContext";


export const ThreadProvider: React.FC<ThreadProviderProps> = ({ children }) => {
  const [thread, setThread] = React.useState<Thread>({ thredId: null });

  useUpdateEffect(() => {
    storage.set(THREAD_ID, thread.thredId);
  }, [thread]);

  return (
    <ThreadContext.Provider value={{ thread, setThread }}>
      {children}
    </ThreadContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useThread = () => {
  const context = React.useContext(ThreadContext);
  if (!context) {
    throw new Error("useThread must be used within a ThreadProvider");
  }
  return context;
};
