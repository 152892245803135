import Text from "@/components/Text";
import { Button } from "@/components/ui/button";
import ImageViewer from "@/components/viewer";

import { ArrowLeft, ArrowRight } from "lucide-react";

import { Options } from "@/utils/options";

import { useScale } from "@/providers/ScaleProvider";

const Viewer = ({ document_name }: { document_name: string }) => {
  const { setScale } = useScale();

  const handlePrevDocument = () => {};
  const handleNextDocument = () => {};
  const handleZoomIn = () => {
    setScale((prev) => ({ value: Math.floor(prev.value + 1) }));
  };
  const handleZoomOut = () => {
    setScale((prev) => ({ value: Math.floor(prev.value - 1) }));
  };
  const handleDownload = () => {};

  const options = Options({
    zoomIn: handleZoomIn,
    zoomOut: handleZoomOut,
    download: handleDownload,
  });

  return (
    <div className="flex flex-col items-center">
      <div className="flex items-center w-full gap-[10px] z-10">
        <div className="flex items-center justify-between gap-[10px] fixed bg-white dark:bg-background w-[calc(100vw-420px)] h-[50px] mt-[50px] px-[10px] border-b">
          <div className="flex items-center w-full">
            <ArrowLeft
              className="p-1 bg-gray-50 dark:bg-primary-foreground h-[30px] w-[30px] rounded-sm text-gray-600 active:scale-95 dark:text-foreground"
              strokeWidth={1.2}
              onClick={handlePrevDocument}
            />
            <Text
              variant="primary"
              className="dark:!text-gray-300 !text-gray-600 max-w-[500px] overflow-hidden text-ellipsis whitespace-nowrap"
            >
              {document_name}{" "}
              <span className="text-gray-500 text-[10px]">1/23</span>
            </Text>
            <ArrowRight
              className="p-1 bg-gray-50 h-[30px] w-[30px] rounded-sm text-gray-600 active:scale-95 dark:bg-primary-foreground dark:text-foreground"
              strokeWidth={1.2}
              onClick={handleNextDocument}
            />
          </div>
          <div className="flex gap-3 items-center">
            {options?.map((option, index) => (
              <Button
                key={index}
                variant={
                  option.variant as
                    | "default"
                    | "outline"
                    | "link"
                    | "secondary"
                    | "destructive"
                    | "ghost"
                }
                className={option.className}
                size={"sm"}
                onClick={(e) => option.onclick(e)}
              >
                {option.icon}
                {option.text && option.text}
              </Button>
            ))}
          </div>
        </div>
      </div>
      <ImageViewer />
    </div>
  );
};

export default Viewer;
