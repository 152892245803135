import LottiePlayer from "lottie-react";
import { Button } from "antd";
import { useMutation } from "@tanstack/react-query";
import GmailPlusLottie from "@/assets/lottis/gmail-pulse.json";

import { useUser } from "@/providers/userProvider";

import { gmailApi } from "../api/gmail";

const ConnectState = () => {
  const { user } = useUser();
  const { connectGmail } = gmailApi();

  const { mutate, isPending } = useMutation({
    mutationKey: ["connectGmail"],
    mutationFn: connectGmail,
    onSuccess: (data) => {
      return (window.location.href = data.data.url);
    },
  });

  return (
    <div className="flex items-center justify-center gap-2 flex-col h-full">
      <LottiePlayer
        animationData={GmailPlusLottie}
        className="mx-auto w-full h-[300px]"
      />
      <p className="text-center text-gray-600">
        {user?.is_main_account && user?.inbox?.inbox_account_email === null ? (
          <>
            You're one step away from effortlessly organizing <br />
            all your communications. <br />
            <Button
              type="primary"
              className="mt-4"
              onClick={() => {
                mutate();
              }}
              loading={isPending}
            >
              Connect Gmail
            </Button>
          </>
        ) : user?.inbox?.inbox_account_email === null ? (
          <>
            Your organization does not have any active mailboxes! <br /> Connect
            your support mailbox to wingg to get started.
          </>
        ) : (
          <>
            You're one step away from effortlessly organizing <br />
            all your communications. <br />
            <Button
              type="primary"
              className="mt-4"
              onClick={() => {
                mutate();
              }}
              loading={isPending}
            >
              Connect Gmail
            </Button>
          </>
        )}
      </p>
    </div>
  );
};

export default ConnectState;
