import React from "react";

import { timeAgo } from "@/helper/timAgo";

interface EventProps {
  index: number;
  icon: React.ReactNode;
  title: string;
  description: string;
  timeStamp: Date;
}

const Event = ({ description, icon, index, title, timeStamp }: EventProps) => {
  return (
    <div
      key={index}
      className="border rounded-md flex items-start p-3 gap-3 hover:shadow-lg"
    >
      <div>{icon}</div>
      <div>
        <h4 className="text-gray-800">{title + index}</h4>
        <p className="text-sm text-gray-700">{description}</p>
        <div className="text-sm text-gray-600 mt-3 flex items-center justify-end">
          {timeAgo(timeStamp)}
        </div>
      </div>
    </div>
  );
};

export default Event;
