import { useSearchParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { message, Spin } from "antd";
import { Eye, EyeOff, SquarePen } from "lucide-react";
import { useMutation } from "@tanstack/react-query";

import { appRoutes } from "@/utils/paths";

import useTogglePwd from "@/hooks/useTogglePwd";

import useInviteAPI from "./hooks/useInviteAPI";
import { AxiosError } from "axios";

import coverImage from "@/assets/cover/collabration.jpg";

import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import Text from "@/components/Text";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

interface SignUpFormValues {
  password: string;
  name: string;
}

const Invite = () => {
  const { activateInvite, createPassword } = useInviteAPI();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { showPwd, togglePwd } = useTogglePwd();

  React.useEffect(() => {
    if (
      searchParams.get("invite-token") === undefined ||
      searchParams.get("invite-token") === "" ||
      searchParams.get("invite-token") === null
    ) {
      navigate(appRoutes.auth.MAIN_LOGIN);
    }
  }, [searchParams.get("token")]);

  const {
    mutate,
    data: activatedToken,
    isPending,
  } = useMutation({
    mutationKey: ["activateInvite"],
    mutationFn: activateInvite,
    onSuccess: (data) => {
      message.success(data.data.message);
    },
    onError: (error: AxiosError) => {
      message.error((error.response?.data as { message: string }).message);
      navigate(appRoutes.auth.MAIN_LOGIN);
    },
  });

  React.useEffect(() => {
    mutate({ token: searchParams.get("invite-token") as string });
  }, []);

  const {
    control: signupControl,
    handleSubmit: signupSubmit,
    setValue: signup_setValue,
    formState: { errors: signupError },
  } = useForm<SignUpFormValues>({
    defaultValues: {
      password: "",
      name: "",
    },
    mode: "onSubmit",
    resolver: yupResolver(
      Yup.object().shape({
        password: Yup.string().required("Password is required"),
        name: Yup.string().required("Name is required"),
      })
    ),
  });

  const { mutate: signup } = useMutation({
    mutationKey: ["signup-1"],
    mutationFn: createPassword,
    onSuccess: (data) => {
      message.success(data.data.message);
      navigate(appRoutes.auth.MAIN_LOGIN);
    },
    onError: (error: AxiosError) => {
      message.error((error.response?.data as { message: string }).message);
    },
  });

  const onSubmitSignup = async (data: SignUpFormValues) => {
    signup({
      name: data.name,
      password: data.password,
      token: activatedToken?.data.token as string,
    });
  };

  return (
    <div
      className="flex items-center justify-center h-screen w-full bg-cover font-popins md:fixed mx-auto px-[10px]"
      style={{
        backgroundImage: `url(${coverImage})`,
        boxShadow: "inset 0 0 0 0px rgba(0, 0, 0, 0.3)",
      }}
    >
      <Card className="w-full md:w-[400px] pt-[15px] bg-inherit bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 border-none transition-shadow delay-700 shadow-2xl">
        <CardHeader>
          <CardTitle className="text-[25px]">
            <Text className="text-[55px] font-xeroda text-gray-700 mb-[20px] flex gap-[1px]">
             Wingg
            </Text>
          </CardTitle>
          <CardDescription className="text-gray-600">
            We are currently in beta, some features may not work as expected.
          </CardDescription>
        </CardHeader>
        <CardContent>
          {isPending ? (
            <Spin
              size={"large"}
              spinning
              className="flex items-center justify-center mb-5 mt-2"
            />
          ) : (
            <form onSubmit={signupSubmit(onSubmitSignup)}>
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="code">Full name</Label>
                  <Controller
                    name="name"
                    control={signupControl}
                    render={({ field }) => (
                      <Input
                        id="name"
                        type={"text"}
                        name="name"
                        value={field.value}
                        onChange={({ target }) =>
                          signup_setValue("name", target.value)
                        }
                        className="text-black"
                        placeholder="You name here"
                        error={signupError.name?.message}
                        togglePassword={togglePwd}
                        addornment={<SquarePen size={20} />}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="code">Password</Label>
                  <Controller
                    name="password"
                    control={signupControl}
                    render={({ field }) => (
                      <Input
                        id="password"
                        type={showPwd ? "text" : "password"}
                        name="password"
                        value={field.value}
                        onChange={({ target }) =>
                          signup_setValue("password", target.value)
                        }
                        className="text-black"
                        placeholder="********"
                        error={signupError.password?.message}
                        togglePassword={togglePwd}
                        addornment={
                          !showPwd ? <EyeOff size={20} /> : <Eye size={20} />
                        }
                      />
                    )}
                  />
                </div>
              </div>
              <Button
                type={"submit"}
                // loading={signupLoading}
                className="w-full mt-[20px]"
                iconClassName="mt-[20px] w-full mx-auto"
                onClick={() => {}}
              >
                Sign up
              </Button>
            </form>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Invite;
