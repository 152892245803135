import { Button, message } from "antd";
import { ArrowRightToLine } from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import http from "@/http";
import { AxiosError } from "axios";

interface SelectPlanProps {
  selectedOption: string | null;
  setSelectedOption: (value: "INDIVIDUAL" | "ORGAZNIZATION" | null) => void;
  setStep: (value: "org_setup" | "onboarded" | "department_setup" | "team_setup" | "profession_details") => void;
}

export default function SelectPlan({
  selectedOption,
  setSelectedOption,
  setStep,
}: SelectPlanProps) {
  const { mutate: accoutTypeMutation, isPending: accountTypePending } =
    useMutation({
      mutationKey: ["createAccountType", "account_type"],
      mutationFn: () =>
        http.post("/user/onboard-plan-selection", {
          account_type: selectedOption,
        }),
      onError: (error: AxiosError) => {
        return message.error(
          (error.response?.data as { message: string }).message
        );
      },
      onSuccess: () => {
        setStep("profession_details")
      },
    });

  return (
    <div>
      <h1 className="text-3xl font-bold mb-6 text-gray-800">
        Tell us more about your work
      </h1>
      <p className="text-gray-600 mb-8">
        Let's tailor the system to your needs.
      </p>
      <div className="space-y-4">
        <Button
          type={selectedOption === "INDIVIDUAL" ? "default" : "dashed"}
          className={`w-full justify-start text-left h-auto py-4 px-6 ${
            selectedOption === "INDIVIDUAL"
              ? "bg-orange-100 hover:bg-orange-200 text-gray-800"
              : "hover:bg-orange-100"
          }`}
          onClick={() => setSelectedOption("INDIVIDUAL")}
        >
          <div>
            <div className="font-semibold">I'm an individual</div>
            <div className="text-sm text-gray-500">
              Personal account for you
            </div>
          </div>
        </Button>
        <Button
          type={selectedOption === "ORGAZNIZATION" ? "default" : "dashed"}
          className={`w-full justify-start text-left h-auto py-4 px-6 ${
            selectedOption === "ORGAZNIZATION"
              ? "bg-orange-100 hover:bg-orange-200 text-gray-800"
              : "hover:bg-orange-100"
          }`}
          onClick={() => setSelectedOption("ORGAZNIZATION")}
        >
          <div>
            <div className="font-semibold">I'm part of an organization</div>
            <div className="text-sm text-gray-500">
              Collaborative account for teams
            </div>
          </div>
        </Button>
      </div>
      <div className="mt-8 flex justify-end">
        <Button
          onClick={() => accoutTypeMutation()}
          size="large"
          iconPosition="end"
          icon={<ArrowRightToLine size={18} />}
          className="bg-orange-500 hover:bg-orange-600 text-white w-[100px]"
          disabled={!selectedOption}
          loading={accountTypePending}
        >
          Next
        </Button>
      </div>
    </div>
  );
}
