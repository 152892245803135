import React, { createContext, useState, useContext } from "react";

interface LineItemProps {
  height: number;
  setHeight: React.Dispatch<React.SetStateAction<number>>;
}

interface LineItemsProviderProps {
  children: React.ReactNode;
}

const Drawerontext = createContext<LineItemProps | undefined>(undefined);

export const DrawerProvider: React.FC<LineItemsProviderProps> = ({
  children,
}) => {
  const [height, setHeight] = useState(0);

  return (
    <Drawerontext.Provider value={{ height, setHeight }}>
      {children}
    </Drawerontext.Provider>
  );
};

export const useDrawer = () => {
  const context = useContext(Drawerontext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
