import { Card, CardContent } from "@/components/ui/card";
import { BarChart2, Sparkles } from "lucide-react";

export default function BetaCard() {
  return (
    <Card className="relative overflow-hidden border-2 border-dashed border-primary/20 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 mb-8 shadow-lg rounded-lg">
      <CardContent className="p-8">
        <div className="flex items-start gap-6">
          <div className="rounded-full bg-primary/10 p-4">
            <BarChart2 className="h-8 w-8 text-primary" />
          </div>
          <div className="space-y-3 flex-1">
            <div className="flex items-center gap-3">
              <h3 className="text-2xl font-bold text-gray-800">
                Analytics Beta
              </h3>
              <div className="flex items-center rounded-full bg-primary/10 px-3 py-1 text-sm font-semibold text-primary">
                <Sparkles className="mr-1 h-4 w-4" />
                Beta
              </div>
            </div>
            <p className="text-base text-muted-foreground leading-relaxed">
              We’re excited to share the first phase of our Analytics feature,
              currently in beta. You can expect more insights and metrics to
              roll out gradually as we refine and expand this feature.
            </p>
            <p className="text-sm text-muted-foreground mt-4">
              For now, you can access key metrics, including the total emails
              received, counts by status, priority levels, and label categories.
              We’re gradually expanding our analytics offerings, so if you have
              specific insights in mind or would like us to prioritize certain
              data points, please don’t hesitate to reach out.
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
