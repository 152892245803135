/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from "react";
import { Empty, Pagination, message } from "antd";
import { Link } from "react-router-dom";
import introJs from "intro.js";
import { CancelTokenSource } from "axios";
import { createCancelToken } from "@/http";
import { useQuery } from "@tanstack/react-query";

import {
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import {
  Trash,
  Loader2,
  Workflow,
  Settings2,
  Layers2,
  Pencil,
  Copy,
  ArrowUpSquare,
} from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuItem,
  DropdownMenuLabel,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { useUser } from "@/providers/userProvider";

import { WorkflowDataColumns } from "./colums/workflowColumn";

import DeleteWorkflow from "./components/DeleteWorkflow";
import NewWorkflow from "./components/NewWorkflow";
import EditWorkflow from "./components/EditWorkflow";
import DuplicateWorkflow from "./components/DuplicateWorkflow";

import { useNewWorkflow } from "./hooks/useWorkflow";

import { Workflow as WorkflowType } from "@/types/workflow";
import { adminAccess } from "@/types/role";

import workflowEmpty from "@/assets/svgs/no-file.svg";

export function WorkflowsTable() {
  const { fetchWorkflow } = useNewWorkflow();
  const { user } = useUser();

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showSheet, setShowSheet] = React.useState(false);
  const [showEditSheet, setShowEditSheet] = React.useState(false);
  const [showDuplicateSheet, setShowDuplicateSheet] = React.useState(false);

  const [workflowInfo, setWorkflowInfo] = React.useState<WorkflowType>();

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["workflow", page, pageSize],
    queryFn: () => fetchWorkflow({ page, limit: pageSize }),
  });

  const cancelTokenRef = React.useRef<CancelTokenSource | null>(null);
  cancelTokenRef.current = createCancelToken();

  React.useEffect(() => {
    setPage(page);
    // refetch();
  }, [page, pageSize]);

  /* ---- Action Functions ----*/
  const handleDeleteModalOpener = (workflow: WorkflowType) => {
    setWorkflowInfo(workflow);
    setShowDeleteDialog(true);
  };

  const handleNewWorkflowSheetOpener = () => {
    setShowSheet(true);
  };

  const handleEditModalOpener = (workflow: WorkflowType) => {
    setWorkflowInfo(workflow);
    setShowEditSheet(true);
  };

  const handleDuplicateWorkflow = (workflow: WorkflowType) => {
    setWorkflowInfo(workflow);
    setShowDuplicateSheet(true);
  };

  // intro.js
  const createWorkflowRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (
      data &&
      data?.data.data.length <= 0 &&
      adminAccess.filter((role) => role === user?.role).length
    ) {
      introJs()
        .setOptions({
          steps: [
            {
              element: createWorkflowRef.current,
              intro: "Click here to create a new workflow.",
              tooltipClass: "dark:text-gray-50 dark:!bg-gray-900",
              title: "Create Workflow",
            },
          ],
        })
        .start();
    }
  }, []);

  /* ---- Table Update Functions ----*/

  const table = useReactTable({
    data: data?.data.data ?? [],
    manualPagination: true,
    columns: [
      ...WorkflowDataColumns,
      {
        id: "actions",
        cell: ({ row }) => {
          const workflow = row.original;
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="h-8 w-8 p-0">
                  <span className="sr-only">Open menu</span>
                  <DotsHorizontalIcon className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="font-popins">
                <DropdownMenuLabel>Actions</DropdownMenuLabel>
                <Link to={`${workflow.workflow_name}-${workflow.workflow_id}`}>
                  <DropdownMenuItem className="flex items-center gap-1 text-[#7c7c7c]">
                    <ArrowUpSquare size={20} strokeWidth={1.2} />
                    Open workflow
                  </DropdownMenuItem>
                </Link>
                <DropdownMenuItem
                  onClick={() => {
                    navigator.clipboard.writeText(workflow.workflow_id);
                    message.success("WorkflowID copied to clipboard");
                  }}
                  className="flex items-center gap-1 text-[#7c7c7c]"
                >
                  <Copy size={20} strokeWidth={1.2} />
                  Copy workflow ID
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => handleDuplicateWorkflow(workflow)}
                  className="flex items-center gap-1 text-[#7c7c7c]"
                >
                  <Layers2 size={20} strokeWidth={1.2} />
                  Duplicate workflow
                </DropdownMenuItem>
                <Link
                  to={`/configurations/${row.original.workflow_id}`}
                  state={{
                    workflow_name: row.original.workflow_name,
                  }}
                >
                  <DropdownMenuItem className="flex items-center gap-1 text-[#7c7c7c]">
                    <Settings2 size={20} strokeWidth={1.2} />
                    Configurations
                  </DropdownMenuItem>
                </Link>
                <DropdownMenuItem
                  className="flex items-center gap-1 text-[#7c7c7c]"
                  onClick={() => handleEditModalOpener(workflow)}
                >
                  <Pencil size={20} strokeWidth={1.2} />
                  Rename workflow
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => handleDeleteModalOpener(workflow)}
                  className="flex items-center gap-1 text-[#7c7c7c] hover:!text-red-500"
                >
                  <Trash size={20} strokeWidth={1.2} />
                  Delete workflow
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
        },
      },
    ],
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      columnFilters,
    },
  });

  return (
    <div className="h-[calc(100vh-72px)] w-full ml-0 relative">
      {!isLoading ? (
        <div className="px-4">
          <DeleteWorkflow
            isOpen={showDeleteDialog}
            onClose={() => setShowDeleteDialog(false)}
            workflowInfo={workflowInfo as WorkflowType}
            key={workflowInfo?.workflow_id}
            refetch={refetch}
          />
          <NewWorkflow
            setShowSheet={setShowSheet}
            showNewWorkflow={showSheet}
          />
          <EditWorkflow
            refetch={refetch}
            setShowEditWorkflow={setShowEditSheet}
            showEditWorkflow={showEditSheet}
            workflowInfo={workflowInfo}
            key={workflowInfo?.workflow_id}
          />
          <DuplicateWorkflow
            refetch={refetch}
            setShowEditWorkflow={setShowDuplicateSheet}
            showEditWorkflow={showDuplicateSheet}
            workflowInfo={workflowInfo}
            key={workflowInfo?.workflow_id}
          />
          <div className="flex items-center py-4 justify-between">
            <Input
              placeholder="Filter workflow with their name..."
              value={
                (table
                  .getColumn("workflow_name")
                  ?.getFilterValue() as string) ?? ""
              }
              onChange={(event) =>
                table
                  .getColumn("workflow_name")
                  ?.setFilterValue(event.target.value)
              }
              className="min-w-[500px] w-full"
            />
            {adminAccess.filter((role) => role === user?.role).length ? (
              <Button
                className="flex gap-[5px] items-center"
                ref={createWorkflowRef}
                onClick={handleNewWorkflowSheetOpener}
              >
                <Workflow strokeWidth={1} size={20} />
                Create Workflow
              </Button>
            ) : null}
          </div>
          <div className="rounded-md border max-h-[700px] overflow-scroll">
            <Table>
              <TableHeader>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </TableHead>
                      );
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table
                    .getRowModel()
                    .rows.sort((a, b) => {
                      const a_date = new Date(a.original.createdAt ?? "");
                      const b_date = new Date(b.original.createdAt ?? "");
                      return b_date.getTime() - a_date.getTime();
                    })
                    .map((row) => (
                      <TableRow key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={WorkflowDataColumns.length + 1}
                      className="h-24 text-center"
                    >
                      <Empty
                        className="py-10 font-popins flex flex-col items-center justify-center"
                        description="No workflows found!!"
                        image={workflowEmpty}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <div className="flex items-center bg-[#fbfcfe] dark:bg-gray-800 justify-between py-[11.5px] px-[25px] absolute right-0 bottom-0 left-0 border-t w-full">
            <div className="flex-1 text-sm text-muted-foreground">
              {table.getFilteredSelectedRowModel().rows.length > 0 && (
                <span className="text-[13px] text-gray-700">
                  {table.getFilteredSelectedRowModel().rows.length} workflow(s)
                  selected
                </span>
              )}
            </div>
            <div className="space-x-2 flex items-center">
              <Pagination
                current={page}
                pageSize={pageSize}
                total={data?.data.pagination.total || 0}
                onChange={(page) => setPage(page)}
                showSizeChanger={true}
                onShowSizeChange={(current, size) => {
                  setPageSize(size);
                  setPage(current);
                }}
                totalBoundaryShowSizeChanger={10}
                showTotal={(total, range) => (
                  <span className="dark:text-white">
                    Showing {range[0]}-{range[1]} of {total} workflows
                  </span>
                )}
                showLessItems={true}
                showTitle={true}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center mt-[40px] gap-[6px]">
          <Loader2 className="animate-spin h-5 w-5 text-gray-400" />
          <p className="text-gray-400">Loading...</p>
        </div>
      )}
    </div>
  );
}
