/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/ban-types */
import React from "react";

import storage from "@/utils/storage";
import { DispatchAction, Tokens } from "@/types";
import { TOKENS } from "@/constant";
import useUpdateEffect from "@/hooks/useUpdateEffect";

const INITAL_STATE: Tokens = {};

export const TokenActions = {
  setTokens: "SET_TOKENS",
  logout: "LOGOUT",
};

const reducer = (state: Tokens, action: DispatchAction<Tokens>) => {
  switch (action.type) {
    case TokenActions.setTokens:
      return { ...state, ...action.payload };
    case TokenActions.logout:
      return INITAL_STATE;
    default:
      throw new Error("Invalid action type");
  }
};

const TokenContext = React.createContext<Tokens>({});
TokenContext.displayName = "TokenContext";

const initializeTokenState = (initState: Tokens): Tokens => {
  return { ...initState, ...storage.get<Tokens>(TOKENS)};
};

const TokenDispatch = React.createContext<
  React.Dispatch<DispatchAction<Tokens>>
>(() => {});
TokenDispatch.displayName = "TokenDispatch";

export default function AuthProvider(props: React.PropsWithChildren<{}>) {
  const [tokenState, dispatch] = React.useReducer(
    reducer,
    INITAL_STATE,
    initializeTokenState
  );

  useUpdateEffect(() => {
    storage.set(TOKENS, tokenState);
  }, [tokenState]);

  return (
    <TokenDispatch.Provider value={dispatch}>
      <TokenContext.Provider value={tokenState}>
        {props.children}
      </TokenContext.Provider>
    </TokenDispatch.Provider>
  );
}

export const useToken = () => React.useContext(TokenContext);
export const useTokenDispatch = () => React.useContext(TokenDispatch);
