import React from "react";
import { AxiosError } from "axios";
import { useMutation } from "@tanstack/react-query";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";

import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

import { Workflow } from "@/types/workflow";
import { Workflow as WorkflowIcon } from "lucide-react";

import { useNewWorkflow } from "../hooks/useWorkflow";

interface FormValues {
  workflow_name: string;
  workflow_description: string;
}

interface Props {
  setShowEditWorkflow: React.Dispatch<React.SetStateAction<boolean>>;
  showEditWorkflow: boolean;
  workflowInfo: Workflow | undefined;
  refetch: () => void;
}

const DuplicateWorkflow = ({
  setShowEditWorkflow,
  showEditWorkflow,
  workflowInfo,
  refetch,
}: Props) => {
  const { duplicateWorkflow } = useNewWorkflow();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      workflow_name: workflowInfo?.workflow_name.concat(" [duplicate]"),
      workflow_description: workflowInfo?.workflow_description || "",
    },
    mode: "onSubmit",
    resolver: yupResolver(
      Yup.object().shape({
        workflow_name: Yup.string()
          .required("It can not be empty")
          .default(workflowInfo?.workflow_name.concat(" [duplicate]")),
        workflow_description: Yup.string().default(
          workflowInfo?.workflow_description || "duplicate"
        ),
      })
    ),
  });

  const { mutateAsync, isPending, error } = useMutation({
    mutationKey: ["duplicateWorkflow", workflowInfo?.workflow_id],
    mutationFn: async (data: FormValues) => {
      return await duplicateWorkflow({
        workflow_id: workflowInfo?.workflow_id || "",
        workflow_name: data.workflow_name || "",
        workflow_description: data.workflow_description || "",
      });
    },
    onError: (error: AxiosError) => {
      return  message.error(
        (error?.response?.data as { message: string })?.message);
    },
    onSuccess: (data) => {
      refetch();
      message.success(data.data?.message);
      setShowEditWorkflow(false);
    },
  });

  const onSubmit = async (data: FormValues) => {
    mutateAsync({ ...data });
  };

  return (
    <Sheet
      open={showEditWorkflow}
      onOpenChange={() => setShowEditWorkflow(false)}
    >
      <SheetContent className="min-w-[500px]">
        <SheetHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <SheetTitle>
              Duplicate {workflowInfo?.workflow_name} workflow
            </SheetTitle>
            <SheetDescription>
              Duplicate the workflow schema configurations
            </SheetDescription>
            <div className="grid gap-4 py-4 w-full">
              <div className="mb-[-5px] flex items-center justify-between">
                <Label>Workflow name</Label>
              </div>
              <Controller
                name="workflow_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="what is the name of the workflow?"
                    addornment={<WorkflowIcon size={20} />}
                    error={errors.workflow_name?.message || error?.message}
                  />
                )}
              />
            </div>
            <div className="grid gap-4 py-4 w-full">
              <div className="mb-[-5px] flex items-center justify-between">
                <Label>Workflow desc</Label>
              </div>
              <Controller
                name="workflow_description"
                control={control}
                render={({ field }) => (
                  <textarea
                    {...field}
                    className="flex min-h-[70px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 max-h-[130px]"
                    placeholder="short description of the workflow"
                  />
                )}
              />
            </div>
            <div className="flex items-start gap-[15px] mt-[30px]">
              <Button
                type="submit"
                className="w-[100px]"
                loading={isPending}
                variant={"default"}
              >
                Create
              </Button>
            </div>
          </form>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

export default DuplicateWorkflow;
