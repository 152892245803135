import React from "react";
import { Check, ChevronsUpDown, AlertTriangle } from "lucide-react";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, Button as BTN, message, notification } from "antd";
import { useMutation } from "@tanstack/react-query";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import { cn } from "@/lib/utils";

import { useUser } from "@/providers/userProvider";

import helpDesk from '@/assets/svgs/help-desk.svg'

import { useRequestFormAPI } from './hook'
import { AxiosError } from "axios";

const requests = [
  { label: "Feature request" },
  { label: "Accuracy" },
  { label: "Performance" },
  { label: "Add new field to schema" },
  { label: "Output formatting" },
  { label: "Integration" },
  { label: "Others" },
];

interface RequestFormProps {
  open: boolean;
  onChange: (open: boolean) => void;
}

interface FromRequest {
  requestType: string;
  note: string;
}

const RequestForm: React.FC<RequestFormProps> = ({ onChange, open }) => {
  const { user } = useUser();
  const {addRequest} = useRequestFormAPI()
  const [selectedRequest, setSelectedRequest] = React.useState<string>("");
  const [openBox, setOpenBox] = React.useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<FromRequest>({
    defaultValues: {
      requestType: "",
      note: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        requestType: Yup.string().required("Request type is required"),
        note: Yup.string().required("Note is required"),
      })
    ),
  });

  const { mutate,isPending } = useMutation({
    mutationKey: ["addRequest"],
    mutationFn: addRequest,
    onError: (error:AxiosError) => {
      message.error((error?.response?.data as {message:string}).message || "Something went wrong");
    },
    onSuccess: () => {
      notification.success({
        message: "Request submitted successfully",
        description: "We will get back to you soon",
        type: "success",
        showProgress: true
      });
      onChange(false)
      reset()
    }
  })

  const onSubmit = (data: FromRequest) => {
    mutate({
      email: user?.email as string,
      request_type: data.requestType,
      note: data.note,
    })
  };

  return (
    <Dialog open={open} onOpenChange={onChange} modal>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader>
            <DialogTitle className="text-[19px] font-bold">
              Support request form
            </DialogTitle>
            <DialogDescription className="flex items-start">
           <p>
           Fill this form to help keeping track of your request or
           improvements you would like to see in the platform.
           </p>
              <img src={helpDesk} alt="support" className="h-[120px] w-[120px] pointer-events-none mt-[-50px]" />
            </DialogDescription>
          </DialogHeader>
          <div className="grid grid-cols-1 gap-5 mt-3">
            <div className="grid grid-cols-1 gap-2 items-center justify-center">
              <label htmlFor="email">Email*</label>
              <Input
                type="email"
                className="cursor-not-allowed"
                id="email"
                value={user?.email}
              />
            </div>
            <div className="grid grid-cols-1 gap-2 items-center">
              <label htmlFor="email">Request type*</label>
              <div>
                <Popover open={openBox} onOpenChange={setOpenBox}>
                  <PopoverTrigger asChild>
                    <Button
                      variant={"outline"}
                      role="combobox"
                      className="w-full justify-between"
                      aria-expanded={openBox}
                    >
                      {selectedRequest || "Select request"}
                      <ChevronsUpDown
                        size={20}
                        className="h-4 w-4 shrink-0 opacity-50"
                      />
                    </Button>
                  </PopoverTrigger>
                  {errors.requestType && (
                    <div className="text-red-500 text-sm flex items-center gap-2 my-1">
                      <AlertTriangle size={15} /> {errors.requestType?.message}
                    </div>
                  )}
                  <PopoverContent className="p-0 grid grid-cols-1 w-[400px]">
                    <Command>
                      <CommandInput placeholder="Search request types" />
                      <CommandList>
                        <CommandEmpty>No request found</CommandEmpty>
                        <CommandGroup>
                          {requests.map((request, index) => (
                            <Controller
                              key={index}
                              name="requestType"
                              control={control}
                              render={({ field }) => (
                                <CommandItem
                                  key={index}
                                  value={request.label}
                                  onSelect={(currentValue) => {
                                    setSelectedRequest(
                                      currentValue === selectedRequest
                                        ? ""
                                        : currentValue
                                    );
                                    setOpenBox(false);
                                    field.onChange(currentValue);
                                  }}
                                >
                                  <Check
                                    size={20}
                                    className={cn(
                                      "h-4 w-4 shrink-0 mr-2",
                                      selectedRequest
                                        .toLocaleLowerCase()
                                        .toString() ===
                                        request.label
                                          .toLocaleLowerCase()
                                          .toString()
                                        ? "opacity-100 text-gray-800"
                                        : "opacity-0 text-gray-800"
                                    )}
                                  />
                                  {request.label}
                                </CommandItem>
                              )}
                            />
                          ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-2 items-center justify-center mt-4">
            <label htmlFor="note">Note*</label>
            <Controller
              name="note"
              control={control}
              render={({ field }) => (
                <textarea
                  {...field}
                  id="note"
                  className="border rounded-md p-2 min-h-[50px] max-h-[100px]"
                  placeholder="Add note..."
                />
              )}
            />
            {errors.note && (
              <div className="text-red-500 text-sm flex items-center gap-2 my-.5">
                <AlertTriangle size={15} /> {errors.note?.message}
              </div>
            )}
          </div>
          <BTN className="w-[100px] mt-3" htmlType="submit" loading={isPending} type="primary">
            Submit
          </BTN>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RequestForm;
