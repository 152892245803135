import React from "react";
import { clsx } from "clsx";

import { colors } from "@/media/index";

interface Props {
  children: React.ReactNode;
  variant?: "primary" | "secondary";
  bold?: boolean;
  semiBold?: boolean;
  small?: boolean;
  toSmall?: boolean;
  className?: string;
  big?: boolean;
}

const Text: React.FC<Props> = (props) => {
  return (
    <p
      className={clsx(
        `font-popins text-[14px] leading-[16px] dark:text-white ${props.className}`,
        props.bold && "font-bold",
        props.semiBold && "font-semibold",
        props.small && "text-[12px]",
        props.toSmall && "text-[10px]",
        props.big && "text-[16px]",
        props.variant === "primary" && `text-[${colors.fontPrimary}]`,
        props.variant === "secondary" && `text-[${colors.fontSecondary}]`
      )}
    >
      {props.children}
    </p>
  );
};

export default Text;
