import { Skeleton } from "antd";

export const MessageSkeleton = () => {
  return (
    <div className="flex gap-4 items-start mt-[20px] border px-[10px] py-[20px] rounded-md">
      <Skeleton.Avatar active size="small" />
      <div className="w-full">
        <Skeleton.Input active size="small" className="!w-full !h-[20px]" />
        <Skeleton.Input
          active
          size="small"
          className="!w-full !h-[10px] mt-2"
        />
        <Skeleton.Input
          active
          size="small"
          className="!w-full !h-[10px] mt-1"
        />
        <Skeleton.Input
          active
          size="small"
          className="!w-full !h-[10px] mt-1"
        />
      </div>
    </div>
  );
};

export const DividerSkeleton = () => {
  return (
    <div className="flex items-center gap-2 mt-3">
      <div className="border-t border-gray-300 w-full" />
      <p className="text-gray-500 text-xs px-[5px] rounded-full py-[5px] font-semibold flex items-center justify-between">
        <Skeleton.Input
          active
          size="small"
          className="!w-[140px] !rounded-full"
        />
      </p>
      <div className="border-t border-gray-300 w-full" />
    </div>
  );
};

export const AttachementsSkeleton = () => {
  return (
    <div className="mt-4 w-full">
      <div className="flex items-center justify-between">
        <Skeleton.Input active size="small" className="!w-[150px] !h-[10px]" />
        <Skeleton.Input active size="small" className="!w-[120px] !h-[10px]" />
      </div>
      <div className="flex gap-[20px] mt-4">
        <Skeleton.Input active size="small" className="!w-[120px]" />
        <Skeleton.Input active size="small" className="!w-[120px]" />
        <Skeleton.Input active size="small" className="!w-[120px]" />
      </div>
    </div>
  );
};

export const HeaderSkeleton = () => {
  return (
    <div className="flex items-start w-full gap-4">
      <Skeleton.Avatar active size="large" />
      <div className="w-full">
        <Skeleton.Input active size="small" className="!w-full !h-[20px]" />
        <Skeleton.Input
          active
          size="small"
          className="!w-[150px] !h-[10px] mt-1"
        />
      </div>
    </div>
  );
};
