import React from "react";

export default function useUpdateEffect(effect:React.EffectCallback, deps:React.DependencyList) {
    const isMounted = React.useRef(false);

    React.useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
        } else {
            return effect();
        }
    }, deps);
}