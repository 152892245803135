import React from 'react'

const useTogglePwd = () => {
  const [showPwd, setShowPwd] = React.useState(false)

  const togglePwd = () => {
    setShowPwd(!showPwd)
  }

  return {
    showPwd,
    togglePwd
  }
}

export default useTogglePwd