import { ChevronDown } from "lucide-react";

interface DividerProps {
  count: number;
  onclick: () => void;
}

const Divider: React.FC<DividerProps> = ({ count,onclick }) => {
  return (
    <div>
      <div className="flex items-center gap-2">
        <div className="border-t border-gray-300 w-full" />
        <p onClick={onclick} className="text-gray-500 text-xs px-[5px] bg-gray-200 rounded-full min-w-[143px] py-[5px] font-semibold flex items-center justify-between hover:bg-gray-300 cursor-pointer">
          <span className="bg-black text-white h-[16px] w-[16px] rounded-full mr-1 flex items-center justify-center">
            {count}
          </span>
          older messages
          <ChevronDown size={16} className="ml-3" />
        </p>
        <div className="border-t border-gray-300 w-full" />
      </div>
    </div>
  );
};

export default Divider;
