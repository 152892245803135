import { useQuery } from "@tanstack/react-query";
import React from "react";
import { notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";

import Editor from "./Editor";
import Stats from "./Stats";
import ThreadHeader from "./threads/ThreadHeader";
import Divider from "./threads/Divider";
import Message from "./threads/Message";
import {
  AttachementsSkeleton,
  HeaderSkeleton,
  MessageSkeleton,
} from "./Skelton";

import { useThread } from "@/providers/thredContext";
import { useReplay, Methods } from "@/providers/replayContext";

import { ReactComponent as InvalidIcon } from "@/assets/svgs/invalid-message.svg";
import { ReactComponent as MailBroken } from "@/assets/svgs/mail-broken.svg";

import { gmailApi } from "../api/gmail";
import { Label } from "@/types/email.type";

import Header from "./threads/Header";
import { AxiosError } from "axios";

const Threads = ({
  searchText,
  labels,
}: {
  searchText: string;
  labels: Label[] | undefined;
}) => {
  const { getThreadsMessages } = gmailApi();
  const { thread, setThread } = useThread();
  const { replayMessage } = useReplay();
  const navigate = useNavigate();
  const { inbox_type } = useParams();

  const [olderMessagesIndex, setOlderMessagesIndex] = React.useState(0);
  const [activeMessageIndex, setActiveMessageIndex] = React.useState(0);
  const [showAllMessages, setShowAllMessages] = React.useState(false);

  const { data, status, refetch, error } = useQuery({
    queryKey: ["threads", thread.thredId],
    queryFn: () => getThreadsMessages(thread.thredId),
    enabled: !!thread.thredId,
    retry: false,
  });

  React.useEffect(() => {
    if (error) {
      navigate("/inbox/" + inbox_type, { replace: true });
      notification.error({
        message: (
          <p className="text-sm font-semibold text-gray-600 flex items-center ml-2">
            {" "}
            {
              ((error as AxiosError).response?.data as { message: string })
                ?.message
            }
          </p>
        ),
        duration: 10,
        icon:
          ((error as AxiosError).response?.data as { status: number })
            ?.status === 400 ? (
            <InvalidIcon
              height={"30px"}
              width={"30px"}
              className="fill-red-400 stroke-red-400"
            />
          ) : (
            <MailBroken
              width={"40px"}
              className="m-[-8px] fill-red-400 stroke-red-400"
            />
          ),
        closable: true,
      });
      setThread({ thredId: "" });
    }
  }, [error, navigate, inbox_type, setThread]);

  React.useEffect(() => {
    if (!data) return;
    setActiveMessageIndex(data.data.threads.messages.length - 1);
    setShowAllMessages(false);
  }, [thread.thredId, data]);

  React.useEffect(() => {
    const messageLength = data?.data.threads.messages.length ?? 0;
    setOlderMessagesIndex(messageLength - 2);
    if (!data) return;
    setActiveMessageIndex(messageLength - 1);
  }, [data]);

  const handleShowOlderMessages = () => {
    setShowAllMessages(true);
  };

  return (
    <div className="relative w-full">
      {!thread.thredId ? (
        <Stats />
      ) : (
        <div className="relative h-[calc(100vh-5px)] overflow-scroll">
          <div className="px-[10px] py-[10px] sticky top-0">
            <Header labels={labels} />
            {status === "pending" ? (
              <HeaderSkeleton />
            ) : (
              <ThreadHeader
                searchText={searchText}
                subject={
                  data?.data.threads?.messages[0]?.payload.headers.find(
                    (header) => header.name === "Subject"
                  )?.value as string
                }
                cc={
                  data?.data.threads?.messages[0]?.payload.headers.find(
                    (header) => header.name === "Cc"
                  )?.value as string
                }
                from={
                  data?.data.threads?.messages[0]?.payload.headers.find(
                    (header) => header.name === "From"
                  )?.value as string
                }
                threads={data?.data.threads?.messages.length as number}
                to={
                  data?.data.threads?.messages[0]?.payload.headers.find(
                    (header) => header.name === "To"
                  )?.value as string
                }
              />
            )}
            <div className="overflow-scroll h-[calc(100vh-150px)]">
              {status === "pending" ? (
                <MessageSkeleton />
              ) : (
                data?.data.threads.messages.map((message, index) => {
                  const isLastMessage =
                    index === data?.data.threads.messages.length - 1;
                  const isActive = isLastMessage || showAllMessages;

                  return (
                    <div key={message?.id} className="w-full">
                      <Message
                        metadata={message.metadata}
                        searchText={searchText}
                        active={isActive}
                        messageId={message?.id}
                        content={message?.snippet}
                        to={
                          message?.payload.headers.find(
                            (header) => header.name === "To"
                          )?.value
                        }
                        from={
                          message?.payload.headers.find(
                            (header) => header.name === "From"
                          )?.value as string
                        }
                        timeStamp={new Date(parseInt(message?.internalDate))}
                      />
                      {index === olderMessagesIndex &&
                        data?.data.threads.messages.length > 1 &&
                        !showAllMessages && (
                          <div className="mt-3">
                            <Divider
                              count={activeMessageIndex}
                              onclick={handleShowOlderMessages}
                            />
                          </div>
                        )}
                    </div>
                  );
                })
              )}
              {status === "pending" ? <AttachementsSkeleton /> : null}
            </div>

            {replayMessage?.method != Methods.COMPOSE && (
              <div
                className={
                  "w-full sticky bottom-0  border rounded-md bg-white shadow-2xl"
                }
              >
                <Editor refetch={refetch} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Threads;
