import http from "@/http";
import { LoginResponse } from "@/model/user.model";

export const use2FA = () => {
  const verify2FA = async ({token,email}:{token:string,email:string}) => {
   return await http.post<LoginResponse>("/auth/verify-code", { token, email });
  };

  return {
    verify2FA,
  };
};