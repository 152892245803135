import { InviteUser } from "../hooks/types";
import { CalendarCheck } from "lucide-react";

import { ColumnDef } from "@tanstack/react-table";

import { Role } from "../components/Role";

export const InviteDataColumns: ColumnDef<InviteUser>[] = [
  {
    accessorKey: "email",
    header: () => <div className="text-left">Email</div>,
    cell: ({ row }) => {
      return row.original.email;
    },
  },
  {
    accessorKey: "expireAt",
    header: () => <div className="text-left ml-[50px]">Expire at</div>,
    cell: ({ row }) => {
      const data = row.original.expire_at;
      const date = new Date(data);
      return (
        <div className="items-center justify-start ml-[50px] flex gap-1 text-[#7c7c7c] dark:text-gray-400">
          <CalendarCheck color="gray" size={22} strokeWidth={1.2} />
          {date?.getTime() > 0 ? (
            <>
              {date?.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
                calendar: "gregory",
                numberingSystem: "latn",
                localeMatcher: "lookup",
                weekday: "short",
              })}
              {
                // date left
                date.getTime() - new Date().getTime() > 0 ? (
                  <span className="text-green-500">
                    {" "}
                    (in {date.getDate() - new Date().getDate()} day)
                  </span>
                ) : (
                  <span className="text-red-500"> (expired)</span>
                )
              }
            </>
          ) : (
            "N/A"
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "Role",
    header: () => <div className="text-left">Role</div>,
    cell: ({ row }) => {
      return <Role role={row.original.role} value={row.original?.email as string} table_name="invite"/>;
    },
  },
];
