import { Transition } from "@headlessui/react";
import React from "react";
import { ChevronDown } from "lucide-react";
import { cn } from "@/lib/utils";
import { Input } from "antd";

interface Props {
  defaultPlaceHolder?: string;
  open?: boolean;
  className?: string;
  onChange: (value: string) => void;
  hasSeparator?: boolean;
  items: {
    label: string;
    value: string;
    desc?: string;
    icon?: React.ReactNode;
  }[];
}

export const Dropdown = ({
  items,
  onChange,
  open,
  defaultPlaceHolder = "Select workflow type",
  hasSeparator = true,
  className,
}: Props) => {
  const divRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setOpenDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const [selected, setSelected] = React.useState<string | null>(null);
  const [openDropdown, setOpenDropdown] = React.useState(open || false);

  const onOpen = () => {
    setOpenDropdown(!openDropdown);
  };

  return (
    <div className={className}>
      <div className="relative w-full">
        <div onClick={onOpen}>
          <Input value={selected || defaultPlaceHolder} className="w-full h-[40px]" />
          <ChevronDown
            className={cn(
              "absolute right-2 top-[15px] transform -translate-y-2 transition",
              openDropdown && "rotate-180"
            )}
            strokeWidth={1}
          />
        </div>
        <Transition
          show={openDropdown}
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="absolute z-10 mt-2 w-full rounded-md shadow-lg bg-white"
            ref={divRef}
          >
            <div
              className="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {items.map((item, index) => (
                <React.Fragment key={index}>
                  <div
                    className="px-4 py-2 m-1 cursor-pointer hover:bg-gray-100 rounded-md flex items-start gap-1"
                    onClick={() => {
                      setSelected(item.label);
                      onChange(item.value);
                      setOpenDropdown(false);
                    }}
                  >
                    {item?.icon}
                    <div>
                      <h6 className="text-gray-700">{item.label}</h6>
                      {item.desc && (
                        <div className="text-xs text-gray-500">{item.desc}</div>
                      )}
                    </div>
                  </div>
                  {hasSeparator && index < items.length - 1 && <Separator />}
                </React.Fragment>
              ))}
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

const Separator = () => (
  <div className="border-t border-gray-200  w-[calc(100%-1.5rem)] mx-[10px]" />
);
