import { Outlet, Navigate, useLocation } from "react-router-dom";

import { useToken } from "@/providers/useAuthProvider";

import { appRoutes } from "@/utils/paths";

const Index = () => {
  const token = useToken();

  const { pathname } = useLocation();

  if (Object.keys(token ?? {}).length) {
    return <Navigate to={appRoutes.auth.INDEX} state={{ route: pathname }} />;
  }

  return <Outlet />;
};

export default Index;
