import { User } from "../hooks/types";
import { CalendarCheck } from "lucide-react";

import { ColumnDef } from "@tanstack/react-table";

import { Role } from "../components/Role";

export const UserDataColumns: ColumnDef<User>[] = [
  {
    accessorKey: "name",
    header: () => <div className="text-left">Name</div>,
    cell: ({ row }) => {
      return (
        <div className="text-gray-700 dark:text-gray-400">
          {row.original?.name}
        </div>
      );
    },
  },
  {
    accessorKey: "email",
    header: () => <div className="text-left">Email</div>,
    cell: ({ row }) => {
      return row.original.email;
    },
  },
  {
    accessorKey: "createdAt",
    header: () => <div className="text-left ml-[50px]">Create at</div>,
    cell: ({ row }) => {
      const location = row.getValue("createdAt") as string;
      const date = new Date(location);
      return (
        <div className="items-center justify-start ml-[50px] flex gap-1 text-[#7c7c7c] dark:text-gray-400">
          <CalendarCheck color="gray" size={22} strokeWidth={1.2} />
          {date?.getTime() > 0 ? (
            <>
              {date?.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
                calendar: "gregory",
                numberingSystem: "latn",
                localeMatcher: "lookup",
                weekday: "short",
              })}
            </>
          ) : (
            "N/A"
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "Role",
    header: () => <div className="text-left">Role</div>,
    cell: ({ row }) => {
      return <Role role={row.original.role} value={row.original.userId as string} table_name="user"/>;
    },
  },
];
