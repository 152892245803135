import http from "@/http";
import { useState } from "react";
import { message } from "antd";
import { AxiosError } from "axios";

import { LoginResponse } from "@/model/user.model";
import { useNavigate } from "react-router-dom";
import { useUserDispatch } from "@/providers/userProvider";
import { useTokenDispatch, TokenActions } from "@/providers/useAuthProvider";

import { appRoutes } from "@/utils/paths";

const useSignup = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useUserDispatch();
  const tokenDispatch = useTokenDispatch();
  const navigate = useNavigate();

  const signup = async (email: string, password: string, name: string) => {
    try {
      setLoading(true);
      const response = await http.post<LoginResponse>("/auth/register", {
        email,
        password,
        name,
      });
      setLoading(false);
      dispatch?.setUser(response.data.user);
      tokenDispatch({
        type: TokenActions.setTokens,
        payload: {
          access_token: response.data.access_token,
          refresh_token: response.data.refresh_token,
        },
      });
      return navigate(appRoutes.dashboard.INBOX.ROOT);
    } catch (error: unknown) {
      setLoading(false);
      if (error instanceof AxiosError) {
        if (error.response?.data.statusCode === "LOGIN_201") {
          return message.warning(error.response?.data.message);
        }
        message.error(error.response?.data.message);
      }
      throw error;
    }
  };

  return { signup, loading };
};

export default useSignup;
