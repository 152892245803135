import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"

interface StatCardPros {
  title: string;
  value: number | string;
  icon: React.ReactNode;
  subtext: string | React.ReactNode;
  color: string;
}

const StatCard : React.FC<StatCardPros> = ({ title, value, icon, subtext, color }) => (
  <Card className={"overflow-hidden hover:border-l-4 shadow-md hover:shadow-lg transition-shadow duration-200 "+color}>
    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
      <CardTitle className="text-sm font-medium text-gray-500">{title}</CardTitle>
      {icon}
    </CardHeader>
    <CardContent>
      <div className="text-3xl font-bold">{value}</div>
      <p className="text-sm text-gray-500 mt-1">{subtext}</p>
    </CardContent>
  </Card>
)

export default  StatCard