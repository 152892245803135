import { useEffect, useState, useRef } from "react";
import DOMPurify from "dompurify";


// Helper function to ensure all links open in new tabs
const modifyLinksToOpenInNewTab = (htmlContent: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, "text/html");

  const links = doc.querySelectorAll("a");

  links.forEach((link) => {
    link.setAttribute("target", "_blank");
    // link.setAttribute("rel", "noreferrer");
  });

  return doc.documentElement.innerHTML;
};

const highlightTextFn = (content: string, searchTerm: string) => {
  if (!searchTerm) return content;
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, "text/html");

  const highlightNodes = (node: ChildNode) => {
    if (node.nodeType === Node.TEXT_NODE) {
      const regex = new RegExp(`(${searchTerm})`, "gi");
      const newText = node.textContent?.replace(regex, `<span class="highlight">$1</span>`);
      if (newText) {
        const span = document.createElement("span");
        span.innerHTML = newText;
        node.replaceWith(...span.childNodes);
      }
    } else {
      node.childNodes.forEach(highlightNodes); // Recursively highlight child nodes
    }
  };

  doc.body.childNodes.forEach(highlightNodes);
  return doc.body.innerHTML;
};

const EmailViewer = ({ content, highlightText }: { content: string, highlightText: string }) => {
  const [emailContent, setEmailContent] = useState<string | null>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (content) {
      const sanitizedContent = DOMPurify.sanitize(content || "");
      const modifiedContent = modifyLinksToOpenInNewTab(sanitizedContent);
      const highlightedContent = highlightTextFn(modifiedContent, highlightText);
        // Inject the font style into the content
        const styledContent = `
        <style>
          body { font-family: sans-serif; font-size: 13px; }
          .highlight { background-color: #fdba74; border-radius:2px; padding: 1px 2px; }
        </style>
        ${highlightedContent}
      `;
      setEmailContent(styledContent);
    }
  }, [content, highlightText]);

  useEffect(() => {
    const adjustIframeHeight = () => {
      const iframe = iframeRef.current;
      if (iframe && iframe.contentWindow && iframe.contentDocument) {
        iframe.style.height = "auto"; 
        const contentHeight = iframe.contentDocument.body.scrollHeight+20;
        iframe.style.height = `${Math.min(contentHeight, 600)}px`; 
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.onload = () => adjustIframeHeight();
    }
  }, [emailContent]);

  if (!emailContent) {
    return <p>Loading...</p>;
  }

  return (
    <iframe
      ref={iframeRef}
      title="Email Content"
      className="w-full"
      style={{
        maxHeight: "600px",
        width: "100%",
        border: "none",
        overflowY: "auto",
        fontFamily:'sans-serif'
      }}
      srcDoc={emailContent}
    />
  );
};

export default EmailViewer;
