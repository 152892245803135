import React from "react";
import { Transition } from "@headlessui/react";
import { message } from "antd";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";
import { Workflow } from "lucide-react";


import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";


import { useNewWorkflow } from "../hooks/useWorkflow";
import { Dropdown } from "@/components/Dropdown";

import { ReactComponent as InvoiceIcon } from '@/assets/svgs/icons/invoice.svg'
import { ReactComponent as EmptyIcon } from '@/assets/svgs/icons/empty.svg'

interface FormData {
  workflow_name: string;
  workflow_description?: string;
  workflow_type: string;
}

interface Props {
  setShowSheet: React.Dispatch<React.SetStateAction<boolean>>;
  showNewWorkflow: boolean;
}

const NewWorkflow = ({ setShowSheet, showNewWorkflow }: Props) => {
  const { newWorkflow } = useNewWorkflow();

  const [isDone, setIsDone] = React.useState(false);

  const queryClient = useQueryClient();

  const { isPending, mutateAsync, data } = useMutation({
    mutationKey: ["newWorkflow"],
    mutationFn: newWorkflow,
    onError: (error: unknown) => {
      const axiosError = error as AxiosError;
      message.error((axiosError?.response?.data as { message: string })?.message);
    },
    onSuccess: (response) => {
      reset();
      queryClient.invalidateQueries({
        queryKey: ["workflow", 1, 10],
      });
      setIsDone(true);
      message.success(response.data.message);
    },
  });

  const {
    control: newWorkflowControl,
    handleSubmit,
    reset,
    formState: { errors },
    watch
  } = useForm<FormData>({
    defaultValues: {
      workflow_name: "",
      workflow_description: "",
      workflow_type: "",
    },
    mode: "onSubmit",
    resolver: yupResolver(
      Yup.object().shape({
        workflow_name: Yup.string()
          .required("workflow name is required")
          .matches(/^[a-zA-Z_]\w+$/, {
            message:
              "Workflow name can't start with a number and can't contain special characters and spaces and should be snake_case.",
          }),
        workflow_description: Yup.string(),
        workflow_type: Yup.string().required("Workflow type is required"),
      })
    ),
  });

  const onSubmitNewWorkflow = async (data: FormData) => {
    mutateAsync(data);
  };

  return (
    <Sheet
      open={showNewWorkflow}
      modal={true}
      onOpenChange={() => {
        setShowSheet(false)
        setIsDone(false)
      }}
    >
      <SheetContent className="min-w-[500px]">
        <SheetHeader>
          <Transition
            show={!isDone}
            enterFrom="translate-x-full opacity-0 duration-75"
            enterTo="translate-x-0 opacity-100"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="translate-x-full opacity-0"
            leave="transition-opacity duration-125"
          >
            <form onSubmit={handleSubmit(onSubmitNewWorkflow)}>
              <SheetTitle>Create new workflow</SheetTitle>
              <SheetDescription className="mt-[10px]">
                Automate document processes with Workflows. Integrate APIs for
                data retrieval. Set up alerts via webhooks for seamless
                notifications.
              </SheetDescription>
              <div className="grid gap-4 pt-[30px] w-full">
                <div className="flex items-center justify-between">
                  <Label>Workflow name*</Label>
                </div>
                <Controller
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="text"
                      placeholder="what is the name of the workflow?"
                      addornment={<Workflow size={20} />}
                      error={errors.workflow_name?.message}
                    />
                  )}
                  name="workflow_name"
                  control={newWorkflowControl}
                />
                <div className="flex items-center justify-between w-full">
                  <Label className="flex justify-between w-full">
                    Workflow description (max 150 characters)
                  </Label>
                  {
                    watch('workflow_description') !== undefined && (
                      <div className={
                          (watch('workflow_description') || "")?.length  > 150
                          ? 'text-red-500 text-[10px] mt-1 w-[88px]'
                          : 'text-[10px] text-muted-foreground mt-1 w-[88px]'
                      }>
                        {watch('workflow_description')?.length || 0} characters
                      </div>
                    )
                  }
                </div>
                <Controller
                  render={({ field }) => (
                    <textarea
                      {...field}
                      className="flex min-h-[70px] w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 max-h-[130px]"
                      placeholder="short description of the workflow"
                    />
                  )}
                  name="workflow_description"
                  control={newWorkflowControl}
                />
                <div>
                  <Label>Workflow type</Label>
                  <Controller 
                    render={({ field }) => (
                      <Dropdown
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                        className="mt-2"
                        items={[
                          {
                            label: "Invoice",
                            value: "INVOICE",
                            desc: "Pre define configuration for invoice procesing",
                            icon: <InvoiceIcon height={50} width={50}/>,
                          },
                          {
                            label: "Custom",
                            value: "CUSTOM",
                            desc: "Custom configuration for document processing",
                            icon: <EmptyIcon height={50} width={50}/>,
                          },
                        ]}
                      />
                    )}
                    name="workflow_type"
                    control={newWorkflowControl}
                  />
                  {errors.workflow_type && (
                    <p className="text-[12px] text-red-500 mt-1">{errors.workflow_type.message}</p>
                  )}
                </div>
              </div>
              <div className="flex items-start gap-[15px] mt-[30px]">
                <Button
                  loading={isPending}
                  type="submit"
                  className="w-[100px]"
                  variant={"default"}
                >
                  Create
                </Button>
              </div>
            </form>
          </Transition>
          <Transition
            show={isDone}
            enterFrom="translate-x-full opacity-0 duration-75"
            enterTo="translate-x-0 opacity-100"
            leaveFrom="translate-x-0 opacity-100"
            leaveTo="translate-x-full opacity-0"
            enter="transition-opacity duration-75"
            leave="transition-opacity duration-125"
          >
            <h2 className="text-lg font-semibold text-foreground">
              Create new workflow
            </h2>
            <div className="text-sm text-muted-foreground mt-[10px]">
              Automate document processes with Workflows. Integrate APIs for
              data retrieval. Set up alerts via webhooks for seamless
              notifications.
            </div>
            <div>
              <div className="flex items-start gap-[15px] mt-[30px]">
                <Link
                  to={`/configurations/${data?.data?.workflow_id}`}
                  state={{ workflow_name: data?.data.workflow_name }}
                >
                  <Button variant={"default"}>Automate workflow</Button>
                </Link>
                <Button
                  type="button"
                  className="w-[100px]"
                  onClick={() => {
                    setShowSheet(false);
                    setIsDone(false);
                  }}
                  variant={"outline"}
                >
                  Skip
                </Button>
              </div>
            </div>
          </Transition>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  );
};

export default NewWorkflow;
