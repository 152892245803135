import React from "react";
import { useParams } from "react-router-dom";
import { useMyMail } from "@/providers/myMailContext";
import { useQueryClient } from "@tanstack/react-query";
import { gmailApi } from "../api/gmail";
import { Message } from "@/types/email.type";

const useRefetchLatestEmail = () => {
  const queryClient = useQueryClient();
  const { syncMessageList } = gmailApi();
  const { setPage, page } = useMyMail();
  const [isSyncMail, setIsSyncMail] = React.useState(false);
  const { inbox_type } = useParams<{ inbox_type: string }>();

  const applySorting = (messages: Message[]) =>
    messages.sort(
      (a, b) =>
        new Date(b.internalDate).getTime() - new Date(a.internalDate).getTime()
    );

  const refetchLatestAllEmail = async () => {
    setIsSyncMail(true);
    try {
      const latestResponse = await syncMessageList();
      const latestMessages = latestResponse.data.messages.messages;

      if (!Array.isArray(latestMessages)) {
        throw new Error("Invalid structure for latestMessage");
      }

      const currentMessages = page?.messages.messages || [];
      const existingThreadIds = new Set(currentMessages.map((msg) => msg.threadId));

      // Filter out messages that already exist based on threadId
      const newMessages = latestMessages.filter(
        (msg) => !existingThreadIds.has(msg.threadId)
      );

      // Helper function to update the page state with filtered messages
      const updatePageWithMessages = (messages: Message[]) => {
        setPage((prev) => ({
          ...prev,
          messages: {
            messages: applySorting([...prev.messages.messages, ...messages]),
            nextPageToken: latestResponse.data.messages.nextPageToken,
          },
          lastSyncDate: latestResponse.data.lastSyncDate,
        }));
      };

      // Determine filter action based on inbox_type
      const lowerCaseInboxType = inbox_type?.toLowerCase();

      switch (lowerCaseInboxType) {
        case "my-mails":
          // Only keep assigned messages
          updatePageWithMessages(newMessages.filter((mail) => mail.assignees.length > 0));
          break;
        case "sent":
          // Keep only sent messages
          updatePageWithMessages(newMessages.filter((mail) => mail.labelIds.includes("SENT")));
          break;
        case "spams":
          updatePageWithMessages(newMessages.filter((mail) => mail.labelIds.includes("SPAM")));
          break;
        case "all-mails":
          updatePageWithMessages(newMessages);
          break;
        case "unassigned":
          // Only keep unassigned messages
          updatePageWithMessages(newMessages.filter((mail) => mail.assignees.length === 0));
          break;
        default:
          // Fallback to default behavior
          updatePageWithMessages(newMessages);
      }

      // Invalidate query to refresh count
      queryClient.invalidateQueries({ queryKey: ["getLablesCount"] });
    } catch (error) {
      console.log(error);
    } finally {
      setIsSyncMail(false);
    }
  };

  return {
    refetchLatestAllEmail,
    isSyncMail,
  };
};

export default useRefetchLatestEmail;
