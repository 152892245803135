import React from "react";

interface Geometry {
  bounding_box: {
    left: number;
    top: number;
    width: number;
    height: number;
  };
  polygon: {
    x: number;
    y: number;
  }[];
  anotation_label:string
}

interface GeometryProps {
  geometry: Geometry;
  setGeometry: React.Dispatch<React.SetStateAction<Geometry>>;
}

interface GeometryProviderProps {
  children: React.ReactNode;
}

const GeometryContext = React.createContext<GeometryProps | undefined>(
  undefined
);

export const GeometryProvider: React.FC<GeometryProviderProps> = ({
  children,
}) => {
  const [geometry, setGeometry] = React.useState<Geometry>({
    bounding_box: {
      left: 0,
      top: 0,
      width: 0,
      height: 0,
    },
    polygon: [],
    anotation_label:''
  });

  return (
    <GeometryContext.Provider value={{ geometry, setGeometry }}>
      {children}
    </GeometryContext.Provider>
  );
};

export const useGeometry = () => {
  const context = React.useContext(GeometryContext);
  if (!context) {
    throw new Error("useGeometry must be used within a GeometryProvider");
  }
  return context;
};
