import { Mail, ZapIcon, BadgeCheckIcon, Tag } from "lucide-react";

const growthPlan = [
  {
    title: "Email",
    icon: <Mail className="w-5 h-5 text-purple-500 mr-2" />,
    used: 1023,
    plan_offers: 6000,
  },
  {
    title: "Users",
    icon: <ZapIcon className="w-5 h-5 text-blue-500 mr-2" />,
    used: 3,
    plan_offers: 10,
  },
  {
    title: "Labels",
    icon: <Tag className="w-5 h-5 text-orange-500 mr-2" />,
    used: 6,
    plan_offers: 15,
  },
];

export default function Plan() {
  return (
    <div className="mx-auto rounded-lg p-8 bg-gradient-to-r from-blue-50 via-blue-100 to-blue-50 shadow-lg font-popins">
      <div className="flex justify-between items-start mb-6">
        <div>
          <h2 className="text-3xl font-bold text-blue-700">Growth</h2>
          <p className="text-sm text-gray-500">February 2024 (Last Invoice)</p>
        </div>
        <BadgeCheckIcon size={32} className="text-green-500" />
      </div>

      <p className="text-gray-700 text-sm mb-6">
        Designed for scaling teams, the Growth Plan covers everything from
        enhanced AI support to custom integrations. Reach out to add seats,
        services, or customizations.
      </p>

      <div className="mb-6">
        <h3 className="text-lg font-semibold text-blue-700 mb-3">Usage</h3>
        <div className="space-y-3">
          {growthPlan.map((item) => (
            <Usageservice {...item} />
          ))}
        </div>
      </div>
    </div>
  );
}

interface UsageServiceProps {
  title: string;
  icon: React.ReactNode;
  used: number;
  plan_offers: number;
}

const Usageservice: React.FC<UsageServiceProps> = (props) => {
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        {props.icon}
        <span className="text-sm text-gray-700 ml-2">{props.title}</span>
      </div>
      <div className="flex items-center space-x-4">
        <span className="text-sm text-gray-600 w-12 text-right tabular-nums">
          {props.used}
        </span>
        <span className="text-sm font-semibold text-gray-800 w-12 text-right tabular-nums">
          {props.plan_offers}
        </span>
      </div>
    </div>
  );
};
