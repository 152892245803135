import React from "react";

interface HalfScreenProps {
  title?: string;
  heading: string;
  desc: string;
  image?: string;
  video?: string;
}

const HalfScreen: React.FC<HalfScreenProps> = ({
  desc,
  heading,
  title,
  image,
  video,
}) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, [videoRef]);

  return (
    <div className="max-w-2xl text-center">
      {title && <div className="inline-block bg-orange-100 text-orange-800 rounded-full px-3 py-1 text-sm font-semibold mb-4">
        { title}
      </div>}
      {heading && <h2 className="text-3xl font-bold mb-4 text-gray-800">
        {heading}
      </h2>}
      <p className="text-gray-600 mb-8">
        {(desc && desc) ||
          " Get started quickly and easily. Our intuitive setup ensures you're ready to go in no time."}
      </p>
      {image && <img src={image} alt="onboard" className="w-full mb-8" />}
      {video && (
        <video
          className="rounded-md"
          loop
            muted
          autoPlay={true}
          src={video}
          ref={videoRef}
        />
      )}
    </div>
  );
};

export default HalfScreen;
