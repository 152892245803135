import React from 'react'

import { Label } from '@/types/email.type'

interface LabelSelectContextProps {
  labels: Label[];
  setLabels: React.Dispatch<React.SetStateAction<Label[]>>;
}

interface LabelSelectProviderProps {
  children: React.ReactNode;
}

const LabelSelectContext = React.createContext<LabelSelectContextProps | undefined>(
  undefined
);

export const LabelSelectProvider: React.FC<LabelSelectProviderProps> = ({ children }) => {
  const [labels, setLabels] = React.useState<Label[]>([]);

  return (
    <LabelSelectContext.Provider value={{ labels, setLabels }}>
      {children}
    </LabelSelectContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useLabelSelect = () => {
  const context = React.useContext(LabelSelectContext);
  if (!context) {
    throw new Error("useLabelSelect must be used within a LabelSelectProvider");
  }
  return context;
};
